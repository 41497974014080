import { ReviewResult } from '@/model/enum';

const reviewResultList: Array<{ name: string, value: ReviewResult }> = _.filter(_.map(ReviewResult, item => {
    let name: string = null;
    switch (item) {
        case ReviewResult.PASS:
            name = '通过';
            break;
        case ReviewResult.REJECT:
            name = '未通过';
            break;
        case ReviewResult.DISABLE:
            name = '失效';
            break;
    }
    return { name, value: item };
}), item => item.name) as any;

const reviewResultFilter = (val: ReviewResult) => {
    return _.get(_.find(reviewResultList, item => item.value === val), 'name') || '-';
};

export {
    reviewResultFilter,
    reviewResultList
};
