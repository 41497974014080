








import { Component, Prop } from 'vue-property-decorator';
import { BaseComponent } from '@/mixins/base-component';
import { ThingsFunctionType } from '@/model/enum';
import { thingsModelFunctionFilter } from '@/filter';

@Component
export default class JTLThingsFunctionLabelComponent extends BaseComponent {
    @Prop()
    functionType: ThingsFunctionType;

    /**
     * 根据名称获取颜色
     */
    get NameColor(): string {
        let nameColor = null;
        switch (this.functionType) {
            case ThingsFunctionType.ATTRIBUTE:
                nameColor = JTL.CONSTANT.COLORS[5];
                break;
            case ThingsFunctionType.EVENT:
                nameColor = JTL.CONSTANT.COLORS[3];
                break;
            case ThingsFunctionType.METHOD:
                nameColor = JTL.CONSTANT.COLORS[2];
                break;
            case ThingsFunctionType.FEATURE:
                nameColor = JTL.CONSTANT.COLORS[1];
                break;
        }
        return nameColor;
    }

    get FunctionName() {
        return thingsModelFunctionFilter(this.functionType);
    }
}

