var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "view-line-arrow",
      on: {
        mousemove: function($event) {
          return _vm.onMousemove($event)
        },
        mouseup: function($event) {
          return _vm.onMouseUp($event)
        },
        resize: _vm.onResize
      }
    },
    [
      _c(
        "canvas",
        {
          ref: "elCanvas",
          attrs: {
            width: _vm.component.style.position.w,
            height: _vm.component.style.position.h
          }
        },
        [_vm._v(" Your browser does not support the HTML5 canvas tag. ")]
      ),
      _vm.editMode && _vm.IsSelected
        ? [
            _vm._l(_vm.points, function(pass, index) {
              return [
                _c("div", {
                  key: index,
                  staticClass: "passby",
                  style: {
                    left: pass.x - 5 + "px",
                    top: pass.y - 5 + "px"
                  },
                  on: {
                    mousedown: function($event) {
                      $event.stopPropagation()
                      return _vm.arrowPassDown(pass, $event, index)
                    }
                  }
                })
              ]
            })
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }