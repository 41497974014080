import { ThingsFunctionType } from '@/model/enum';

const thingsModelFunctionList: Array<{ name: string, value: ThingsFunctionType }> = _.filter(_.map(ThingsFunctionType, item => {
    let name: string = null;
    switch (item) {
        case ThingsFunctionType.ATTRIBUTE:
            name = '属性';
            break;
        case ThingsFunctionType.EVENT:
            name = '事件';
            break;
        case ThingsFunctionType.METHOD:
            name = '服务';
            break;
        case ThingsFunctionType.FEATURE:
            name = '特征';
            break;
    }
    return { name, value: item };
}), item => item.name) as any;

const thingsModelFunctionFilter = (val: ThingsFunctionType) => {
    return _.get(_.find(thingsModelFunctionList, item => item.value === val), 'name') || '-';
};

export {
    thingsModelFunctionFilter,
    thingsModelFunctionList
};
