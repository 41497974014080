import { ParkingGateType, ParkingSpaceTypeEnum } from '@/model/enum';

const parkingGateTypeList: Array<{ name: string, value: ParkingGateType }> = _.filter(_.map(ParkingGateType, item => {
    let name: string = null;
    switch (item) {
        case ParkingGateType.EXIT:
            name = '驶出';
            break;
        case ParkingGateType.ENTRANCE:
            name = '驶入';
            break;
    }
    return { name, value: item };
}), item => item.name) as any;
const parkingGateTypeFilter = (val: ParkingGateType | string) => {
    return _.get(_.find(parkingGateTypeList, item => item.value === val), 'name') || '-';
};
export {
    parkingGateTypeFilter,
    parkingGateTypeList
};

const parkingSpaceTypeList: Array<{ name: string, value: ParkingSpaceTypeEnum }> = _.filter(_.map(ParkingSpaceTypeEnum, item => {
    let name: string = null;
    switch (item) {
        case ParkingSpaceTypeEnum.USING:
            name = '占用';
            break;
        case ParkingSpaceTypeEnum.FREE:
            name = '空闲';
            break;
        case ParkingSpaceTypeEnum.RATE:
            name = '停车率';
            break;
    }
    return { name, value: item };
}), item => item.name) as any;
const parkingSpaceTypeFilter = (val: ParkingSpaceTypeEnum | string) => {
    return _.get(_.find(parkingSpaceTypeList, item => item.value === val), 'name') || '-';
};
export {
    parkingSpaceTypeFilter,
    parkingSpaceTypeList
};
