import { BaseEntityModel } from '@/model/base-model';
import { FormControl, FormControlType, FormControlTextModel, FormControlSelectGroupModel, FormControlOptionModel } from '@/model/form-control';
import { GroupQueryModel } from '@/model/query-model';
import { FacilityType, GroupEntityType } from '@/model/enum';
import { QueryControl, QueryControlType } from '@/model/query-control';
import FacilityGroupService from '@/service/facility-group';
import LabelService from '@/service/label';
import { NUMBER_LETTER_UNDERLINE_REGEXP, LETTER_REGEXP } from '@/model/regexp';
export class FacilityTypeEntityModel extends BaseEntityModel {
    constructor(groupId?: any) {
        super();
        // this.moduleType = groupId;
        // this.groupType = GroupEntityType.FACILITY_TYPE;
        this.groupId = groupId;
    }

    @FormControl({
        label: '分组',
        type: FormControlType.TREE_SELECT,
        // optionsPromise: FacilityTypeGroupService.getGroupTree,
        optionsPromise: FacilityGroupService.getGroupTree,
        optionsPromiseParam: GroupEntityType.FACILITY_TYPE,
        mode: 'default',
        required: true
    } as FormControlOptionModel)
    groupId: string = undefined;

    // @FormControl({
    //     label: '业务分类',
    //     type: FormControlType.TREE_SELECT,
    //     options: _.map(TYPE_TREE_DATA, item => new SimpleTreeModel().toModel(item)),
    //     required: false
    // } as FormControlOptionModel)
    // moduleType: FacilityType = undefined;

    @FormControl({
        label: '标识符',
        type: FormControlType.TEXT,
        required: true,
        max: 50,
        pattern: NUMBER_LETTER_UNDERLINE_REGEXP,
        readonly: true
    } as FormControlTextModel)
    identifier: string = undefined;

    @FormControl({
        label: '名称',
        type: FormControlType.TEXT,
        required: true,
        max: 255
    } as FormControlTextModel)
    name: string = undefined;

    @FormControl({
        label: '代码',
        type: FormControlType.TEXT,
        required: true,
        max: 255,
        pattern: LETTER_REGEXP
    } as FormControlTextModel)
    code: string = undefined;

    @FormControl({
        label: '描述',
        type: FormControlType.TEXT_AREA,
        required: false
    } as FormControlTextModel)
    remark: string = undefined;

    @FormControl({
        label: '标签',
        type: FormControlType.SELECT,
        optionsPromise: LabelService.getAllLabel.bind(LabelService),
        mode: 'multiple',
        required: false
    } as FormControlOptionModel)
    tagIds: string = undefined;

    check: boolean = false;

    static getTableColumns() {
        return [
            {
                title: '名称',
                dataIndex: 'name',
                scopedSlots: { customRender: 'name' }
            },
            {
                title: '描述',
                dataIndex: 'remark'
            },
            {
                title: '创建时间',
                dataIndex: 'createdTime'
            },
            {
                title: '操作',
                dataIndex: 'action',
                scopedSlots: { customRender: 'action' }
            }
        ];
    }

    toService() {
        return {
            id: this.id,
            name: this.name,
            groupId: this.groupId,
            tagIds: this.tagIds,
            remark: this.remark,
            code: this.code,
            identifier: this.identifier
        };
    }
}

export class FacilityTypeQueryModel extends GroupQueryModel {
    @QueryControl({
        label: '名称',
        type: QueryControlType.TEXT,
        span: 12
    })
    name: string = undefined;
    type: FacilityType = undefined;

    toService() {
        const data: any = {
            params: {
                name: this.name,
                groupIds: this.GroupIds
            }
        };
        if (this.type) {
            // data.params.moduleTypes = [this.type];
            data.params.moduleType = this.type;
        }
        return data;
    }
}
