










































































































import { Component } from 'vue-property-decorator';
import { AttributeEntityType, GroupEntityType } from '@/model/enum';
// import TableComponent from '@/mixins/table-component';
import TransformComponent from '@/mixins/transform-component';
import CommonService from '@/service/common';
import LinkageDeviceService from '@/service/linkage-device';
import FacilityV2Service from '@/service/facility-v2';
import { FacilityV2QueryModel, LinkageFacilityQueryModel } from '@/entity-model/facility-v2-entity';

@Component
export default class LinkageDeviceDialog extends TransformComponent {
    allTypeList: Array<any> = null;
    // dialogVisible: boolean = false;
    // GroupEntityType = GroupEntityType;
    linkageType: AttributeEntityType = null;
    // rightDataList: Array<any> = null;

    created() {
        this.isLoadMore = true;
        this.isAutoQuery = false;
    }

    get IsLinkageDevice() {
        return this.linkageType === AttributeEntityType.DEVICE;
    }
    get IsLinkageFacility() {
        return this.linkageType === AttributeEntityType.FACILITY;
    }

    dialogOpen(linkageId: string, linkageType: AttributeEntityType, groupId: string, selectedValue: Array<any>) {
        this.linkageType = linkageType;
        this.dialogVisible = true;
        this.rightDataList = _.cloneDeep(selectedValue) || [];
        CommonService.getAllFacilityType().then(res => this.allTypeList = res);
        if (linkageType === AttributeEntityType.DEVICE) {
            // CommonService.getDeviceTypeOptions().then(res => this.allTypeList = res);
            this.initTable({ listFunc: LinkageDeviceService.getLinkageDevice, queryModel: new LinkageFacilityQueryModel() });
            this.queryModel.groupId = groupId;
            this.queryModel.linkageId = linkageId;
        } else if (linkageType === AttributeEntityType.FACILITY) {
            // CommonService.getAllFacilityType().then(res => this.allTypeList = res);
            this.initTable({ listFunc: FacilityV2Service.query, queryModel: new FacilityV2QueryModel() });
        }
        this.getList().then(() => {
            this.initListData();
        });
    }

    linkageLoadMoreClick() {
        this.loadMoreClick().then(() => {
            this.initListData();
        });
    }

    initListData() {
        if (this.rightDataList && this.rightDataList.length > 0) {
            _.forEach(this.rightDataList, item => {
                const leftDataItem = _.find(this.listData, listItem => listItem.id === item.id);
                if (leftDataItem) {
                    leftDataItem.check = true;
                }
            });
        }
    }

    dialogOK() {
        this.$emit('dialogOK', _.map(this.rightDataList, item => {
            return {
                id: item.id,
                name: item.displayName || item.name
            };
        }));
        this.dialogClose();
    }

    dialogClose(): void {
        this.queryModel = null;
        this.listData = null;
        this.rightDataList = null;
        this.allTypeList = null;
        this.dialogVisible = false;
    }

    querySelectChange(selectValue: string) {
        if (this.IsLinkageDevice) {
            this.queryModel.typeId = selectValue;
        } else if (this.IsLinkageFacility) {
            this.queryModel.typeIdList = selectValue;
        }
        this.getList(null, false);
    }

    // checkOnChange(model: any) {
    //     if (model.check) {
    //         this.rightDataList.push(model);
    //     } else {
    //         const index = _.findIndex(this.rightDataList, item => item.id === model.id);
    //         if (index > -1) {
    //             this.rightDataList.splice(index, 1);
    //         }
    //     }
    // }

    get AllTypeList() {
        return this.getAllOptions(this.allTypeList);
    }
    // get AllCheck() {
    //     if (!this.listData || this.listData.length === 0) {
    //         return false;
    //     }
    //     return _.filter(this.listData, item => item.check).length === this.listData.length;
    // }
    // set AllCheck(val: boolean) {
    //     _.forEach(this.listData, item => {
    //         item.check = val;
    //         if (val) {
    //             const index = _.findIndex(this.rightDataList, rightItem => rightItem.id === item.id);
    //             if (index === -1) {
    //                 this.rightDataList.push(item);
    //             }
    //         }
    //     });
    //     if (!val) {
    //         this.rightDataList = [];
    //     }
    // }
    // removeItemClick(model, index) {
    //     model.check = false;
    //     const listDataItem = _.find(this.listData, item => item.id === model.id);
    //     if (listDataItem) {
    //         listDataItem.check = false;
    //     }
    //     this.rightDataList.splice(index, 1);
    // }
    // removeAllClick() {
    //     this.rightDataList = [];
    //     _.forEach(this.listData, item => {
    //         item.check = false;
    //     });
    // }
}
