





















import { Component, Vue, Prop, Watch, Ref } from 'vue-property-decorator';
import { BaseComponent } from '@/mixins/base-component';
import { SimpleTreeModel } from '@/model/simple-tree-model';
import SpaceService from '@/service/space-v2';

@Component
export default class JTLTreeSpaceComponent extends BaseComponent {
    treeData: Array<SimpleTreeModel> = null;
    expandedKeys: Array<string> = null;
    selectedKeys: Array<string> = null;
    @Prop()
    spaceId: string;
    @Prop()
    getLocationTreeService: Function;
    @Prop()
    getLocationTreeServiceParam: any;

    treeLoading: boolean = true;

    mounted() {
        if (this.spaceId) {
            this.selectedKeys = [this.spaceId];
        }
        this.initTree();
    }

    initTree() {
        let getLocationTreeService = this.getLocationTreeService || SpaceService.getLocationTreeWithAll;
        this.treeLoading = true;
        getLocationTreeService(this.getLocationTreeServiceParam).then(res => {
            this.treeData = res;
            if (this.spaceId) {
                this.expandedKeys = [];
                this.initExpandedKeys(this.treeData);
            } else if (this.treeData && this.treeData.length > 0) {
                this.expandedKeys = [''];
                // 默认选中第一个节点
                const selectGroupId = this.treeData[0].id;
                this.selectedKeys = [selectGroupId];
                this.$emit('select', this.getSelectGroupIds(this.treeData[0]));
                // this.$emit('loaded');
            }
        }).catch(err => {
            $log.error(err);
        }).finally(() => {
            this.treeLoading = false;
        });
    }

    initExpandedKeys(children: Array<SimpleTreeModel>) {
        if (children && children.length > 0) {
            for (let i = 0; i < children.length; i++) {
                if (children[i].id === this.spaceId) {
                    this.$emit('select', this.getSelectGroupIds(children[i]));
                    // this.$emit('loaded');
                    this.getParentKey(children[i].parent);
                    break;
                } else {
                    this.initExpandedKeys(children[i].children);
                }
            }
        }
    }

    getParentKey(parent: SimpleTreeModel) {
        if (parent) {
            this.expandedKeys.push(parent.id);
            if (parent.parent) {
                this.getParentKey(parent.parent);
            }
        }
    }

    treeSelect(selectedKeys, e:{ selected: boolean, selectedNodes, node, event }) {
        const groupId = e.node.value;
        if (e.selected) {
            this.$emit('select', this.getSelectGroupIds(e.node.dataRef));
        } else {
            this.selectedKeys = [groupId];
        }
    }

    getSelectGroupIds(treeItem: SimpleTreeModel) {
        return _.flattenDeep(this.getChildGroupId(treeItem));
    }

    getChildGroupId(treeItem: SimpleTreeModel) {
        const ids = [];
        if (treeItem) {
            ids.push(treeItem.id);
            if (treeItem.children && treeItem.children.length > 0) {
                ids.push(..._.map(treeItem.children, item => {
                    return this.getChildGroupId(item);
                }));
            }
        }
        return ids;
    }
}

