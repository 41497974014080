import { FormControl, FormControlType, FormControlTextModel, FormControlOptionModel } from '@/model/form-control';
// import { GroupEntityType } from '@/model/enum';
import SpaceV2Service from '@/service/space-v2';
// import LabelService from '@/service/label';
import { FacilityConfigGroupEntityModel } from './facility-config-group-entity';

export class FacilityGroupEntityModel extends FacilityConfigGroupEntityModel {
    @FormControl({
        label: '关联位置',
        type: FormControlType.TREE_SELECT,
        optionsPromise: SpaceV2Service.getLocationFloors,
        required: false,
        mode: 'multiple'
    } as FormControlOptionModel)
    locations: Array<string> = undefined;

    toModel(json): any {
        this.locations = _.map(_.get(json, 'locations'), item => _.get(item, 'locationId'));
        this.name = this.title = _.get(json, 'name');
        this.type = _.get(json, 'type') || _.get(json, 'groupType');
        this.key = this.value = this.id = _.get(json, 'id');
        this.pId = _.get(json, 'parentId');
        if (json && json.children && json.children.length > 0) {
            this.children = _.map(json.children, item => {
                const child: FacilityGroupEntityModel = new FacilityGroupEntityModel().toModel(item);
                child.parent = this;
                child.pId = this.id;
                child.type = this.type;
                return child;
            });
        } else {
            this.children = [];
        }

        this.tagIds = _.get(json, 'tagIds');
        this.mark = _.get(json, 'mark');
        return this;
    }

    toService() {
        return {
            id: this.id,
            locations: (this.locations === undefined || this.locations === null) ? undefined : _.map(this.locations, item => {
                return { locationId: item };
            }),
            name: this.name,
            parentId: this.pId,
            type: this.type,
            groupType: this.type,
            tagIds: this.tagIds,
            mark: this.mark
        };
    }
}
