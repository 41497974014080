import { dateFormat } from '@/filter/date-format';
import { generateUUID } from '@/utils/base_util';

export const NO_SERVICE_FIELD = ['createdDt', 'modifiedDt', 'createdBy', 'modifiedBy', 'uuid'];
export const NO_FORM_FIELD = ['id', ...NO_SERVICE_FIELD];

export class BaseModel {
    /**
     * 唯一标识
     */
    uuid: string = generateUUID();

    /**
     * 从service的数据转成前端模型数据
     * 这里只转换字段和前端模型里定义一样的数据
     * 不一样的字段请在模型里的toModel方法里转换
     * 注：待转换的属性必须赋初始值
     * @param json service返回的json数据
     */
    toModel(json): any {
        _.forEach(json, (value, key) => {
            if (this.hasOwnProperty(key)) {
                this[key] = value;
            }
        });
        return this;
    }

    /**
     * 从前端模型数据转成service需要的json数据
     * 这里只转换字段和前端模型里定义一样的数据
     * 不一样的字段请在模型里的toService方法里转换
     * 注：待转换的属性必须赋初始值
     */
    toService(): any {
        const data = {};
        _.forEach(this, (value, key) => {
            if (key !== 'uuid' && this.hasOwnProperty(key) && NO_SERVICE_FIELD.indexOf(key) === -1 && value !== undefined) {
                data[key] = value;
            }
        });
        return data;
    }
}

export class BaseEntityModel extends BaseModel {
    id: string = undefined;
    createdTime: string = undefined;
    modifiedTime: string = undefined;

    toModel(json): any {
        super.toModel(json);
        this.createdTime = dateFormat(_.get(json, 'createdTime') || _.get(json, 'createdDT') || _.get(json, 'createDate'));
        this.modifiedTime = dateFormat(_.get(json, 'modifiedTime') || _.get(json, 'modifiedDT') || _.get(json, 'lastUpdateTs'));
        this.uuid = `${this.id}|${this.uuid}`;
        return this;
    }

    toService(): any {
        const data = super.toService();
        delete data.createdTime;
        delete data.modifiedTime;
        return data;
    }
}
