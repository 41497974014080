var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "select-user-component" },
    [
      _c(
        "a-row",
        { attrs: { type: "flex", justify: "center" } },
        [
          _c(
            "a-col",
            { staticClass: "select-user-wrapper", attrs: { span: 22 } },
            _vm._l(_vm.value, function(item, index) {
              return _c(
                "a-tag",
                {
                  key: index,
                  attrs: { closable: "" },
                  on: {
                    close: function($event) {
                      return _vm.value.splice(index, 1)
                    }
                  }
                },
                [_vm._v(" " + _vm._s(item.name) + " ")]
              )
            }),
            1
          ),
          _c("a-col", { staticClass: "text-center", attrs: { span: 2 } }, [
            _c(
              "a",
              { staticClass: "jtl-link", on: { click: _vm.selectUserClick } },
              [_vm._v("选择")]
            )
          ])
        ],
        1
      ),
      _c("select-user-dialog", {
        ref: "selectUserDialog",
        on: { dialogOK: _vm.selectUserChange }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }