import { PeriodTypeEnum } from '@/model/enum';
const periodTypeList: Array<{ name: string, value: PeriodTypeEnum }> = _.filter(_.map(PeriodTypeEnum, item => {
    let name: string = null;
    switch (item) {
        case PeriodTypeEnum.HOUR:
            name = '按小时';
            break;
        case PeriodTypeEnum.DAY:
            name = '按天';
            break;
        case PeriodTypeEnum.WEEK:
            name = '按周';
            break;
        case PeriodTypeEnum.MONTH:
            name = '按月';
            break;
        case PeriodTypeEnum.SEASON:
            name = '按季度';
            break;
        case PeriodTypeEnum.YEAR:
            name = '按年';
            break;
        // case PeriodTypeEnum.CUSTOM:
        //     name = '自定义';
        //     break;
    }
    return { name, value: item };
}), item => item.name) as any;

const periodTypeFilter = (val: PeriodTypeEnum) => {
    return _.get(_.find(periodTypeList, item => item.value === val), 'name') || '-';
};

const getDateFormatByPeriodTypeEnum = (type?: PeriodTypeEnum) => {
    let dateFormat = null;
    switch (type) {
        case PeriodTypeEnum.DAY:
        case PeriodTypeEnum.WEEK:
            dateFormat = 'MM-DD';
            break;
        case PeriodTypeEnum.MONTH:
        case PeriodTypeEnum.SEASON:
            dateFormat = 'YYYY-MM';
            break;
        case PeriodTypeEnum.YEAR:
            dateFormat = 'YYYY';
            break;
        default:
        case PeriodTypeEnum.HOUR:
            dateFormat = 'MM-DD HH:mm';
            break;
    }
    return dateFormat;
};

export {
    periodTypeFilter,
    periodTypeList,
    getDateFormatByPeriodTypeEnum
};
