
import { FacilityV2EntityModel, FacilityV2QueryModel } from '@/entity-model/facility-v2-entity';
import { ICRUDQ } from '@/model/interface';
import { get, post, del } from './request';

const URL_PATH = `${BI_BASE_REQUEST_PATH}/facilityV2`;

class FacilityV2Service implements ICRUDQ<FacilityV2EntityModel, FacilityV2QueryModel> {
    async create(model: FacilityV2EntityModel):Promise<FacilityV2EntityModel> {
        const url = `${URL_PATH}/save`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async retrieve(modelId: string):Promise<FacilityV2EntityModel> {
        const url = `${URL_PATH}/getById/${modelId}`;
        const res = await get(url);
        return new FacilityV2EntityModel().toModel(res);
    }

    async update(model: FacilityV2EntityModel):Promise<FacilityV2EntityModel> {
        const url = `${URL_PATH}/save`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async delete(model: FacilityV2EntityModel):Promise<FacilityV2EntityModel> {
        const url = `${URL_PATH}/delete/${model.id}`;
        const res = await del(url);
        return res;
    }

    async query(query?: FacilityV2QueryModel, page?: number, limit?: number):Promise<any> {
        const url = `${URL_PATH}/list`;
        const params = Object.assign({ page, limit }, query?.toService());
        const res = await post(url, params);
        res.items = _.map(res.items, item => item = new FacilityV2EntityModel().toModel(item));
        return res;
        // 不带分页功能的处理方法
        // return _.map(res, item => item = new FacilityV2EntityModel().toModel(item));
    }

    async queryByFacilityType(facilityTypeId: string):Promise<any> {
        const url = `${URL_PATH}/list`;
        const params = Object.assign({ page: 1, limit: 9999 }, { params: {
            typeIdList: [facilityTypeId],
            forceRoot: false
        } });
        const res = await post(url, params);
        return _.map(res.items, item => {
            return {
                value: item.id,
                name: item.name + (item.spaceName ? ' | ' + item.spaceName : '')
            };
        });
    }

    async unlink(id: string, parentId: string):Promise<any> {
        const url = `${URL_PATH}/unlink`;
        return await post(url, { childId: id, parentId: parentId });
    }

    async links(parentId: string, childId: Array<string>):Promise<any> {
        const url = `${URL_PATH}/batchLinkedSave`;
        return await post(url, { id: parentId, children: childId });
    }

    async batchCreateFacility(params: any):Promise<any> {
        const url = `${URL_PATH}/batchSave`;
        return await post(url, params);
    }

    async batchDelete(params: Array<any>):Promise<any> {
        const url = `${URL_PATH}/batchDelete`;
        return await post(url, params);
    }

    async batchUnlink(params: Array<any>):Promise<any> {
        const url = `${URL_PATH}/batchUnlink`;
        return await post(url, params);
    }

    async batchPosition(ids: Array<string>, positionId: string):Promise<any> {
        const url = `${URL_PATH}/batchChangeLocation`;
        return await post(url, { facilityIds: ids, locationId: positionId });
    }

    async batchChangeGroup(ids: Array<string>, groupIds: string) {
        const url = `${URL_PATH}/batchChangeGroup`;
        return await post(url, { facilityIds: ids, groupIds });
    }
}

export default new FacilityV2Service();
