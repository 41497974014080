





















import { Component, Prop } from 'vue-property-decorator';
import { SelectTreeComponent } from '@/mixins/tree-select-component';
import { GroupEntityType } from '@/model/enum';
import ThingsGroupService from '@/service/things-group';

@Component
export default class JTLSelectGroupComponent extends SelectTreeComponent {
    @Prop()
    groupType: GroupEntityType;
    @Prop()
    hasUnGroup: boolean;
    @Prop({ default: '请选择分组' })
    placeholder: string;
    created() {
        this.loading = true;
        ThingsGroupService.getGroupTree(this.groupType, this.hasUnGroup).then(res => {
            this.options = res;
            this.$nextTick(() => {
                // this.selectId = this.value;
                this.setSelectId();
            });
        }).catch(err => {
            $log.error(err);
        }).finally(() => {
            this.loading = false;
        });
    }
}

