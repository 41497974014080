export default [
    {
        path: 'service-center/all',
        component: () => import('@/pages/dashboard/service-center/index.vue'),
        meta: { parentMenu: 'dashboardMonitor' }
    },
    {
        path: 'event-view',
        component: () => import('@/pages/dashboard/event-view/event-view-list.vue'),
        meta: { parentMenu: 'dashboardMonitor' }
    },
    {
        path: 'event-view/:type',
        component: () => import('@/pages/dashboard/event-view/event-view-list.vue'),
        meta: { parentMenu: 'dashboardMonitor' }
    },
    {
        path: 'inspection',
        component: () => import('@/pages/dashboard/inspection/inspection-list.vue'),
        meta: { parentMenu: 'dashboardMonitor' }
    },
    {
        path: 'maintenance',
        component: () => import('@/pages/dashboard/maintenance/maintenance-list.vue'),
        meta: { parentMenu: 'dashboardMonitor' }
    },
    {
        path: 'cleaning',
        component: () => import('@/pages/dashboard/cleaning/cleaning-list.vue'),
        meta: { parentMenu: 'dashboardMonitor' }
    },
    {
        path: 'knowledge',
        component: () => import('@/pages/dashboard/knowledge/knowledge-list.vue'),
        meta: { parentMenu: 'dashboardMonitor' }
    },
    {
        path: 'workorder/:searchType/:workordertype',
        component: () => import('@/pages/dashboard/work-order/work-order-list.vue'),
        meta: { parentMenu: 'dashboardWorkOrder' }
    },
    {
        path: 'backup-asset',
        component: () => import('@/pages/dashboard/backup-asset/backup-asset-list.vue'),
        meta: { parentMenu: 'dashboardMonitor' }
    },
    {
        path: 'asset-list',
        component: () => import('@/pages/dashboard/asset/asset-list.vue'),
        meta: { parentMenu: 'dashboardMonitor' }
    },
    {
        path: 'professional-relation',
        component: () => import('@/pages/dashboard/work-order-setting/professional-relation-list.vue'),
        meta: { parentMenu: 'dashboardWorkOrderConfig' }
    },
    {
        path: 'work-order-form',
        component: () => import('@/pages/dashboard/work-order-setting/work-order-form-list.vue'),
        meta: { parentMenu: 'dashboardWorkOrderConfig' }
    },
    {
        path: 'work-order-form/:id',
        component: () => import('@/pages/dashboard/work-order-setting/work-order-form-design.vue'),
        meta: { parentPath: '/dashboard/work-order-form', parentMenu: 'dashboardWorkOrderConfig' }
    },
    {
        path: 'work-order-flow',
        component: () => import('@/pages/dashboard/work-order-setting/work-order-flow-list.vue'),
        meta: { parentMenu: 'dashboardWorkOrderConfig' }
    },
    {
        path: 'work-order-flow/:id',
        component: () => import('@/pages/dashboard/work-order-setting/work-order-flow-design.vue'),
        meta: { parentPath: '/dashboard/work-order-flow', parentMenu: 'dashboardWorkOrderConfig' }
    },
    {
        path: 'work-order-config',
        component: () => import('@/pages/dashboard/work-order-setting/work-order-config-list.vue'),
        meta: { parentMenu: 'dashboardWorkOrderConfig' }
    },
    {
        path: 'work-order-config/:id',
        component: () => import('@/pages/dashboard/work-order-setting/work-order-config-detail.vue'),
        meta: { parentPath: '/dashboard/work-order-config', parentMenu: 'dashboardWorkOrderConfig' }
    }, {
        path: 'work-order-service',
        component: () => import('@/pages/dashboard/work-order-setting/work-order-service-list.vue'),
        meta: { parentMenu: 'dashboardWorkOrderConfig' }
    },
    {
        path: 'work-order-service/:id',
        component: () => import('@/pages/dashboard/work-order-setting/work-order-service-design.vue'),
        meta: { parentPath: '/dashboard/work-order-config', parentMenu: 'dashboardWorkOrderConfig' }
    }, {
        path: 'work-order-rate',
        component: () => import('@/pages/dashboard/work-order-setting/work-order-rate-list.vue'),
        meta: { parentMenu: 'dashboardWorkOrderConfig' }
    }, {
        path: 'work-order-status-config',
        component: () => import('@/pages/dashboard/work-order-setting/work-order-status-config.vue'),
        meta: { parentMenu: 'dashboardWorkOrderConfig' }
    }, {
        path: 'work-order-timespan',
        component: () => import('@/pages/dashboard/work-order-setting/work-order-timespan-list.vue'),
        meta: { parentMenu: 'dashboardWorkOrderConfig' }
    }, {
        path: 'duty-calendar/:type',
        component: () => import('@/pages/dashboard/duty-management/duty-calendar.vue'),
        meta: { parentMenu: 'dashboardDutyManagement' }
    }, {
        path: 'duty-management',
        component: () => import('@/pages/dashboard/duty-management/duty-management-list.vue'),
        meta: { parentMenu: 'dashboardDutyManagement' }
    }, {
        path: 'duty-management/:id',
        component: () => import('@/pages/dashboard/duty-management/duty-management-detail.vue'),
        meta: { parentPath: '/dashboard/duty-management', parentMenu: 'dashboardDutyManagement' }
    }, {
        path: 'duty-config',
        component: () => import('@/pages/dashboard/duty-management/duty-shift-list.vue'),
        meta: { parentMenu: 'dashboardDutyManagement' }
    }, {
        path: 'duty-history',
        component: () => import('@/pages/dashboard/duty-management/duty-history-list.vue'),
        meta: { parentMenu: 'dashboardDutyManagement' }
    }
];
