import { TopoBehaveType } from '../model/enum';

const topoBehaveTypeList: Array<{name: string, value: TopoBehaveType}> = _.filter(_.map(TopoBehaveType, item => {
    let name: string = null;
    switch (item) {
        case TopoBehaveType.CLICK:
            name = '单击';
            break;
        case TopoBehaveType.DBCLICK:
            name = '双击';
            break;
        case TopoBehaveType.MOUSEIN:
            name = '鼠标移入';
            break;
        case TopoBehaveType.MOUSEOUT:
            name = '鼠标移出';
            break;
        case TopoBehaveType.SUBSCRIBE:
            name = '订阅事件';
            break;
        case TopoBehaveType.BROADCAST:
            name = '广播事件';
            break;
    }
    return { name, value: item };
}), item => item.name) as any;

const topoBehaveTypeFilter = (val: TopoBehaveType) => {
    return _.get(_.find(topoBehaveTypeList, item => item.value === val), 'name') || '-';
};

export {
    topoBehaveTypeFilter,
    topoBehaveTypeList
};
