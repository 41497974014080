import { ReadWriteEnum } from '@/model/enum';

const readWriteList: Array<{ name: string, value: ReadWriteEnum }> = _.filter(_.map(ReadWriteEnum, item => {
    let name: string = null;
    switch (item) {
        case ReadWriteEnum.READONLY:
            name = '只读';
            break;
        case ReadWriteEnum.READ_WRITE:
            name = '读写';
            break;
    }
    return { name, value: item };
}), item => item.name) as any;

const readWriteFilter = (val: ReadWriteEnum) => {
    return _.get(_.find(readWriteList, item => item.value === val), 'name') || '-';
};

export {
    readWriteFilter,
    readWriteList
};
