import { FormControl, FormControlType, FormControlTextModel } from '@/model/form-control';
import { GroupQueryModel, QueryModel } from '@/model/query-model';
import { NUMBER_LETTER_UNDERLINE_REGEXP } from '@/model/regexp';
import { ThingsFunctionGroupModel } from './things-function-entity';
import { GroupEntityType, SyncType, ThingsEntityType } from '@/model/enum';

export class ThingsTemplateEntityModel extends ThingsFunctionGroupModel {
    constructor(groupId?: string, groupType: GroupEntityType = GroupEntityType.TEMPLATE) {
        super(groupId, groupType);
    }
    @FormControl({
        label: '名称',
        type: FormControlType.TEXT,
        required: true,
        max: 50
    } as FormControlTextModel)
    name: string = undefined;

    @FormControl({
        label: '标识符',
        type: FormControlType.TEXT,
        required: true,
        max: 50,
        pattern: NUMBER_LETTER_UNDERLINE_REGEXP
    } as FormControlTextModel)
    identifier: string = undefined;

    @FormControl({
        label: '描述',
        type: FormControlType.TEXT_AREA,
        max: 500
    } as FormControlTextModel)
    remark: string = undefined;

    static getTableColumns() {
        return [
            {
                title: '名称',
                dataIndex: 'name',
                scopedSlots: { customRender: 'name' }
            },
            {
                title: '标识符',
                dataIndex: 'identifier'
            },
            {
                title: '更新时间',
                dataIndex: 'modifiedTime'
            },
            {
                title: '操作',
                dataIndex: 'action',
                scopedSlots: { customRender: 'action' }
            }
        ];
    }
}

export class ThingsTemplateQueryModel extends GroupQueryModel {
    name: string = undefined;
    // groupIds: Array<string> = undefined;

    toService() {
        const data = super.toService();
        data.params = {
            groupIds: this.GroupIds
        };
        if (this.name) {
            data.params.name = this.name;
        }
        delete data.name;
        delete data.groupIds;
        return data;
    }
}

export class ThingsSyncQueryModel extends QueryModel {
    sourceName: string = '';
    targetName: string = '';
    difference: boolean = false;
    sourceGroupIds: Array<string> = undefined;
    targetGroupIds: Array<string> = undefined;
    token: string = undefined;
    url: string = undefined;
    syncType: SyncType = undefined; // 0:同步到本地 1:同步到目标服务
    thingsEntityType: ThingsEntityType = undefined;

    toService() {
        const data = super.toService();
        data.type = this.syncType;
        if (this.thingsEntityType === ThingsEntityType.FUNCTION) {
            data.targetGroup = data.sourceGroup = _.upperCase(data.sourceGroupIds);
            delete data.sourceGroupIds;
        }

        delete data.syncType;
        delete data.thingsEntityType;
        return data;
    }
}
