var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        "topo-panel view-panel",
        { "topo-panel-dragover": _vm.IdDragOver },
        { "topo-panel-editor": _vm.editMode }
      ],
      on: {
        dragenter: function($event) {
          $event.stopPropagation()
          return _vm.dragenter($event)
        },
        dragleave: function($event) {
          $event.stopPropagation()
          return _vm.dragleave($event)
        },
        drop: _vm.drop
      }
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }