var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "jtl-tree-group-component" },
    [
      _c(
        "a-spin",
        {
          staticClass: "tree-group-spin",
          attrs: { spinning: _vm.treeLoading }
        },
        [
          _c("a-tree", {
            staticClass: "jtl-tree",
            attrs: {
              draggable: "",
              "expanded-keys": _vm.expandedKeys,
              "selected-keys": _vm.selectedKeys,
              "tree-data-simple-mode": "",
              "tree-data": _vm.treeData
            },
            on: {
              "update:expandedKeys": function($event) {
                _vm.expandedKeys = $event
              },
              "update:expanded-keys": function($event) {
                _vm.expandedKeys = $event
              },
              "update:selectedKeys": function($event) {
                _vm.selectedKeys = $event
              },
              "update:selected-keys": function($event) {
                _vm.selectedKeys = $event
              },
              select: _vm.treeSelect,
              dragenter: _vm.onDragEnter,
              drop: _vm.onDrop
            },
            scopedSlots: _vm._u([
              {
                key: "custom",
                fn: function(item) {
                  return [
                    _c(
                      "jtl-tooltip-component",
                      { attrs: { title: item.title, placement: "right" } },
                      [_c("span", [_vm._v(_vm._s(item.title))])]
                    ),
                    _vm._.trim(item.id)
                      ? _c(
                          "a-dropdown",
                          {
                            staticClass: "tree-item-action-wrapper",
                            attrs: { trigger: ["click"] }
                          },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "tree-item-action-dot",
                                on: {
                                  click: function(e) {
                                    return e.preventDefault()
                                  }
                                }
                              },
                              [_vm._v("⋮")]
                            ),
                            _c(
                              "a-menu",
                              { attrs: { slot: "overlay" }, slot: "overlay" },
                              [
                                _c(
                                  "a-menu-item",
                                  {
                                    key: "0",
                                    on: {
                                      click: function($event) {
                                        _vm.addGroupClick(
                                          item.pId,
                                          _vm._.get(item, "parent.children") ||
                                            _vm.treeData
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("添加同级分组")]
                                ),
                                _c(
                                  "a-menu-item",
                                  {
                                    key: "1",
                                    on: {
                                      click: function($event) {
                                        return _vm.addGroupClick(
                                          item.id,
                                          item.children
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("添加下级分组")]
                                ),
                                _c(
                                  "a-menu-item",
                                  {
                                    key: "2",
                                    on: {
                                      click: function($event) {
                                        _vm.editGroupClick(
                                          item.pId,
                                          item,
                                          _vm._.get(item, "parent.children") ||
                                            _vm.treeData
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("修改分组")]
                                ),
                                _c(
                                  "a-menu-item",
                                  {
                                    key: "3",
                                    on: {
                                      click: function($event) {
                                        return _vm.deleteGroupClick(item)
                                      }
                                    }
                                  },
                                  [_vm._v("删除分组")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : item.id === ""
                      ? _c(
                          "a-dropdown",
                          {
                            staticClass: "tree-item-action-wrapper",
                            attrs: { trigger: ["click"] }
                          },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "tree-item-action-dot",
                                on: {
                                  click: function(e) {
                                    return e.preventDefault()
                                  }
                                }
                              },
                              [_vm._v("⋮")]
                            ),
                            _c(
                              "a-menu",
                              { attrs: { slot: "overlay" }, slot: "overlay" },
                              [
                                _c(
                                  "a-menu-item",
                                  {
                                    key: "0",
                                    on: {
                                      click: function($event) {
                                        _vm.addGroupClick(
                                          item.pId,
                                          _vm._.get(item, "parent.children") ||
                                            _vm.treeData
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("添加同级分组")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("jtl-entity-dialog", {
        ref: "thingsGroupFormDialog",
        on: { dialogOK: _vm.initTree }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }