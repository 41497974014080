















































































































































































































































































































































































































import { Component, Prop } from 'vue-property-decorator';
import { FormControlType, FormControlOptionModel, FormControlTableModel, FormControlUploadModel } from '@/model/form-control';
import FormComponent from '@/mixins/form-component';
import AttibuteDataTypeValueFormItem from './attribute-data-type-value.vue';
import ThingsModelTypeComponent from './things-model-type.vue';
import ThingsFunctionAutoCompleteComponent from './things-function-auto-complete.vue';
import SelectDeviceAttributeTableComponent from './select-device-attribute-table.vue';
import DataParamComponent from './data-param.vue';
import DataParamEditDialog from './data-param-edit-dialog.vue';
import TimerPickersComponent from './time-pickers.vue';
import JTLFormTableComponent from './form-table.vue';
import MonthSeasonRuleComponent from './month-season-rule.vue';
import TopoTargetSelect from './topo-target-select.vue';
import SelectUserComponent from './select-user.vue';
import LinkageDeviceComponent from './linkage-device.vue';
import FormEnumComponent from './form-enum.vue';
import ResourceUrlComponent from './resource-url.vue';
import { AttributeConfig } from '@/model/attribute-config';
import { DataParamModel } from '@/entity-model/data-param-entity';
import { ViewModeType } from '@/model/enum';

@Component({
    components: {
        'attribute-data-type-value-component': AttibuteDataTypeValueFormItem,
        'things-model-type-component': ThingsModelTypeComponent,
        'things-function-auto-complete-component': ThingsFunctionAutoCompleteComponent,
        'select-device-attribute-table-component': SelectDeviceAttributeTableComponent,
        'data-param-component': DataParamComponent,
        'data-param-edit-dialog': DataParamEditDialog,
        'time-pickers-component': TimerPickersComponent,
        'jtl-form-table': JTLFormTableComponent,
        'month-season-rule-component': MonthSeasonRuleComponent,
        'topo-target-select': TopoTargetSelect,
        'select-user-component': SelectUserComponent,
        'resource-url-component': ResourceUrlComponent,
        'linkage-device-component': LinkageDeviceComponent,
        'form-enum-component': FormEnumComponent
    }
})
export default class JTLForm extends FormComponent<any> {
    FormControlType = FormControlType;
    @Prop({ default: true })
    hasFeedback: boolean;

    addDevice(deviceList: Array<any>) {
        if (!deviceList) {
            deviceList = [];
        }
        deviceList.push(new AttributeConfig());
    }
    deleteDevice(deviceList: Array<any>, index: number) {
        deviceList.splice(index, 1);
    }

    addDataParam(formKey: string) {
        const component: DataParamEditDialog = this.$refs[`dataParamEditDialog_${formKey}`][0];
        if (component) {
            component.dialogOpenClick(new DataParamModel(), ViewModeType.NEW);
        }
    }
    editDataParam(formKey: string, dialogParam: {dataParam: DataParamModel, index: number}) {
        const component: DataParamEditDialog = this.$refs[`dataParamEditDialog_${formKey}`][0];
        if (component) {
            component.dialogOpenClick(dialogParam.dataParam, ViewModeType.UPDATE, dialogParam.index);
        }
    }
    deleteDataParam(dataParamList: Array<DataParamModel>, index: number) {
        dataParamList.splice(index, 1);
    }
    dataParamFormDialogOK(formKey: string, dialogParam: {dataParam: DataParamModel, newOrUpdate: ViewModeType, index: number}) {
        if (!this.formModel[formKey]) {
            this.formModel[formKey] = [];
        }
        if (dialogParam.newOrUpdate === ViewModeType.NEW) {
            this.formModel[formKey].push(dialogParam.dataParam);
        } else {
            this.formModel[formKey].splice(dialogParam.index, 1, dialogParam.dataParam);
        }
    }

    onSelectChange(formControl: FormControlOptionModel) {
        formControl.value = this.formModel[formControl.key];
        if (formControl.cascad) {
            _.forEach(this.formControlList, item => {
                if (item.invisibleFunction) {
                    item.invisible = item.invisibleFunction(this.formModel, this.formControlList);
                }
                if (item.labelFunction) {
                    item.label = item.labelFunction(this.formModel);
                }
            });
        }
        this.initFormRelationKeys(formControl, true);
        this.$emit('change', formControl);
        this.validateField(formControl.key);
        if (formControl.searchText) {
            formControl.searchText = null;
        }
    }
    formTableAdd(formControl:FormControlTableModel) {
        this.$emit('formTableAdd', formControl);
    }

    formTableChange(dataSource, formControl:FormControlTableModel) {
        this.formModel[formControl.key] = dataSource;
    }
}

