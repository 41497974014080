





























import { Component, Prop } from 'vue-property-decorator';
import { BaseComponent } from '@/mixins/base-component';
import moment, { Moment } from 'moment';

@Component
export default class FormEnumComponent extends BaseComponent {
    @Prop()
    value: Array<any>;
    @Prop()
    disabled: boolean;

    addEnumClick() {
        this.value.push({ name: '', value: '' });
    }

    removeEnumClick(index: number) {
        this.value.splice(index, 1);
    }

    validate() {
        if (!this.value || this.value.length === 0) {
            this.showMessageWarning('请添加枚举项');
            return false;
        }
        for (let index = 0; index < this.value.length; index++) {
            if (!this.value[index].value) {
                this.showMessageWarning('请填写枚举的参数值');
                return false;
            }
            if (!this.value[index].name) {
                this.showMessageWarning('请填写枚举的参数描述');
                return false;
            }
        }
        return true;
    }
}

