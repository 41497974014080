var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "view-parking-text" },
    [
      _c(
        "a-row",
        { attrs: { type: "flex", align: "middle" } },
        [
          _c("a-col", { attrs: { span: 8 } }, [
            _c("span", { staticClass: "parking-title" }, [
              _vm._v(_vm._s(_vm.Title))
            ])
          ]),
          _c(
            "a-col",
            { attrs: { span: 16 } },
            [
              _c(
                "a-row",
                [
                  _c(
                    "a-col",
                    { staticClass: "parking-desc", attrs: { span: 12 } },
                    [_vm._v("空车位")]
                  ),
                  _c(
                    "a-col",
                    {
                      staticClass: "parking-count text-right",
                      attrs: { span: 12 }
                    },
                    [_vm._v(_vm._s(_vm._f("text")(_vm.FreeCount)))]
                  )
                ],
                1
              ),
              _c(
                "a-row",
                [
                  _c(
                    "a-col",
                    { staticClass: "parking-desc", attrs: { span: 12 } },
                    [_vm._v("总车位")]
                  ),
                  _c(
                    "a-col",
                    {
                      staticClass: "parking-count text-right",
                      attrs: { span: 12 }
                    },
                    [_vm._v(_vm._s(_vm._f("text")(_vm.TotalCount)))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }