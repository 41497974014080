import { BaseEntityModel } from '@/model/base-model';
import { SpaceType } from '@/model/enum';
import { SimpleTreeModel } from '@/model/simple-tree-model';

export const SPACE_LEVEL_TYPE = [
    { code: SpaceType.BUILDING, name: '建筑' },
    { code: SpaceType.FLOOR, name: '楼层' },
    { code: SpaceType.SPACE, name: '空间' },
    { code: SpaceType.UNIT, name: '单元' }
];

export class SpaceEntityModel extends BaseEntityModel {
    constructor(pId?: string, level: number = 1, name?: string) {
        super();
        this.parentId = pId;
        this.name = name;
        this.level = level || 1;
    }

    name: string = undefined;
    level: number = undefined;
    parentId: string = undefined;
    children: Array<SpaceEntityModel> = undefined;
    type: SpaceType = undefined;

    get LevelType() {
        return _.get(SPACE_LEVEL_TYPE, `[${this.level - 1}].code`);
    }

    toModel(json): any {
        this.id = _.get(json, 'asset.id.id');
        this.name = _.get(json, 'asset.name');
        this.parentId = _.get(json, 'parentId') || '0';
        const children = _.get(json, 'children');
        if (children && children.length > 0) {
            this.children = _.map(children, item => new SpaceEntityModel().toModel(item));
        }
        return this;
    }

    toService() {
        const data = super.toService();
        data.type = this.type || this.LevelType;
        return data;
    }
}

export class SpaceTreeEntityModel extends SimpleTreeModel {
    tagIds:Array<string> = undefined;
    check: boolean = false;

    toModel(json): any {
        this.tagIds = _.get(json, 'tagIds');
        this.title = _.get(json, 'name');
        this.key = this.value = this.id = _.get(json, 'id');
        this.pId = _.get(json, 'parentId');
        this.sort = _.get(json, 'sort');
        if (!_.isUndefined(_.get(json, 'selectable'))) {
            this.selectable = !!_.get(json, 'selectable');
            // this.disabled = !this.selectable;
        }
        if (!_.isUndefined(_.get(json, 'visible'))) {
            this.visible = _.get(json, 'visible') === 'visible';
        }
        if (json && json.children && json.children.length > 0) {
            this.children = _.map(json.children, item => {
                item.parentId = this.id;
                const child: SpaceTreeEntityModel = new SpaceTreeEntityModel().toModel(item);
                child.parent = this;
                return child;
            });
        } else {
            this.children = [];
        }
        return this;
        // return _.toPlainObject(this);
    }

    setSelect(selectedPermissions: Array<string>) {
        if (selectedPermissions && selectedPermissions.indexOf(this.id) > -1) {
            this.check = true;
            _.forEach(this.children, item => {
                item.setSelect(selectedPermissions);
            });
        } else {
            this.check = false;
        }
    }

    toRoleService() {
        const data: any = [this.id];
        if (this.children && this.children.length > 0) {
            data.push(..._.map(_.filter(this.children, item => item.check), (item: SpaceTreeEntityModel) => item.toRoleService()));
        }
        return data;
    }
}
