






































































































































































































import { Component, Prop } from 'vue-property-decorator';
import { BaseComponent } from '@/mixins/base-component';
import { ThingsModelTypeModel } from '@/entity-model/things-model-type-entity';
import { ThingsModelType, ViewModeType, ReadWriteEnum, ThingsFunctionType } from '@/model/enum';
import { thingsModelTypeList } from '@/filter/things-model-type';
import { NUMBER_INT_REGEXP, NUMBER_FLOAT_REGEXP } from '@/model/regexp';
import DataParamComponent from './data-param.vue';
import DataParamEditDialog from './data-param-edit-dialog.vue';
import { DataParamModel } from '@/entity-model/data-param-entity';
import PropertyService from '@/service/property';
import { PropertyQueryModel } from '@/entity-model/property-entity';

@Component({
    components: {
        'data-param-component': DataParamComponent,
        'data-param-edit-dialog': DataParamEditDialog
    }
})
export default class ThingsModelTypeComponent extends BaseComponent {
    ThingsModelType = ThingsModelType;
    ReadWriteEnum = ReadWriteEnum;
    @Prop()
    value: ThingsModelTypeModel;

    @Prop()
    disabled: boolean;

    @Prop()
    thingsType: ThingsFunctionType;

    @Prop({ default: false })
    setValue: boolean;

    // formModel: ThingsModelTypeModel = null;
    formRules: any = null;
    unitList = [];

    get formModel(): ThingsModelTypeModel {
        return this.value;
    }

    get DataStringText() {
        return this.setValue ? '数据值' : '数据长度';
    }

    get DefaultOptions(): Array<{name: string, value: string}> {
        let defaultOptions = null;
        if (this.formModel.thingsModelType === ThingsModelType.BOOLEAN) {
            defaultOptions = [
                { name: this.formModel.dataTrueName, value: 'true' },
                { name: this.formModel.dataFalseName, value: 'false' }
            ];
        } else if (this.formModel.thingsModelType === ThingsModelType.ENUM) {
            defaultOptions = this.formModel.enumList;
        }
        return defaultOptions;
    }

    get DefaultFormValue(): string {
        return `${this.formModel.value}`;
    }

    set DefaultFormValue(val: string) {
        if (this.formModel.thingsModelType === ThingsModelType.BOOLEAN) {
            if (val === 'true') {
                this.formModel.value = true;
            } else {
                this.formModel.value = false;
            }
        } else if (this.formModel.thingsModelType === ThingsModelType.ENUM) {
            this.formModel.value = val;
        }
    }

    created() {
        // this.formModel = this.value;
        this.formRules = {
            thingsModelType: [{ required: true, message: '数据类型是必填项' }],
            accessMode: [{ required: true, message: '读写类型是必填项' }],
            dataString: [
                { required: true, message: `${this.DataStringText}是必填项` },
                { pattern: NUMBER_INT_REGEXP, message: '必须为整数' }
            ],
            dataMin: [
                { required: true, message: '取值范围最小值是必填项' },
                { pattern: NUMBER_INT_REGEXP, message: '必须为整数' },
                { validator: this.minValidator, trigger: 'blur' }
            ],
            dataMax: [
                { required: true, message: '取值范围最大值是必填项' },
                { pattern: NUMBER_INT_REGEXP, message: '必须为整数' },
                { validator: this.maxValidator, trigger: 'blur' }
            ],
            dataTrueName: [{ required: true, message: 'true是必填项' }],
            dataFalseName: [{ required: true, message: 'false是必填项' }],
            jsonParams: [{ required: true, message: 'JSON对象是必填项' }],
            step: [
                { required: true, message: '步长是必填项' },
                // { pattern: NUMBER_INT_REGEXP, message: '必须为整数' },
                { validator: this.stepValidator, trigger: 'blur' }
            ],
            value: [
                { validator: this.valueValidator, trigger: 'blur' }
            ]
        };
        PropertyService.query(new PropertyQueryModel('unit')).then(res => {
            this.unitList = _.map(res, item => {
                return {
                    name: item.name,
                    value: item.code
                };
            });
        });
    }

    get ThingsModelTypeList() {
        if (this.thingsType === ThingsFunctionType.ATTRIBUTE) {
            return thingsModelTypeList;
        } else {
            // 事件、方法、特征的类型排除JSON类型
            return _.filter(thingsModelTypeList, item => item.value !== ThingsModelType.JSON);
        }
    }

    minValidator(rule, value, callback) {
        const min = parseFloat(value);
        const max = parseFloat(`${this.formModel.dataMax}`);
        if (min >= max && this.formModel.dataMax) {
            callback(new Error('最小值必须小于最大值'));
        } else {
            if (this.formModel.dataMax) {
                (this.$refs[JTL.CONSTANT.DEFAULT_FORM_NAME] as any).validateField('dataMax');
            }
            if (this.formModel.step) {
                (this.$refs[JTL.CONSTANT.DEFAULT_FORM_NAME] as any).validateField('step');
            }
            callback();
        }
    }

    maxValidator(rule, value, callback) {
        const min = parseFloat(`${this.formModel.dataMin}`);
        const max = parseFloat(value);
        if (min >= max && this.formModel.dataMin) {
            callback(new Error('最大值必须大于最小值'));
        } else {
            if (this.formModel.dataMin) {
                (this.$refs[JTL.CONSTANT.DEFAULT_FORM_NAME] as any).validateField('dataMin');
            }
            if (this.formModel.step) {
                (this.$refs[JTL.CONSTANT.DEFAULT_FORM_NAME] as any).validateField('step');
            }
            callback();
        }
    }

    stepValidator(rule, value, callback) {
        const min = parseFloat(`${this.formModel.dataMin}`);
        const max = parseFloat(`${this.formModel.dataMax}`);
        const step = parseFloat(`${this.formModel.step}`);
        if (step > max - min) {
            callback(new Error(`不能大于取值范围的差值(${step} > ${max} - ${min})`));
        } else if (this.formModel.thingsModelType === ThingsModelType.INT && !NUMBER_INT_REGEXP.test(`${this.formModel.step}`)) {
            callback(new Error('步长必须为整数'));
        } else {
            callback();
        }
    }

    valueValidator(rule, value, callback) {
        if (!value) {
            callback();
        }
        if ([ThingsModelType.INT, ThingsModelType.FLOAT, ThingsModelType.DOUBLE].indexOf(this.formModel.thingsModelType) > -1) {
            const min = parseFloat(`${this.formModel.dataMin}`);
            const max = parseFloat(`${this.formModel.dataMax}`);
            if (value > max || value < min) {
                callback(new Error(`默认值应在 ${min} 和 ${max} 之间`));
            } else {
                callback();
            }
        } else if ([ThingsModelType.STRING, ThingsModelType.TEXT, ThingsModelType.PASSWORD].indexOf(this.formModel.thingsModelType) > -1) {
            if (value.length > this.formModel.dataString) {
                callback(new Error(`字符个数超过${this.formModel.dataString}个了`));
            } else {
                callback();
            }
        } else {
            callback();
        }
    }

    thingsModelTypeChange(val: ThingsModelType) {
        if (val === ThingsModelType.INT) {
            this.value.step = 1;
            this.formRules.dataMin[1].pattern = this.formRules.dataMax[1].pattern = NUMBER_INT_REGEXP;
            this.formRules.dataMin[1].message = this.formRules.dataMax[1].message = '必须为整数';
            this.formRules.value = [
                { pattern: NUMBER_INT_REGEXP, message: '必须为整数' },
                { validator: this.valueValidator, trigger: 'blur' }
            ];
        } else if (val === ThingsModelType.FLOAT) {
            this.value.step = 0.1;
            this.floatValite();
        } else if (val === ThingsModelType.DOUBLE) {
            this.value.step = 0.01;
            this.floatValite();
        } else if (val === ThingsModelType.STRING || val === ThingsModelType.TEXT || val === ThingsModelType.PASSWORD) {
            this.formRules.value = [
                { validator: this.valueValidator, trigger: 'blur' }
            ];
        }

        this.value.dataMin = -2147483648;
        this.value.dataMax = 2147483648;
        this.value.dataString = 10240;
        // this.value.step = 1;
        this.value.dataTrueName = '';
        this.value.dataFalseName = '';
        this.value.value = null;
        this.clearValidate();
    }

    floatValite():void {
        this.formRules.dataMin[1].pattern = this.formRules.dataMax[1].pattern = NUMBER_FLOAT_REGEXP;
        this.formRules.dataMin[1].message = this.formRules.dataMax[1].message = '必须为浮点数';
        this.formRules.value = [
            { pattern: NUMBER_FLOAT_REGEXP, message: '必须为浮点数' },
            { validator: this.valueValidator, trigger: 'blur' }
        ];
    }

    /**
     * 清除所有表单项验证结果
     */
    clearValidate(): void {
        this.$nextTick(() => {
            if (this.$refs[JTL.CONSTANT.DEFAULT_FORM_NAME]) {
                (this.$refs[JTL.CONSTANT.DEFAULT_FORM_NAME] as any).clearValidate();
            }
        });
    }

    async validate() {
        return await (this.$refs[JTL.CONSTANT.DEFAULT_FORM_NAME] as any).validate();
    }

    // valueChange() {
    //     this.$emit('input', this.value);
    //     this.$emit('change');
    // }

    addDataParam() {
        const component: DataParamEditDialog = this.$refs.dataParamEditDialog as DataParamEditDialog;
        if (component) {
            component.dialogOpenClick(new DataParamModel(), ViewModeType.NEW);
        }
    }

    editDataParam(dialogParam: {dataParam: DataParamModel, index: number}) {
        const component: DataParamEditDialog = this.$refs.dataParamEditDialog as DataParamEditDialog;
        if (component) {
            component.dialogOpenClick(dialogParam.dataParam, ViewModeType.UPDATE, dialogParam.index);
        }
    }

    deleteDataParam(index: number) {
        this.formModel.jsonParams.splice(index, 1);
    }

    dataParamFormDialogOK(dialogParam: {dataParam: DataParamModel, newOrUpdate: ViewModeType, index: number}) {
        if (!this.formModel.jsonParams) {
            this.formModel.jsonParams = [];
        }
        if (dialogParam.newOrUpdate === ViewModeType.NEW) {
            this.formModel.jsonParams.push(dialogParam.dataParam);
        } else {
            this.formModel.jsonParams.splice(dialogParam.index, 1, dialogParam.dataParam);
        }
    }

    addEnumClick() {
        this.formModel.enumList.push({ name: '', value: '' });
    }

    removeEnumClick(index: number) {
        this.formModel.enumList.splice(index, 1);
    }
}

