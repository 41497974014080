




import { Component } from 'vue-property-decorator';
import TopoBaseView from '../topo-base-view';

@Component
export default class ViewButton extends TopoBaseView {
    get Type() {
        return _.get(this.component, 'style.type');
    }
    get Text() {
        return _.get(this.component, 'style.text');
    }
    get Size() {
        return _.get(this.component, 'style.size');
    }
}
