var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "data-param-form-component" },
    _vm._l(_vm.value, function(item, index) {
      return _c(
        "div",
        { key: index, staticClass: "data-param-wrap" },
        [
          _c(
            "a-row",
            { staticClass: "data-param-row", attrs: { gutter: 16 } },
            [
              _c(
                "a-col",
                {
                  staticClass: "data-param-col-name text-ellipsis",
                  attrs: { span: 18, title: _vm._.get(item, "DisplayName") }
                },
                [_vm._v("参数名称: " + _vm._s(_vm._.get(item, "DisplayName")))]
              ),
              !_vm.disabled
                ? _c(
                    "a-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "jtl-edit-link",
                          on: {
                            click: function($event) {
                              return _vm.editClick(item, index)
                            }
                          }
                        },
                        [_vm._v("编辑")]
                      ),
                      _c("a-divider", { attrs: { type: "vertical" } }),
                      _c(
                        "a",
                        {
                          staticClass: "jtl-del-link",
                          on: {
                            click: function($event) {
                              return _vm.deleteClick(index)
                            }
                          }
                        },
                        [_vm._v("删除")]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }