

































































import { Component } from 'vue-property-decorator';
import { BaseComponent } from '@/mixins/base-component';
import OrgService from '@/service/org';

@Component
export default class SelectUserDialog extends BaseComponent {
    searchValue: string = null;
    dialogVisible: boolean = false;
    treeData: Array<any> = null;
    expandedKeys: Array<string> = [];
    checkedKeys: { checked: Array<any>, halfChecked?: Array<any> } = null;
    treeLoading: boolean = true;

    get TrimSearchValue() {
        return _.toUpper(_.trim(this.searchValue));
    }

    get RightDataList() {
        if (!this.checkedKeys || !this.checkedKeys.checked || this.checkedKeys.checked.length === 0) {
            return null;
        }
        let rightDataList: Array<any> = [];
        _.forEach(this.treeData, item => {
            rightDataList.push(...this.getTreeSelectItem(item));
        });
        return _.flattenDeep(rightDataList);
    }

    getTreeSelectItem(treeItem) {
        const dataList = [];
        if (this.checkedKeys && this.checkedKeys.checked && this.checkedKeys.checked.indexOf(treeItem.id) > -1) {
            dataList.push(treeItem);
        }
        if (treeItem.children && treeItem.children.length > 0) {
            _.forEach(treeItem.children, item => {
                dataList.push(...this.getTreeSelectItem(item));
            });
        }
        return dataList;
    }

    dialogOpen(selectedKeys) {
        this.dialogVisible = true;
        this.checkedKeys = { checked: _.map(selectedKeys, item => item.id) };
        this.treeLoading = true;
        OrgService.getOrgUserTree().then(res => {
            this.treeData = res;
        }).catch(err => {
            $log.error(err);
        }).finally(() => {
            this.treeLoading = false;
        });
    }

    onSearchValueChange() {
        if (!this.TrimSearchValue) {
            _.forEach(this.treeData, item => {
                this.initSearchExpandedKeys(item);
            });
            return;
        }
        this.expandedKeys = [];
        _.forEach(this.treeData, item => {
            if (_.toUpper(item.title).indexOf(this.TrimSearchValue) > -1) {
                this.getParentKey(item.parent);
            }
            this.initSearchExpandedKeys(item);
        });
    }

    initSearchExpandedKeys(treeItem) {
        if (!this.TrimSearchValue || _.toUpper(treeItem.title).indexOf(this.TrimSearchValue) > -1) {
            this.getParentKey(treeItem);
        }
        if (treeItem.children && treeItem.children.length > 0) {
            _.forEach(treeItem.children, item => {
                this.initSearchExpandedKeys(item);
            });
        }
    }
    getParentKey(treeItem) {
        if (treeItem && treeItem.parent) {
            this.expandedKeys.push(treeItem.parent.id);
            this.getParentKey(treeItem.parent);
        }
    }

    dialogOK() {
        this.$emit('dialogOK', _.map(this.RightDataList, item => {
            return {
                id: item.id,
                name: item.name,
                isUser: item.isUser
            };
        }));
        this.dialogClose();
    }

    dialogClose(): void {
        this.dialogVisible = false;
    }

    removeItemClick(model, index) {
        const removeIndex = _.findIndex(this.checkedKeys.checked, item => item === model.id);
        this.checkedKeys.checked.splice(removeIndex, 1);
    }
    removeAllClick() {
        if (this.checkedKeys) {
            this.checkedKeys = null;
        }
    }

    onCheck(checkedKeys, e:{checked: boolean, checkedNodes, node, event}) {
        _.forEach(_.get(e.node.dataRef, 'children'), item => {
            this.checkChildren(item, e.checked);
        });
    }

    checkChildren(treeItem, checked) {
        treeItem.disabled = checked;
        treeItem.disableCheckbox = checked;
        if (checked) {
            const checkedIndex = _.findIndex(this.checkedKeys.checked, checkedKeyItem => checkedKeyItem === treeItem.id);
            if (checkedIndex > -1) {
                this.checkedKeys.checked.splice(checkedIndex, 1);
            }
        }
        _.forEach(treeItem.children, item => {
            this.checkChildren(item, checked);
        });
    }
}
