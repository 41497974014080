





import { Component } from 'vue-property-decorator';
import TopoBaseView from './topo-base-view';

@Component
export default class ViewText extends TopoBaseView {
    get TextContent() {
        if (this.ComponentData || _.isNumber(this.ComponentData)) {
            return this.ComponentData;
        }
        return '{TEXT}';
    }
}
