









































import { Component, Vue, Prop, Watch, Ref } from 'vue-property-decorator';
import { BaseComponent } from '@/mixins/base-component';
import FormDialogComponent from '@/mixins/form-dialog-component';
import { ThingsGroupEntityModel } from '@/entity-model/things-group-entity';
import { SimpleTreeModel } from '@/model/simple-tree-model';
import ThingsGroupService from '@/service/things-group';
import FacilityGroupService from '@/service/facility-group';
import FacilityConfigGroupService from '@/service/facility-config-group';
import WorkorderGroupService from '@/service/workorder-group';
import { ViewModeType, GroupEntityType } from '@/model/enum';
import { FacilityGroupEntityModel } from '@/entity-model/facility-group-entity';
import { FacilityConfigGroupEntityModel } from '@/entity-model/facility-config-group-entity';
import { WorkorderGroupEntityModel } from '@/entity-model/workorder-group-entity';
import { ICRUDQ } from '@/model/interface';

@Component
export default class JTLTreeGroup extends BaseComponent {
    treeData: Array<ThingsGroupEntityModel> = null;
    expandedKeys: Array<string> = null;
    selectedKeys: Array<string> = null;
    @Prop()
    groupId: string;
    @Prop()
    groupType: GroupEntityType;

    treeLoading: boolean = true;

    @Ref()
    private thingsGroupFormDialog: FormDialogComponent<ThingsGroupEntityModel>;

    get GroupService(): ICRUDQ<any, any> {
        if (this.groupType === GroupEntityType.FACILITY_CONFIG) {
            return FacilityConfigGroupService;
        } else if ([GroupEntityType.FACILITY, GroupEntityType.FACILITY_TYPE, GroupEntityType.LINKAGE].indexOf(this.groupType) > -1) {
            return FacilityGroupService;
        } else if (this.groupType === GroupEntityType.KNOWLEDGE || this.groupType === GroupEntityType.BACKUP_ASSET) {
            return new WorkorderGroupService(this.groupType);
        }
        return ThingsGroupService;
    }

    mounted() {
        if (this.groupId) {
            this.selectedKeys = [this.groupId];
        }
        this.initTree();
    }

    initTree() {
        this.treeLoading = true;
        this.GroupService.query(this.groupType).then((res: any) => {
            this.treeData = res;
            if (this.groupId) {
                this.expandedKeys = [];
                this.initExpandedKeys(this.treeData);
            } else if (this.treeData && this.treeData.length > 0) {
                this.expandedKeys = [''];
                // 默认选中第一个节点
                const selectGroupId = this.treeData[0].id;
                this.selectedKeys = [selectGroupId];
                // this.$emit('select', this.getSelectGroupIds(this.treeData[0]));
                this.$emit('select', null);
            }
        }).catch(err => {
            this.$emit('select', null);
            $log.error(err);
        }).finally(() => {
            this.treeLoading = false;
        });
    }

    initExpandedKeys(children: Array<SimpleTreeModel>) {
        if (children && children.length > 0) {
            for (let i = 0; i < children.length; i++) {
                if (children[i].id === this.groupId) {
                    this.$emit('select', this.getSelectGroupIds(children[i]));
                    this.getParentKey(children[i].parent);
                    break;
                } else {
                    this.initExpandedKeys(children[i].children);
                }
            }
        }
    }

    getParentKey(parent: SimpleTreeModel) {
        if (parent) {
            this.expandedKeys.push(parent.id);
            if (parent.parent) {
                this.getParentKey(parent.parent);
            }
        }
    }

    treeSelect(selectedKeys, e:{ selected: boolean, selectedNodes, node, event }) {
        const groupId = e.node.value;
        if (!groupId) {
            this.$emit('select', null);
            return;
        }
        if (e.selected) {
            this.$emit('select', this.getSelectGroupIds(e.node.dataRef));
        } else {
            this.selectedKeys = [groupId];
        }
    }

    getSelectGroupIds(treeItem: ThingsGroupEntityModel | SimpleTreeModel) {
        return _.flattenDeep(this.getChildGroupId(treeItem));
    }

    getChildGroupId(treeItem: ThingsGroupEntityModel | SimpleTreeModel) {
        const ids = [];
        if (treeItem) {
            ids.push(treeItem.id);
            if (treeItem.children && treeItem.children.length > 0) {
                ids.push(..._.map(treeItem.children, item => {
                    return this.getChildGroupId(item);
                }));
            }
        }
        return ids;
    }

    addGroupClick(parentId: string, groupNameValidateList: Array<any>) {
        let groupModel = null;
        // const thingsGroupModel = new ThingsGroupEntityModel(this.groupType);
        switch (this.groupType) {
            case GroupEntityType.FACILITY:
            case GroupEntityType.FACILITY_TYPE:
            case GroupEntityType.LINKAGE:
                groupModel = new FacilityGroupEntityModel(this.groupType);
                break;
            case GroupEntityType.FACILITY_CONFIG:
                groupModel = new FacilityConfigGroupEntityModel(this.groupType);
                break;
            case GroupEntityType.KNOWLEDGE:
            case GroupEntityType.BACKUP_ASSET:
                groupModel = new WorkorderGroupEntityModel(this.groupType);
                break;
            default:
                groupModel = new ThingsGroupEntityModel(this.groupType);
                break;
        }
        groupModel.pId = parentId;
        groupModel.groupNameValidateList = groupNameValidateList;
        this.thingsGroupFormDialog.dialogOpen(groupModel, this.GroupService, ViewModeType.NEW);
    }
    editGroupClick(parentId: string, treeItem, groupNameValidateList: Array<any>) {
        treeItem.dataRef.groupNameValidateList = groupNameValidateList;
        treeItem.dataRef.pId = parentId;
        if (this.groupType === GroupEntityType.KNOWLEDGE || this.groupType === GroupEntityType.BACKUP_ASSET) {
            this.thingsGroupFormDialog.dialogOpen(treeItem.dataRef, this.GroupService, ViewModeType.UPDATE, false);
        } else {
            this.thingsGroupFormDialog.dialogOpen(treeItem.dataRef, this.GroupService, ViewModeType.UPDATE, true);
        }
        this.thingsGroupFormDialog.dialogTitle = `编辑分组${treeItem.dataRef.id}`;
    }
    deleteGroupClick(treeItem) {
        const confirm = this.$confirm({
            title: '确认删除',
            content: `确认删除分组${_.get(treeItem, 'dataRef.title')}`,
            onOk: () => {
                this.startFullScreenLoading('正在删除');
                return this.GroupService.delete(treeItem).then((ret) => {
                    // this.initTree();
                    const parentChildren = _.get(treeItem, 'parent.children') || this.treeData;
                    const removeIndex = _.findIndex(parentChildren, (item: any) => item.uuid === treeItem.uuid);
                    parentChildren.splice(removeIndex, 1);
                    this.showMessageSuccess('删除成功');
                    return true;
                }).catch((err) => {
                    throw err;
                }).finally(() => {
                    this.stopFullScreenLoading();
                });
            },
            onCancel: () => {
                confirm.destroy();
            }
        });
    }

    onDragEnter(info: { expandedKeys: Array<string>}) {
        // console.log('onDragEnter');
        // console.log(info);
        // expandedKeys 需要受控时设置
        // this.expandedKeys = info.expandedKeys;
    }
    onDrop(info: {dragNode: {dataRef: any}, node: {dataRef: any}}) {
        // console.log('onDrop');
        // console.log(info);
        const updateGroupModel = info.dragNode.dataRef;
        updateGroupModel.locations = undefined;
        updateGroupModel.pId = info.node.dataRef.id;
        // if (this.expandedKeys.indexOf(updateGroupModel.pId) === -1) {
        //     this.expandedKeys.push(updateGroupModel.pId);
        // }
        this.GroupService.update(updateGroupModel).then(res => {
            this.initTree();
        });
    }
}

