var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "jtl-tree-group-component" },
    [
      _c(
        "a-spin",
        { attrs: { spinning: _vm.treeLoading } },
        [
          _c("a-tree", {
            staticClass: "jtl-tree",
            attrs: {
              "expanded-keys": _vm.expandedKeys,
              "selected-keys": _vm.selectedKeys,
              "tree-data-simple-mode": "",
              "tree-data": _vm.treeData
            },
            on: {
              "update:expandedKeys": function($event) {
                _vm.expandedKeys = $event
              },
              "update:expanded-keys": function($event) {
                _vm.expandedKeys = $event
              },
              "update:selectedKeys": function($event) {
                _vm.selectedKeys = $event
              },
              "update:selected-keys": function($event) {
                _vm.selectedKeys = $event
              },
              select: _vm.treeSelect
            },
            scopedSlots: _vm._u([
              {
                key: "custom",
                fn: function(item) {
                  return [
                    _c(
                      "jtl-tooltip-component",
                      { attrs: { title: item.title, placement: "right" } },
                      [_c("span", [_vm._v(_vm._s(item.title))])]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }