import { BaseEntityModel } from '@/model/base-model';
import { ThingsFunctionType, GroupEntityType } from '@/model/enum';
import ThingsAttributeService from '@/service/things-attribute';
import ThingsEventService from '@/service/things-event';
import ThingsMethodService from '@/service/things-method';
import { thingsModelFunctionFilter, thingsModelTypeFilter, eventLevelFilter, attributeTypeFilter, readWriteFilter } from '@/filter';
import { ThingsAttributeEntityModel } from './things-attribute-entity';
import { ThingsEventEntityModel } from './things-event-entity';
import { ThingsMethodEntityModel } from './things-method-entity';
import { DeviceTypeFeatureModel } from './device-type-feature';
// import DeviceTypeService from '@/service/device-type';
// import ThingsFeatureService from '@/service/things-feature';
import CommonService from '@/service/common';
import { FormControl, FormControlType, FormControlSelectGroupModel } from '@/model/form-control';
export class ThingsFunctionEntityModel extends BaseEntityModel {
    attrs: Array<ThingsAttributeEntityModel>;
    events: Array<ThingsEventEntityModel>;
    methods: Array<ThingsMethodEntityModel>;
    features: Array<DeviceTypeFeatureModel>;

    public getFunctionList(funcionType: ThingsFunctionType) {
        let filterDataList = null;
        switch (funcionType) {
            case ThingsFunctionType.ATTRIBUTE:
                filterDataList = this.attrs;
                break;
            case ThingsFunctionType.METHOD:
                filterDataList = this.methods;
                break;
            case ThingsFunctionType.EVENT:
                filterDataList = this.events;
                break;
            case ThingsFunctionType.FEATURE:
                filterDataList = this.features;
        }
        return filterDataList;
    }

    public getFunctionName(funcionType: ThingsFunctionType) {
        return thingsModelFunctionFilter(funcionType);
    }

    public deleteFunction(funcionType: ThingsFunctionType, model: ThingsFunctionEntityModel, entityId?: string): Promise<any> {
        let deleteService: (model: any, entityId?: string) => Promise<any> = null;
        switch (funcionType) {
            case ThingsFunctionType.ATTRIBUTE:
                deleteService = ThingsAttributeService.delete;
                break;
            case ThingsFunctionType.METHOD:
                deleteService = ThingsMethodService.delete;
                break;
            case ThingsFunctionType.EVENT:
                deleteService = ThingsEventService.delete;
                break;
            case ThingsFunctionType.FEATURE:
                // deleteService = DeviceTypeService.deleteFeature;
                // deleteService = ThingsFeatureService.deleteDeviceFeature;
                deleteService = CommonService.deleteDeviceFeature;
                break;
        }
        return deleteService(model, entityId);
    }

    toModel(json): any {
        super.toModel(_.get(json, 'entity') || (_.get(json, 'type.id') ? _.get(json, 'type') : json));
        this.attrs = _.map(_.get(json, 'attrs'), item => new ThingsAttributeEntityModel().toModel(item));
        this.events = _.map(_.get(json, 'events'), item => new ThingsEventEntityModel().toModel(item));
        this.methods = _.map(_.get(json, 'methods'), item => new ThingsMethodEntityModel().toModel(item));
        this.features = _.map(_.get(json, 'features'), item => new DeviceTypeFeatureModel().toModel(item));
        return this;
    }

    static getFunctionTabelColumns(): Array<any> {
        return [
            {
                title: '序号',
                dataIndex: 'idx',
                customRender: function(text, record, index) {
                    return index + 1;
                }
            },
            {
                title: '名称',
                dataIndex: 'name'
                // scopedSlots: { customRender: 'name' }
            },
            {
                title: '标识符',
                dataIndex: 'identifier',
                customRender: function(text, record, index) {
                    return _.get(record, 'identifier') || _.get(record, 'identifier');
                }
            },
            {
                title: '数据类型',
                dataIndex: 'type',
                customRender: function(text, record, index) {
                    const dataType = _.get(record, 'thingsModelTypeModel.thingsModelType');
                    return thingsModelTypeFilter(dataType);
                },
                functionType: [ThingsFunctionType.ATTRIBUTE]
            },
            // {
            //     title: '数据定义',
            //     dataIndex: 'thingsModelTypeModel'
            //     // customRender: (text, record, index) => {
            //     //     return thingsModelTypeFilter(text);
            //     // }
            // },
            {
                title: '读写类型',
                dataIndex: 'accessMode',
                customRender: function(text, record, index) {
                    return readWriteFilter(text);
                },
                functionType: [ThingsFunctionType.ATTRIBUTE]
            },
            {
                title: '属性范围',
                dataIndex: 'attributeType',
                customRender: (text, record, index) => {
                    return attributeTypeFilter(text);
                },
                functionType: [ThingsFunctionType.ATTRIBUTE]
            },
            {
                title: '事件类型',
                dataIndex: 'level',
                customRender: function(text, record, index) {
                    return eventLevelFilter(text);
                },
                functionType: [ThingsFunctionType.EVENT]
            },
            {
                title: '操作',
                dataIndex: 'action',
                scopedSlots: { customRender: 'action' },
                functionType: [ThingsFunctionType.ATTRIBUTE, ThingsFunctionType.EVENT, ThingsFunctionType.METHOD]
            },
            {
                title: '操作',
                dataIndex: 'featureAction',
                scopedSlots: { customRender: 'featureAction' },
                functionType: [ThingsFunctionType.FEATURE]
            }
        ];
    }
}

export class ThingsFunctionGroupModel extends ThingsFunctionEntityModel {
    @FormControl({
        label: '分组',
        type: FormControlType.SELECT_GROUP,
        required: true
    } as FormControlSelectGroupModel)
    groupId: string = undefined;
    groupName: string = undefined;
    groupType: GroupEntityType;

    constructor(groupId?: string, groupType?: GroupEntityType) {
        super();
        this.groupId = groupId || undefined;
        this.groupType = groupType;
    }

    toService(): any {
        const data = {
            groupId: this.groupId,
            template: super.toService()
        };
        delete data.template.groupId;
        return data;
    }
}
