var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      staticClass: "form-edit-component-large",
      attrs: { title: "选择用户", visible: _vm.dialogVisible },
      on: { cancel: _vm.dialogClose }
    },
    [
      _c(
        "div",
        {
          staticClass:
            "select-user-dialog form-edit-component-large transform-dialog"
        },
        [
          _c(
            "a-spin",
            { attrs: { spinning: _vm.treeLoading } },
            [
              _c(
                "a-row",
                {
                  staticClass: "transform-header",
                  attrs: { type: "flex", align: "bottom" }
                },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 11 } },
                    [
                      _c(
                        "a-row",
                        { attrs: { gutter: 16 } },
                        [
                          _c(
                            "a-col",
                            { attrs: { span: 12 } },
                            [
                              _c("a-input-search", {
                                attrs: {
                                  placeholder: "请输入姓名搜索",
                                  "allow-clear": ""
                                },
                                on: { change: _vm.onSearchValueChange },
                                model: {
                                  value: _vm.searchValue,
                                  callback: function($$v) {
                                    _vm.searchValue = $$v
                                  },
                                  expression: "searchValue"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("a-col", { attrs: { span: 11, offset: 2 } }, [
                    _vm._v(" 已选:"),
                    _c(
                      "a",
                      {
                        staticClass: "transform-remove-all jtl-link pull-right",
                        on: { click: _vm.removeAllClick }
                      },
                      [_vm._v("全部删除")]
                    )
                  ])
                ],
                1
              ),
              _c(
                "a-row",
                [
                  _c(
                    "a-col",
                    { staticClass: "transform-column", attrs: { span: 11 } },
                    [
                      _c("a-tree", {
                        staticClass: "jtl-tree",
                        attrs: {
                          checkable: "",
                          "check-strictly": true,
                          "default-expand-all": true,
                          "expanded-keys": _vm.expandedKeys,
                          "tree-data-simple-mode": "",
                          "tree-node-filter-prop": "title",
                          "tree-data": _vm.treeData
                        },
                        on: {
                          "update:expandedKeys": function($event) {
                            _vm.expandedKeys = $event
                          },
                          "update:expanded-keys": function($event) {
                            _vm.expandedKeys = $event
                          },
                          check: _vm.onCheck
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "custom",
                            fn: function(item) {
                              return [
                                _c("span", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.getHighLightHtml(
                                        item.title,
                                        _vm.searchValue
                                      )
                                    )
                                  }
                                }),
                                item.isUser
                                  ? _c("span", { staticClass: "text-desc" }, [
                                      _vm._v("(用户)")
                                    ])
                                  : _c("span", { staticClass: "text-desc" }, [
                                      _vm._v("(部门)")
                                    ])
                              ]
                            }
                          }
                        ]),
                        model: {
                          value: _vm.checkedKeys,
                          callback: function($$v) {
                            _vm.checkedKeys = $$v
                          },
                          expression: "checkedKeys"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    {
                      staticClass: "transform-column-middle",
                      attrs: { span: 2 }
                    },
                    [
                      _c("jtl-icon", {
                        staticClass: "center",
                        attrs: { type: "transform" }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { staticClass: "transform-column", attrs: { span: 11 } },
                    [
                      _c(
                        "ul",
                        { staticClass: "ul-list" },
                        _vm._l(_vm.RightDataList, function(item, index) {
                          return _c(
                            "li",
                            { key: index },
                            [
                              _c(
                                "jtl-tooltip-component",
                                {
                                  staticClass:
                                    "transform-name transform-name-right text-ellipsis",
                                  attrs: {
                                    title: item.name,
                                    placement: "right"
                                  }
                                },
                                [
                                  _vm._v(" " + _vm._s(item.name) + " "),
                                  item.isUser
                                    ? _c("span", { staticClass: "text-desc" }, [
                                        _vm._v("(用户)")
                                      ])
                                    : _c("span", { staticClass: "text-desc" }, [
                                        _vm._v("(部门)")
                                      ])
                                ]
                              ),
                              _c("a-icon", {
                                staticClass: "right-close-icon",
                                attrs: { type: "close-circle" },
                                on: {
                                  click: function($event) {
                                    return _vm.removeItemClick(item, index)
                                  }
                                }
                              })
                            ],
                            1
                          )
                        }),
                        0
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "jtl-button",
            { attrs: { "click-prop": _vm.dialogClose.bind(this) } },
            [_vm._v("取消")]
          ),
          _c(
            "jtl-button",
            {
              attrs: { type: "primary", "click-prop": _vm.dialogOK.bind(this) }
            },
            [_vm._v("保存")]
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }