












































import { Component, Prop } from 'vue-property-decorator';
import { BaseComponent } from '@/mixins/base-component';
import { ThingsFunctionType } from '@/model/enum';
import { thingsModelFunctionFilter } from '@/filter';

@Component
export default class JTLTransformComponent extends BaseComponent {
    loading: boolean = false;
    filterValue: string = null;
    @Prop()
    functionType: ThingsFunctionType;
    @Prop()
    allDataList: Array<any>;

    /**
     * 根据名称获取颜色
     */
    get NameColor(): string {
        let nameColor = null;
        switch (this.functionType) {
            case ThingsFunctionType.ATTRIBUTE:
                nameColor = JTL.CONSTANT.COLORS[5];
                break;
            case ThingsFunctionType.EVENT:
                nameColor = JTL.CONSTANT.COLORS[3];
                break;
            case ThingsFunctionType.METHOD:
                nameColor = JTL.CONSTANT.COLORS[2];
                break;
            case ThingsFunctionType.FEATURE:
                nameColor = JTL.CONSTANT.COLORS[1];
                break;
        }
        return nameColor;
    }

    get FunctionName() {
        return thingsModelFunctionFilter(this.functionType);
    }

    get RightDataList() {
        return _.filter(this.allDataList, item => item.check);
    }

    get FilterDataList() {
        if (this.TrimFilterValue) {
            return _.filter(this.allDataList, item => item.name && item.name.indexOf(this.TrimFilterValue) > -1);
        }
        return this.allDataList;
    }

    get TrimFilterValue() {
        return _.trim(this.filterValue);
    }

    removeItemClick(item, index) {
        item.check = false;
    }

    removeAllClick() {
        _.forEach(this.allDataList, item => {
            item.check = false;
        });
    }
}

