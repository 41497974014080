
























import { Component, Prop, Watch } from 'vue-property-decorator';
import { BaseComponent } from '@/mixins/base-component';

@Component
export default class JTLTreeSelectComponent extends BaseComponent {
    @Prop()
    value: any;

    @Prop({ default: false })
    loading: any;

    @Prop({ default: '请选择' })
    placeholder: string;

    @Prop({ default: () => { return []; } })
    treeData: Array<any>;

    @Prop({ default: false })
    multiple: boolean;

    @Prop()
    disabled: boolean;

    @Prop({ default: false })
    selectChildren: boolean;

    @Prop()
    expandedKeys: Array<string>;

    @Prop()
    onlySelectLeaf: boolean;

    /**
     * true: 不能交叉选择
     * false: 能交叉选择
     */
    @Prop()
    disableSelectCross: boolean;

    @Prop()
    theme: string;

    @Watch('value', { immediate: false, deep: true })
    valueChange(newVal: any): void {
        if (this.disableSelectCross) {
            const firstValue = this.multiple ? _.get(newVal, '[0]') : newVal;
            // console.log('newVal', newVal);
            this.initTreeDisable(this.treeData, firstValue, !!firstValue);
        }
    }

    /**
     * 初始化树每个节点的disable状态
     * firstValue: 选中节点的第一个值
     * findNext: 是否需要递归向下查找
     */
    initTreeDisable(parentTree, firstValue, findNext) {
        let disabledVal = false;
        if (firstValue) {
            if (findNext) {
                disabledVal = !_.find(parentTree, item => item.id === firstValue);
                findNext = disabledVal;
            } else {
                disabledVal = true;
            }
        } else {
            disabledVal = false;
            findNext = false;
        }
        _.forEach(parentTree, item => {
            item.disabled = disabledVal;
            this.initTreeDisable(item.children, firstValue, findNext);
        });
    }

    get TreeValue() {
        return this.value;
    }

    set TreeValue(val: any) {
        // selectChange
    }

    selectChange(val, label, extra) {
        let outputVal = null;
        let outputObj = null;
        const nodeModel = _.get(extra, 'triggerNode.dataRef');
        if (this.onlySelectLeaf && nodeModel && nodeModel.children && nodeModel.children.length > 0) {
            outputVal = this.getSelectGroupIds(nodeModel);
            // Remove parent self
            outputVal.splice(0, 1);
            if (this.value.length === outputVal.length) {
                outputVal = [];
            }
        } else {
            if (!this.multiple && this.selectChildren) {
                outputVal = this.getSelectGroupIds(nodeModel);
                outputObj = this.getSelectGroupIds(nodeModel, true);
            } else {
                outputVal = val;
                outputObj = nodeModel;
            }
        }

        this.$emit('input', outputVal);
        this.$emit('change', outputVal);
        this.$emit('changeObj', outputObj);
    }

    getSelectGroupIds(treeItem: any, getObj: boolean = false) {
        return _.flattenDeep(this.getChildGroupId(treeItem, getObj));
    }

    getChildGroupId(treeItem: any, getObj: boolean = false) {
        const ids = [];
        if (treeItem) {
            if (getObj) {
                ids.push(treeItem);
            } else {
                ids.push(treeItem.id);
            }
            if (treeItem.children && treeItem.children.length > 0) {
                ids.push(..._.map(treeItem.children, item => {
                    return this.getChildGroupId(item, getObj);
                }));
            }
        }
        return ids;
    }
}

