var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "jtl-tree-component" },
    [
      _c(
        "a-spin",
        { attrs: { spinning: _vm.loading } },
        [
          _c("a-icon", {
            staticStyle: { "font-size": "20px" },
            attrs: { slot: "indicator", type: "loading", spin: "" },
            slot: "indicator"
          }),
          _c("a-tree", {
            staticClass: "jtl-tree",
            attrs: {
              "auto-expand-parent": "",
              "tree-data-simple-mode": "",
              "tree-data": _vm.treeData
            },
            on: { select: _vm.selectChange },
            scopedSlots: _vm._u([
              {
                key: "custom",
                fn: function(item) {
                  return [
                    _c(
                      "jtl-tooltip-component",
                      { attrs: { title: item.title, placement: "right" } },
                      [_c("span", [_vm._v(_vm._s(item.title))])]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }