import { Component, Watch } from 'vue-property-decorator';
import TopoBaseView from '../topo-base-view';

@Component
export default class ViewCanvas extends TopoBaseView {
    @Watch('component', { deep: true })
    detailChange(newVal, oldVal) {
        this.onResize();
    }
    getForeColor() {
        var foreColor = this.component.style.foreColor;
        if (foreColor === undefined || foreColor === '') {
            return 'grey';
        } else if (foreColor.startsWith('#')) {
            return this.hex2rgba(foreColor);
        } else {
            return foreColor;
        }
    }
    hex2rgba(hex) { // 颜色转换
        // hex格式如#ffffff
        let colorArr = [];
        for (let i = 1; i < 7; i += 2) {
            colorArr.push(parseInt('0x' + hex.slice(i, i + 2))); // 16进制值转10进制
        }
        var alpha = parseInt('0x' + hex.slice(7, 9)) / 255; // 16进制值转10进制
        return `rgba(${colorArr.join(',')},${alpha})`;
    }
    inRange(x, y, points) { // 边界检测-只能检测凸边形（检测点在不在多边形里面）
        // points表示多边形的顶点集合
        let inside = false;
        for (let i = 0, j = points.length - 1; i < points.length; j = i++) {
            let xi = points[i][0];
            let yi = points[i][1];
            let xj = points[j][0];
            let yj = points[j][1];
            let intersect = ((yi > y) !== (yj > y)) && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
            if (intersect) { inside = !inside; }
        }
        return inside;
    }
}
