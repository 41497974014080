


























import { Component, Prop } from 'vue-property-decorator';
import TopoControl from './topo-control';
import TopoService from '@topo/service/topo-service';
import { TopoPageEntityModel } from '@topo/model/topo-page-entity-model';
import TopoComponentEntityModel from '@topo/model/topo-component-entity-model';

@Component
export default class TopoRenderComponent extends TopoControl {
    @Prop()
    editMode: boolean;

    @Prop()
    component: TopoComponentEntityModel;

    @Prop()
    topoPageEntityModel:TopoPageEntityModel;
}
