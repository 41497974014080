import Vue from 'vue';
import Vuex from 'vuex';

// import example from '@/pages/topo/store/module-example';
// import topoEditor from '@/pages/topo/store/topo-editor';

Vue.use(Vuex);

// Declare empty store first, dynamically register all modules later.
export default new Vuex.Store({
    // modules: {
    //     example,
    //     topoEditor
    // }
});
