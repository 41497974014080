

























import { Component, Vue } from 'vue-property-decorator';
import TopoBasePanelView from '../topo-base-panel-view';

@Component
export default class ViewChartPanel extends TopoBasePanelView {
    get ChartPanelTitle() {
        return _.get(this.ComponentData, 'title');
    }
    get ChartPanelDesc() {
        return _.get(this.ComponentData, 'desc');
    }
    get TextAlign() {
        return _.get(this.ComponentData, 'textAlign') || 'left';
    }
}

