import { ProtocolType } from '@/model/enum';
const protocolTypeList: Array<{ name: string, value: ProtocolType }> = _.filter(_.map(ProtocolType, item => {
    let name: string = null;
    switch (item) {
        case ProtocolType.MODBUS:
            name = 'Modbus';
            break;
        case ProtocolType.BACNET:
            name = 'Bacnet';
            break;
        case ProtocolType.OPCWEB:
            name = 'OPCweb';
            break;
    }
    return { name, value: item };
}), item => item.name) as any;
const protocolTypeFilter = (val: ProtocolType) => {
    return _.get(_.find(protocolTypeList, item => item.value === val), 'name') || '-';
};
export {
    protocolTypeFilter,
    protocolTypeList
};
