























import { BaseComponent } from '@/mixins/base-component';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { DEFAULT_COLORS } from './constants';

@Component
export default class JtlCustomGauge extends BaseComponent {
    @Prop()
    data: any;
    @Prop()
    settings: any;
    @Prop()
    width: number;
    @Prop()
    percent: number;
    @Prop()
    strokeWidth: number;
    @Prop({ default: DEFAULT_COLORS[0] })
    strokeColor: string;
    @Prop()
    percentDesc: string;

    mounted() {
    }
}
