






import { Component, Vue, Prop } from 'vue-property-decorator';
import Editor from 'wangeditor';

@Component
export default class JTLEditor extends Vue {
    @Prop()
    value: string;

    /**
     * 富文本实例
     */
    private editor: Editor;

    mounted() {
        this.editor = new Editor(this.$refs.editor as any);
        // 配置 onchange 回调函数
        this.editor.config.onchange = newHtml => {
            this.$emit('input', newHtml);
        };
        // 配置触发 onchange 的时间频率，默认为 200ms
        this.editor.config.onchangeTimeout = 500; // 修改为 500ms
        this.editor.config.placeholder = '';
        this.editor.create();
        this.editor.txt.html(this.value);
    }

    beforeDestroy() {
        this.editor.destroy();
        this.editor = null;
    }
}

