var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "jtl-qrcode-component" }, [
    _vm.qrInfo
      ? _c("div", { staticClass: "bgCode" }, [
          _c("div", { staticClass: "top-title" }, [
            _c("img", { staticClass: "logo", attrs: { src: _vm.qrLogo } }),
            _c("div", { staticClass: "project-name" }, [
              _vm._v(_vm._s(_vm.projectName))
            ])
          ]),
          _c(
            "div",
            { staticClass: "qrcode-wrapper" },
            [
              _c("vue-qr", {
                staticClass: "qrcodeBg",
                attrs: {
                  text: _vm.qrInfo.id,
                  margin: 1,
                  size: 150,
                  callback: _vm.texte
                }
              })
            ],
            1
          ),
          _c("div", { staticClass: "content" }, [
            _c("div", [_vm._v(_vm._s(_vm.qrInfo.code))]),
            _c("div", [_vm._v(_vm._s(_vm.qrInfo.name))])
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }