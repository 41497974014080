import TopoComponentEntityModel from './topo-component-entity-model';
import { TopoPageEntityModel } from './topo-page-entity-model';
import { checkByPointInRect } from '@topo/assets/libs/topo';
import { CacheUtil, CACHE_ALL_COMPONENT_KEY, CACHE_ALL_PANEL_COMPONENT_KEY } from '@/model/cache-model';

type CMD_TYPE = 'move' | 'resize' | 'resize-lt' | 'resize-lc' | 'resize-lb' | 'resize-rt' | 'resize-rc' | 'resize-rb' | 'resize-ct' | 'resize-cb' | 'frame-select';

export default class TopoEditorModel extends TopoPageEntityModel {
    debounceDetectFrameSelection: Function = null;
    constructor() {
        super();
        this.debounceDetectFrameSelection = _.debounce(this.detectFrameSelection.bind(this), 200);
    }

    /**
     * 选中的组件，仅在右侧属性栏使用
     */
    selectedComponent: TopoComponentEntityModel = null;

    /**
     * 选中的组件列表
     */
    selectedComponents: Array<TopoComponentEntityModel> = [];

    /**
     * 复制的源组件
     */
    copySrcComponents: Array<TopoComponentEntityModel> = [];

    /**
     * copy计数，对于同一个复制源，每次复制后计数+1
     */
    copyCount: number = 0;
    undoStack = []; //
    redoStack = []; //

    /**
     * 指令类型标识
     */
    flag: CMD_TYPE = null;

    /**
     * 移动组件相关变量
     */
    moveItem = {
        startX: 0,
        startY: 0
    };

    /**
     * resize组件位置相关变量
     */
    resizeItem = {
        startPx: 0,
        startPy: 0,
        left: 0,
        top: 0,
        w: 0,
        h: 0
    };

    /**
     * 框选相关变量
     */
    frameSelectionDiv = {
        width: 0,
        height: 0,
        top: 10,
        left: 10,
        startX: 0,
        startY: 0,
        startPageX: 0,
        startPageY: 0
    };

    /**
     * 当前resize组件
     */
    resizeComponent: TopoComponentEntityModel;

    // get SelectedComponents(): Array<TopoComponentEntityModel> {
    //     const selectedComponents = this.getSelectedComponent(this.component);
    //     return _.flattenDeep(selectedComponents);
    // }

    /**
     * 查询组件是否选中
     * @param componentId 组件id
     */
    getIsSelectedById(componentId: string) {
        return _.findIndex(this.selectedComponents, item => item.id === componentId) > -1;
    }

    /**
     * 获取框选div的样式
     */
    get FrameSelectionDivStyle() {
        return {
            width: `${this.frameSelectionDiv.width}px`,
            height: `${this.frameSelectionDiv.height}px`,
            top: `${this.frameSelectionDiv.top}px`,
            left: `${this.frameSelectionDiv.left}px`
        };
    }

    /**
     * 获取选中组件的名称
     */
    get SelectedComponentName(): string {
        return _.get(this.SelectedComponent, 'name');
    }
    set SelectedComponentName(name: string) {
        if (this.SelectedComponent) {
            this.SelectedComponent.name = name;
        }
    }

    /**
     * 获取选中的第一个组件
     */
    get SelectedComponent(): TopoComponentEntityModel {
        if (this.selectedComponents && this.selectedComponents.length === 1) {
            return this.selectedComponents[0];
        }
        return null;
    }

    /**
     * 获取是否选中多个组件
     */
    get IsSelectMoreThanOne(): boolean {
        return this.selectedComponents.length > 1;
    }

    get IsResizeCmd() {
        return this.flag && this.flag.indexOf('resize') > -1;
    }

    /**
     * 添加选中组件
     * @param component 组件
     * @param isSingle 是否唯一
     * @param unselect 是否反选
     */
    addSelectedComponent(component: TopoComponentEntityModel, isSingle: boolean, unselect: boolean = false) {
        if (component === this.component) {
            // root节点的组件禁止选中
            return;
        }
        if (isSingle) {
            this.selectedComponent = component;
        } else {
            this.selectedComponent = null;
        }
        const selectedIndex = _.findIndex(this.selectedComponents, item => item.id === component.id);
        if (isSingle && selectedIndex === -1) {
            // 唯一模式 且 component未被选中
            this.selectedComponents = [component];
        } else if (selectedIndex === -1) {
            this.selectedComponents.push(component);
        } else if (selectedIndex > -1 && unselect) {
            this.selectedComponents.splice(selectedIndex, 1);
        }
    }

    /**
     * 从选中组件列表中移出
     * @param component 移出组件
     */
    removeSelectedComponent(component: TopoComponentEntityModel) {
        const selectedIndex = _.findIndex(this.selectedComponents, item => item.id === component.id);
        if (selectedIndex > -1) {
            this.selectedComponents.splice(selectedIndex, 1);
        }
    }

    /**
     * 清空选中组件列表
     */
    clearSelectedComponents() {
        this.selectedComponents = [];
    }

    /**
     * 编辑窗口鼠标移动事件
     * @param event
     */
    onRootPanelMousemove(event) {
        if (event.which !== 1) {
            this.flag = null;
            return;
        }
        if (this.IsResizeCmd) {
            let dx = event.pageX - this.resizeItem.startPx;
            let dy = event.pageY - this.resizeItem.startPy;
            switch (this.flag) {
                case 'resize-lt':
                    this.resizeComponent.style.position.left = this.resizeItem.left + dx;
                    this.resizeComponent.style.position.top = this.resizeItem.top + dy;
                    dx = -dx;
                    dy = -dy;
                    break;
                case 'resize-lc':
                    this.resizeComponent.style.position.left = this.resizeItem.left + dx;
                    dy = 0;
                    dx = -dx;
                    break;
                case 'resize-lb':
                    this.resizeComponent.style.position.left = this.resizeItem.left + dx;
                    dx = -dx;
                    break;
                case 'resize-rt':
                    this.resizeComponent.style.position.top = this.resizeItem.top + dy;
                    dy = -dy;
                    break;
                case 'resize-rc':
                    dy = 0;
                    break;
                case 'resize-rb':
                    break;
                case 'resize-ct':
                    this.resizeComponent.style.position.top = this.resizeItem.top + dy;
                    dx = 0;
                    dy = -dy;
                    break;
                case 'resize-cb':
                    dx = 0;
                    break;
            }
            if ((this.resizeItem.w + dx) > 20) {
                this.resizeComponent.style.position.w = this.resizeItem.w + dx;
            }
            if ((this.resizeItem.h + dy) > 20) {
                this.resizeComponent.style.position.h = this.resizeItem.h + dy;
            }
        } else if (this.flag === 'move') {
            this.onPanelMouseHandle(event, false);
            // 移动组件
            let dx = event.pageX - this.moveItem.startX;
            let dy = event.pageY - this.moveItem.startY;
            _.forEach(this.selectedComponents, item => {
                if (item.style.tempPosition) {
                    item.style.position.left = item.style.tempPosition.left + dx;
                    item.style.position.top = item.style.tempPosition.top + dy;
                }
            });
        } else if (this.flag === 'frame-select') {
            this.onFrameSelection(event);
        }
    }

    /**
     * 鼠标在panel组件上move/up事件处理
     * @param event
     * @param isMouseUp 是否up事件
     */
    private onPanelMouseHandle(event: MouseEvent, isMouseUp: boolean) {
        if (!isMouseUp && event.altKey) {
            // 按住alt键时，选中的组件都移到root panel中
            const notInRootPanel = _.map(_.filter(this.selectedComponents, item => item.parent.id !== this.component.id), item => {
                item.removeSelf();
                item.style.tempPosition.left += item.parent.style.position.left;
                item.style.tempPosition.top += item.parent.style.position.top;
                item.parent = this.component;
                return item;
            });
            if (!this.component.children) {
                this.component.children = [];
            }
            if (notInRootPanel && notInRootPanel.length > 0) {
                this.component.children = _.union(this.component.children, notInRootPanel);
            }
            return;
        }
        const panelComponents = this.findAllPanelComponent();
        if (panelComponents) {
            let hasFindPanel: boolean = false;
            for (let i = panelComponents.length - 1; i > -1; i--) {
                const currentPanelComponent = panelComponents[i];
                if (_.findIndex(this.selectedComponents, item => item.id === currentPanelComponent.id) === -1) {
                    // 选中的组件中不包含当前的panel组件
                    const panelElement = document.getElementById(currentPanelComponent.id);
                    const panelRect = currentPanelComponent.getElementRect();
                    if (panelRect) {
                        // 检测鼠标坐标是否在panel组件内部
                        if (checkByPointInRect(panelRect, { x: event.pageX, y: event.pageY, width: 0, height: 0 })) {
                            // 鼠标在当前panel组件内
                            if (isMouseUp) {
                                // 鼠标up时处理
                                panelElement.className = panelElement.className.replace('topo-panel-dragover', '');
                                if (!hasFindPanel) {
                                    // 只在最上层的panel里添加子组件
                                    hasFindPanel = true;
                                    const panelInComponentTree = _.get(this.findComponent([currentPanelComponent.id]), '[0]') as TopoComponentEntityModel;
                                    if (panelInComponentTree) {
                                    // 筛选不在当前容器中的选中的组件
                                        const selectedComponentsNotInPanelChildren = _.filter(this.selectedComponents, item => {
                                            return _.findIndex(panelInComponentTree.children, child => child.id === item.id) === -1;
                                        });
                                        // 先从源父容器中移除
                                        _.forEach(selectedComponentsNotInPanelChildren, item => {
                                            item.removeSelf();
                                            // 计算选中组件的相对位置
                                            item.style.position.left -= panelInComponentTree.style.position.left;
                                            item.style.position.top -= panelInComponentTree.style.position.top;
                                            item.clonePosition();
                                            item.parent = panelInComponentTree;
                                        });
                                        panelInComponentTree.children = _.union(panelInComponentTree.children, selectedComponentsNotInPanelChildren);
                                    }
                                }
                            } else {
                                // 鼠标move时处理, Move时只附着到最上层的panel组件
                                if (panelElement.className.indexOf('topo-panel-dragover') === -1) {
                                    panelElement.className += ' topo-panel-dragover';
                                }
                                break;
                            }
                        } else {
                            // 鼠标不在当前panel组件内
                            panelElement.className = panelElement.className.replace('topo-panel-dragover', '');
                        }
                    }
                }
            }
        }
    }

    /**
     * 编辑窗口鼠标抬起事件
     * @param event
     */
    onRootPanelMouseup(event) {
        if (this.IsResizeCmd) {
            // var a = this.$refs.component as any;
            // a.onResize();
        } else if (this.flag === 'move') {
            // 鼠标move只是方便用户预览，真正执行应该用命令，所以要先恢复
            // var dx = event.pageX - this.moveItem.startX;
            // var dy = event.pageY - this.moveItem.startY;
            // _.forEach(this.selectedComponents, item => {
            //     item.style.position.left -= dx;
            //     item.style.position.top -= dy;
            // });
            // this.execute({
            //     op: 'move',
            //     dx: dx,
            //     dy: dy,
            //     items: this.selectedComponents
            // });
            this.onPanelMouseHandle(event, true);
            CacheUtil.cleanCache(CACHE_ALL_PANEL_COMPONENT_KEY);
        } else if (this.flag === 'frame-select') {
            this.frameSelectionDiv.width = 0;
            this.frameSelectionDiv.height = 0;
            this.frameSelectionDiv.top = 0;
            this.frameSelectionDiv.left = 0;
            CacheUtil.cleanCache(CACHE_ALL_COMPONENT_KEY);
        }
        this.flag = null;
    }

    /**
     * 编辑窗口鼠标按下事件
     * @param event
     */
    onRootPanelMousedown(event) {
        this.flag = 'frame-select';
        this.frameSelectionDiv.startX = event.offsetX;
        this.frameSelectionDiv.startY = event.offsetY;
        this.frameSelectionDiv.startPageX = event.pageX;
        this.frameSelectionDiv.startPageY = event.pageY;
    }

    onFrameSelection(event) {
        var dx = event.pageX - this.frameSelectionDiv.startPageX;
        var dy = event.pageY - this.frameSelectionDiv.startPageY;
        this.frameSelectionDiv.width = Math.abs(dx);
        this.frameSelectionDiv.height = Math.abs(dy);
        if (dx > 0 && dy > 0) {
            this.frameSelectionDiv.top = this.frameSelectionDiv.startY;
            this.frameSelectionDiv.left = this.frameSelectionDiv.startX;
        } else if (dx > 0 && dy < 0) {
            this.frameSelectionDiv.top = this.frameSelectionDiv.startY + dy;
            this.frameSelectionDiv.left = this.frameSelectionDiv.startX;
        } else if (dx < 0 && dy > 0) {
            this.frameSelectionDiv.top = this.frameSelectionDiv.startY;
            this.frameSelectionDiv.left = this.frameSelectionDiv.startX + dx;
        } else if (dx < 0 && dy < 0) {
            this.frameSelectionDiv.top = this.frameSelectionDiv.startY + dy;
            this.frameSelectionDiv.left = this.frameSelectionDiv.startX + dx;
        }
        this.debounceDetectFrameSelection();
    }

    private detectFrameSelection() {
        const frameSelectElement = document.getElementById('topo_frame_selection');
        const frameRect = frameSelectElement.getBoundingClientRect();
        // 判断各个组件是否在方框内
        var rect = {
            x: frameRect.x,
            y: frameRect.y,
            width: frameRect.width,
            height: frameRect.height
        };
        _.forEach(this.findAllComponent(), component => {
            const componentRect = component.getElementRect();
            if (componentRect) {
                if (checkByPointInRect(rect, componentRect)) {
                    this.addSelectedComponent(component, false);
                } else {
                    this.removeSelectedComponent(component);
                }
            }
        });
    }

    /**
     * 点击组件事件
     * @param component
     * @param event
     */
    clickComponent(component, event) { // 点击组件
        if (event.ctrlKey) { // 点击了ctrl
            this.addSelectedComponent(component, false, true);
        }
    }

    /**
     * 组件鼠标按下事件
     * @param component
     * @param event
     */
    controlMousedown(component: TopoComponentEntityModel, event: MouseEvent) {
        if (event.ctrlKey) {
            return;
        }
        if (component === this.component) {
            this.clearSelectedComponents();
            // root节点的组件禁止选中
            return;
        }
        event.stopPropagation();
        this.addSelectedComponent(component, true);
        this.flag = 'move';
        this.moveItem.startX = event.pageX;
        this.moveItem.startY = event.pageY;
        // 记录初始信息--move
        _.forEach(this.selectedComponents, item => {
            item.clonePosition();
        });
    }

    /**
     * 组件移动锚点的鼠标按下事件
     * @param component
     * @param event
     * @param flag
     */
    resizeMousedown(component, event, flag) { // resize-鼠标按下事件
        this.flag = flag;
        this.resizeComponent = component;
        this.resizeItem.startPx = event.pageX;
        this.resizeItem.startPy = event.pageY;
        // 记录初始信息-resize
        this.resizeItem.left = this.resizeComponent.style.position.left;
        this.resizeItem.top = this.resizeComponent.style.position.top;
        this.resizeItem.w = this.resizeComponent.style.position.w as number;
        this.resizeItem.h = this.resizeComponent.style.position.h as number;
    }

    /**
     * 移动组件
     * @param direction
     */
    moveComponent(direction) {
        var dx = 0; var dy = 0;
        if (direction === 'up') {
            dy = -5;
        } else if (direction === 'right') {
            dx = 5;
        } else if (direction === 'down') {
            dy = 5;
        } else if (direction === 'left') {
            dx = -5;
        }
        this.execute({
            op: 'move',
            dx: dx,
            dy: dy,
            items: this.selectedComponents
        });
    }

    /**
     * 拖拽释放事件
     * @param event
     * @param parent
     */
    onDrop(event, parent: TopoComponentEntityModel) {
        event.preventDefault();
        const parentPanelComponent = this.findParentPanelComponent(parent);
        if (!parentPanelComponent) {
            console.warn('There is no panel component.');
            return;
        }
        const infoJson = event.dataTransfer.getData('my-info');
        if (!infoJson) {
            console.warn('This component not surpport.');
            return;
        }
        const component = new TopoComponentEntityModel().toModel(JSON.parse(infoJson));
        const target = event.currentTarget;
        const position = target.getBoundingClientRect();
        const left = event.clientX - position.left;
        const top = event.clientY - position.top;
        component.style.position.left = left;
        component.style.position.top = top;
        component.parent = parentPanelComponent;
        if (!parentPanelComponent.children) {
            parentPanelComponent.children = [];
        }
        parentPanelComponent.children.push(component);
        // 默认选中，并点击
        this.addSelectedComponent(component, true);
        document.getElementById('topo_main_editor_components_wrapper').focus();
    }

    /**
     * 删除选中的组件
     */
    deleteSelectedComponents() {
        this.execute({
            op: 'del',
            items: this.selectedComponents
        });
    }

    /**
     * 执行命令
     * @param command 命令
     */
    execute(command) {
        // 暂时不做参数校验
        // 在这里分发命令--这里暂时先用switch分发，应该用表格分发
        switch (command.op) {
            case 'del':
                _.forEach(command.items, (item: TopoComponentEntityModel) => {
                    item.removeSelf();
                });
                this.clearSelectedComponents();
                break;
            case 'move':
                var dx = command.dx;
                var dy = command.dy;
                _.forEach(command.items, item => {
                    item.style.position.left = item.style.position.left + dx;
                    item.style.position.top = item.style.position.top + dy;
                });
                break;
            // case 'copy-add':
            //     this.clearSelectedComponent();
            //     for (let i = 0; i < command.items.length; i++) {
            //         var t = command.items[i];
            //         const component = _.cloneDeep(t);
            //         component.id = generateUUID();
            //         component.name = component.type + this.components.length;
            //         component.style.visible = true;
            //         // 这里应该根据选中的的组件确定位置-暂时用个数
            //         // component.style.position.x =
            //         //     component.style.position.x + 25 * (this.copyCount + 1);
            //         // component.style.position.y =
            //         //     component.style.position.y + 25 * (this.copyCount + 1);
            //         // component.style.position.x = component.style.position.x;
            //         component.style.position.y = component.style.position.y + 100;
            //         this.components.push(component);
            //         this.addSelectedComponent(component);
            //         this.increaseCopyCount();
            //     }
            //     break;
            default:
                console.warn('不支持的命令.');
                break;
        }
        // 记录操作
        this.undoStack.push(command);
    }

    /**
     * 撤销操作
     */
    undo() {
        // var command = this.undoStack.pop();
        // if (command === undefined) {
        //     console.log('none undo command.');
        //     return;
        // }

        // switch (command.op) {
        //     case 'add':
        //         {
        //             const component = command.component;
        //             // 逆向循环删除
        //             for (var i = this.components.length - 1; i >= 0; i--) {
        //                 if (component.id === this.components[i].id) {
        //                     this.components.splice(i, 1);
        //                     break;
        //                 }
        //             }
        //         }
        //         break;
        //     case 'del':
        //         break;
        //     case 'move':
        //         var dx = command.dx;
        //         var dy = command.dy;
        //         for (var key in this.selectedComponentMap) {
        //             const component = this.selectedComponentMap[key];
        //             component.style.position.left = component.style.position.left - dx;
        //             component.style.position.top = component.style.position.top - dy;
        //         }
        //         break;
        //     case 'copy-add':
        //         console.log('undo暂不支持：copy-add');
        //         break;
        //     default:
        //         break;
        // }

        // this.redoStack.push(command);
    }

    /**
     * 回复操作
     */
    redo() {
        var command = this.redoStack.pop();
        if (command === undefined) {
            console.log('none redo command.');
            return;
        }
        this.execute(command);
    }

    /**
     * 复制组件
     */
    copyComponent() {
        this.copySrcComponents = _.cloneDeep(this.selectedComponents);
        this.copyCount = 0;
    }

    /**
     * 粘贴组件
     */
    pasteComponent() {
        if (this.copySrcComponents && this.copySrcComponents.length > 0) {
            this.component.children.push(...this.copySrcComponents);
            this.copyCount++;
        }
    }
}
