import { TopoActionType } from '../model/enum';

const topoActionTypeList: Array<{name: string, value: TopoActionType}> = _.filter(_.map(TopoActionType, item => {
    let name: string = null;
    switch (item) {
        case TopoActionType.LINK:
            name = '超链接';
            break;
        case TopoActionType.VISIBLE:
            name = '显示/隐藏';
            break;
        case TopoActionType.BROADCAST:
            name = '广播事件';
            break;
        case TopoActionType.RELOAD_DATA:
            name = '重新加载数据';
            break;
        case TopoActionType.RESET_DATA:
            name = '重置数据';
            break;
        case TopoActionType.STYLE:
            name = '改变样式';
            break;
    }
    return { name, value: item };
}), item => item.name) as any;

const topoActionTypeFilter = (val: TopoActionType) => {
    return _.get(_.find(topoActionTypeList, item => item.value === val), 'name') || '-';
};

export {
    topoActionTypeFilter,
    topoActionTypeList
};
