import moment from 'moment';

const DEFAULT_FORMAT = 'YYYY-MM-DD HH:mm:ss';
const DATE_FORMAT = 'YYYY-MM-DD';

const dateFormat = (val: string | Date, fmt: string = DEFAULT_FORMAT) => {
    if (!val) {
        return '';
    }
    return moment(val).format(fmt);
};

const dateFromNow = (val: string | Date) => {
    if (!val) {
        return '';
    }
    return moment(val).fromNow();
};

const dateDuration = (val: string | Date, endTime?: string) => {
    if (!val) {
        return '';
    }
    return moment.duration(moment(endTime).diff(val)).humanize();
};

export {
    dateFormat, dateFromNow, dateDuration, DATE_FORMAT
};
