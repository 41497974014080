import { BaseEntityModel } from './base-model';
import { QueryPageModel } from './query-model';

export class UserModel extends BaseEntityModel {
    username: string = undefined;
    email: string = undefined;
    authority: string = undefined;
    token: string;
    refreshToken: string;
    remember: boolean;
    phone: string;
    corpId: string = undefined;
    corpName: string = undefined;
    logo: string = undefined;

    constructor(params?: any) {
        super();
        // this.id = _.get(params, 'id');
        // this.username = _.get(params, 'username');
        // this.token = _.get(params, 'token');
        // this.refreshToken = _.get(params, 'refreshToken');
        // this.email = _.get(params, 'email');
        // this.authority = _.get(params, 'authority');
        // this.corpName = _.get(params, 'corpName');
        // this.logo = _.get(params, 'logo');
        // this.phone = _.get(params, 'phone');
        this.toModel(params);
    }

    get IsEmpty() {
        // return !(this.username && this.token);
        return !this.token;
    }

    /**
     * 是否是超级管理员
     */
    get IsSuperAdmin() {
        // return _.get(this.authorities, '[0].authority') === 'SYS_ADMIN';
        return this.authority === 'SYS_ADMIN';
    }

    toModel(json) {
        this.id = _.get(json, 'id');
        this.username = _.get(json, 'name');
        this.token = _.get(json, 'token');
        this.refreshToken = _.get(json, 'refreshToken');
        this.email = _.get(json, 'email');
        this.authority = _.get(json, 'authority');
        this.corpId = _.get(json, 'corpId');
        this.corpName = _.get(json, 'corpName');
        this.logo = _.get(json, 'logo');
        this.phone = _.get(json, 'phone');
        return this;
    }

    getLocalData() {
        return {
            id: this.id,
            username: this.username,
            email: this.email,
            phone: this.phone,
            logo: this.logo,
            corpId: this.corpId,
            corpName: this.corpName,
            authority: this.authority
        };
    }
}

export class UserQueryModel extends QueryPageModel {
    username: string = undefined;
}
