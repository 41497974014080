/**
 * 文本格式化
 */
export default (val: string | number): string | number => {
    if (val !== 0 && (!val || ['null', 'undefined', 'NULL', 'UNDEFINED', 'NaN'].indexOf(val as string) > -1)) {
        return '-';
    }
    return val;
};

const percentNumber = (text: string | number) => {
    if (!text && text !== 0) {
        return '-';
    }
    let textNumber: number = null;
    try {
        textNumber = parseFloat(text + '');
    } catch (e) {
        console.warn(e);
        return '-';
    }
    return (textNumber * 100).toFixed(2);
};

const percentFormat = (text: string | number) => {
    const pNumber = percentNumber(text);
    if (pNumber === '-') {
        return pNumber;
    }
    return `${pNumber}%`;
};

export {
    percentNumber,
    percentFormat
};
