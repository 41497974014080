var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.component.style.Visible,
          expression: "component.style.Visible"
        },
        {
          name: "move",
          rawName: "v-move",
          value: _vm.component.style.move && !_vm.editMode,
          expression: "component.style.move && !editMode"
        },
        {
          name: "zoom",
          rawName: "v-zoom",
          value: _vm.component.style.zoom && !_vm.editMode,
          expression: "component.style.zoom && !editMode"
        }
      ],
      class: "topo-render-component " + _vm.component.style.ClassName,
      style: _vm.component.style.ComponentStyle,
      on: {
        click: function($event) {
          return _vm.topoPageEntityModel.doClickComponent(_vm.component)
        },
        dblclick: function($event) {
          return _vm.topoPageEntityModel.doDbClickComponent(_vm.component)
        }
      }
    },
    [
      _c(
        _vm.component.ComponentName,
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.component.loading,
              expression: "component.loading"
            }
          ],
          tag: "component",
          attrs: { component: _vm.component, "edit-mode": _vm.editMode }
        },
        [
          _vm._l(_vm.component.children, function(child) {
            return [
              _c("TopoRenderComponent", {
                attrs: {
                  component: child,
                  "topo-page-entity-model": _vm.topoPageEntityModel,
                  "edit-mode": _vm.editMode
                }
              })
            ]
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }