import { post } from './request';
import { UserStoreModule } from '@/store/modules/user';

class SignService {
    async login(username, password) {
        const url = `${AUTH_BASE_REQUEST_PATH}/noauth/login`;
        const data = {
            device: 'PC',
            username,
            password
        };
        return await post(url, data);
    }

    async remoteLogin(remoteUrl, username, password) {
        const url = `${remoteUrl}/auth/api/noauth/login`;
        const data = {
            device: 'PC',
            username,
            password
        };
        return await post(url, data);
    }

    getRefreshToken(): Promise<any> {
        return window.fetch(`${AUTH_BASE_REQUEST_PATH}/auth/token`, { method: 'post', body: JSON.stringify({ refreshToken: UserStoreModule.RefreshToken }) });
    }
}

export default new SignService();
