import { Component, Prop, Watch } from 'vue-property-decorator';
import { BaseComponent } from '@/mixins/base-component';
import TopoComponentEntityModel from '@topo/model/topo-component-entity-model';
import { TopoBehaveType, TopoDataType } from '@topo/model/enum';
import { TopoBehaveBroadcastAndSubscribeModel } from '@/pages/topo/model/behave-model/topo-behave-model';
import { TopoStoreModule } from '@/store/modules/topo';

@Component
export default class TopoBaseView extends BaseComponent {
    @Prop({ default: false })
    editMode: boolean;

    @Prop({ default: function() {
        return new TopoComponentEntityModel();
    } })
    component: TopoComponentEntityModel;

    get IsSelected() {
        return this.editMode &&
            TopoStoreModule.TopoEditorModel &&
            TopoStoreModule.TopoEditorModel.selectedComponents &&
            TopoStoreModule.TopoEditorModel.selectedComponents.indexOf(this.component) > -1;
    }

    /**
     * 初始化组件的配置
     */
    async created() {
        if (this.component) {
            // 初始化组件的事件
            _.forEach(this.component.behaves, item => {
                if (item.type === TopoBehaveType.SUBSCRIBE) {
                    // 订阅事件
                    const subscribeBehave = item as TopoBehaveBroadcastAndSubscribeModel;
                    $topoEventHub.$on(subscribeBehave.eventName, param => {
                        subscribeBehave.action.actionParam = param;
                        $topoEventHub.$emit('TOPO_RENDER_EVENT_HUB', subscribeBehave.action);
                    });
                } else if (item.type === TopoBehaveType.BROADCAST) {
                    // 广播事件
                    const broadcastBehave = item as TopoBehaveBroadcastAndSubscribeModel;
                    $topoEventHub.$emit(broadcastBehave.eventName, broadcastBehave);
                }
            });
            // 获取组件的数据
            this.component.getData(this.editMode);
        }
    }

    /**
     * 组件数据变化时，更新依赖父组件的子组件数据
     */
    @Watch('component.data.componentData')
    onComponentDataChange() {
        _.forEach(this.component.children, item => {
            if (item.data.type === TopoDataType.PARENT) {
                item.getData(this.editMode);
            }
        });
    }

    /**
     * 组件的数据
     */
    get ComponentData() {
        return _.get(this.component, 'data.componentData');
    }

    refreshData(val, sceneName) {

    }
    onResize() {

    }

    destroyed() {
        this.component.cancelGetData();
    }
}
