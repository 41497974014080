import { FormControl, FormControlType, FormControlOptionModel, FormControlModel } from '@/model/form-control';
import { QueryPageModel } from '@/model/query-model';
import { ThingsEntityModel } from './things-entity';
import { eventLevelList, eventLevelFilter } from '@/filter/event-level';
import { EventLevelType, ThingsEntityType, ThingsFunctionType } from '@/model/enum';
import { DataParamModel } from './data-param-entity';

export class ThingsEventEntityModel extends ThingsEntityModel {
    constructor(type?: ThingsEntityType, entityId?: string) {
        super(type, entityId);
        this.functionType = ThingsFunctionType.EVENT;
    }
    @FormControl({
        label: '事件类型',
        type: FormControlType.RADIO_GROUP,
        required: true,
        options: eventLevelList,
        index: 3
    } as FormControlOptionModel)
    level: EventLevelType = undefined;

    @FormControl({
        label: '输出参数',
        type: FormControlType.DATA_PARAM,
        index: 4
    } as FormControlModel<Array<DataParamModel>>)
    outputData: Array<DataParamModel> = undefined;

    static getTableColumns() {
        return [
            {
                title: '事件名称',
                dataIndex: 'name',
                scopedSlots: { customRender: 'name' }
            },
            {
                title: '标识符',
                dataIndex: 'identifier'
            },
            {
                title: '事件类型',
                dataIndex: 'level',
                customRender: (text, record, index) => {
                    return eventLevelFilter(text);
                }
            },
            {
                title: '操作',
                dataIndex: 'action',
                scopedSlots: { customRender: 'action' }
            }
        ];
    }

    toModel(json): any {
        super.toModel(json);
        this.outputData = _.map(_.get(json, 'outputData'), item => new DataParamModel().toModel(item));
        return this;
    }

    toService() {
        const data: any = super.toService();
        // data.type = ThingsEntityType.FUNCTION;
        data.type = this.entityType;
        delete data.entityType;
        data.remark = this.remark;
        data.outputData = _.map(this.outputData, item => item.toService());
        return data;
    }
}

export class ThingsEventQueryModel extends QueryPageModel {
    name: string = undefined;

    toService() {
        const data = super.toService();
        data.params = this.name;
        delete data.name;
        return data;
    }
}
