
import { PropertyEntityModel, PropertyQueryModel, ImportExcelEntityModel } from '@/entity-model/property-entity';
import { ICRUDQ } from '@/model/interface';
import { get, post, del, download } from './request';

const URL_PATH = `${BI_BASE_REQUEST_PATH}/property`;

class PropertyService implements ICRUDQ<PropertyEntityModel, PropertyQueryModel> {
    async create(model: PropertyEntityModel):Promise<PropertyEntityModel> {
        const url = `${URL_PATH}/${model.type}/save`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async retrieve(modelId: string):Promise<PropertyEntityModel> {
        throw new Error('Not implement');
    }

    async update(model: PropertyEntityModel):Promise<PropertyEntityModel> {
        const url = `${URL_PATH}/${model.type}/save`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async delete(model: PropertyEntityModel):Promise<PropertyEntityModel> {
        const url = `${URL_PATH}/${model.type}/${model.id}`;
        const res = await del(url);
        return res;
    }

    async query(query?: PropertyQueryModel, page?: number, limit?: number):Promise<any> {
        const params: any = Object.assign({ page, limit }, query?.toService());

        if (!params.type) {
            throw new Error('Has no entityType');
        }

        const url = `${URL_PATH}/${params.type}/list`;
        const res = await get(url);
        return _.map(res, item => item = new PropertyEntityModel(params.type).toModel(item));
    }

    async importExcel(model: ImportExcelEntityModel) {
        const url = `${URL_PATH}/importExcel`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async exportExcel() {
        const url = `${URL_PATH}/export`;
        const res = await download(url, null, 'post');
        // const res = await post(url);
        return res;
    }
}

export default new PropertyService();
