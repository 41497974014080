import Vue from 'vue';
import 'reflect-metadata';
import Component from 'vue-class-component';
import VueDraggableResizable from 'vue-draggable-resizable';
import VueDraggable from 'vuedraggable';
import Antd from './antd';
import 'normalize.css';
import 'nprogress/nprogress.css';
import '@/styles/index.scss';
import 'lodash';
// 引入全部lodash
// import * as _ from '@/lodash';// 按需引入lodash
import '@/window-init';
import '@/sentry';
import * as filters from '@/filter';
import * as directives from '@/directive';
import * as jtlComponents from '@/components';
import { request } from '@/service/request';

import JtlChart from '@/components/jtl-chart/index';

import JsonViewer from 'vue-json-viewer';
import TopoRender from '@/pages/topo/components/topo/topo-render.vue';

Vue.component('TopoRender', TopoRender);
Vue.use(JsonViewer);

Vue.config.productionTip = false;
Vue.use(Antd);
Vue.use(JtlChart);

window['$eventHub'] = Vue.prototype.$eventHub = new Vue();
window['$topoEventHub'] = new Vue();
window['_'] = Vue.prototype._ = _;
window['$http'] = Vue.prototype.$http = request;

// filters
_.forEach(filters, (filter, key) => {
    Vue.filter(key, filter);
});

// directive
_.forEach(directives, (filter, key) => {
    Vue.directive(key, filter);
});

// components
Vue.component('vue-draggable-resizable', VueDraggableResizable);
Vue.component('draggable', VueDraggable);
_.forEach(jtlComponents, (component, key) => {
    Vue.component(_.kebabCase(key), component);
});
Vue.component('v-nodes', {
    functional: true,
    render: (h, ctx) => ctx.props['vnodes']
});

// https://www.npmjs.com/package/vue-class-component#adding-custom-hooks
Component.registerHooks([
    'beforeRouteEnter',
    'beforeRouteLeave'
    // 'beforeRouteUpdate' // hash模式下才可用
]);
