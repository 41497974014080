import { TopoDataType } from '../enum';
import TopoDataBaseModel from './topo-data-base-model';

export default class TopoDataStaticModel extends TopoDataBaseModel {
    constructor(options?: any) {
        super();
        this.type = TopoDataType.STATIC;
        this.componentData = _.get(options, 'componentData');
    }

    getData() {
        return this.componentData;
    }
    cancelGetData() {}
}
