let sequence = 1;
export function generateUUID() {
    return Date.now() + '-' + (Math.random() * 10000).toFixed(0) + '-' + sequence++;
}

/**
 * 获取rowSpans的方法
 * @param keys 需要判断是否为相同行的列id
 * @param tableData 表格数据
 */
export function getRowSpans(keys: string[], tableData: any[]): number[] {
    let sameValueStart = 0;
    const rowSpans: number[] = [];
    tableData.forEach((item, index) => {
        if (index === 0) {
            rowSpans.push(1);
        } else {
            const isSame = keys.every(key => item[key] === tableData[index - 1][key] && ![null, undefined, ''].includes(item[key]));
            if (isSame) {
                rowSpans[sameValueStart] += 1;
                rowSpans.push(0);
            } else {
                rowSpans.push(1);
                sameValueStart = index;
            }
        }
    });

    return rowSpans;
}

/**
 * 复制到粘贴板
 * @param copyStr 要复制的字符内容
 */
export function copyToPasteboard(copyStr: string): boolean {
    const input = document.createElement('input');
    document.body.appendChild(input);
    input.setAttribute('value', copyStr);
    input.select();

    if (document.execCommand) {
        document.execCommand('copy');
        document.body.removeChild(input);
        return true;
    }

    return false;
}
