var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "jtl-form-component" },
    [
      _vm.formModel
        ? _c(
            "a-form-model",
            {
              ref: _vm.JTL.CONSTANT.DEFAULT_FORM_NAME,
              attrs: { model: _vm.formModel, rules: _vm.formRules }
            },
            [
              _vm._l(_vm.formControlList, function(item, index) {
                return [
                  !item.invisible
                    ? _c(
                        "a-form-model-item",
                        {
                          key: index,
                          class: item.className,
                          style: { width: item.layout.width },
                          attrs: {
                            label: item.label,
                            "label-col": { span: item.layout.labelCol },
                            "wrapper-col": { span: item.layout.wrapperCol },
                            prop: item.key,
                            "has-feedback":
                              _vm.hasFeedback &&
                              item.type === _vm.FormControlType.TEXT
                          }
                        },
                        [
                          item.type === _vm.FormControlType.AUTO_COMPLETE
                            ? _c("a-auto-complete", {
                                attrs: {
                                  "data-source": item.options,
                                  placeholder: item.placeholder,
                                  name: item.key,
                                  disabled: _vm.getDisabled(item)
                                },
                                model: {
                                  value: _vm.formModel[item.key],
                                  callback: function($$v) {
                                    _vm.$set(_vm.formModel, item.key, $$v)
                                  },
                                  expression: "formModel[item.key]"
                                }
                              })
                            : item.type ===
                              _vm.FormControlType.AUTO_COMPLETE_THINGS_FUNCTION
                            ? _c("things-function-auto-complete-component", {
                                attrs: {
                                  "form-model": _vm.formModel,
                                  placeholder: item.placeholder,
                                  name: item.key,
                                  "view-mode": _vm.viewMode,
                                  disabled: _vm.getDisabled(item)
                                },
                                model: {
                                  value: _vm.formModel[item.key],
                                  callback: function($$v) {
                                    _vm.$set(_vm.formModel, item.key, $$v)
                                  },
                                  expression: "formModel[item.key]"
                                }
                              })
                            : item.type === _vm.FormControlType.TEXT &&
                              item.inputType !== "password"
                            ? _c("a-input", {
                                attrs: {
                                  placeholder: item.placeholder,
                                  type: item.inputType,
                                  step: item.step,
                                  name: item.key,
                                  prefix: item.prefix,
                                  suffix: item.suffix,
                                  "allow-clear": item.inputType !== "color",
                                  disabled: _vm.getDisabled(item)
                                },
                                model: {
                                  value: _vm.formModel[item.key],
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.formModel,
                                      item.key,
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "formModel[item.key]"
                                }
                              })
                            : item.type === _vm.FormControlType.TEXT &&
                              item.inputType === "password"
                            ? _c("a-input-password", {
                                attrs: {
                                  placeholder: item.placeholder,
                                  name: item.key,
                                  disabled: _vm.getDisabled(item)
                                },
                                model: {
                                  value: _vm.formModel[item.key],
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.formModel,
                                      item.key,
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "formModel[item.key]"
                                }
                              })
                            : item.type === _vm.FormControlType.NUMBER
                            ? _c("a-input-number", {
                                staticClass: "width100",
                                attrs: {
                                  placeholder: item.placeholder,
                                  step: item.step,
                                  min: item.min,
                                  max: item.max,
                                  name: item.key,
                                  disabled: _vm.getDisabled(item)
                                },
                                model: {
                                  value: _vm.formModel[item.key],
                                  callback: function($$v) {
                                    _vm.$set(_vm.formModel, item.key, $$v)
                                  },
                                  expression: "formModel[item.key]"
                                }
                              })
                            : item.type === _vm.FormControlType.TEXT_AREA
                            ? _c("a-textarea", {
                                attrs: {
                                  placeholder: item.placeholder,
                                  type: item.type,
                                  name: item.key,
                                  disabled: _vm.getDisabled(item),
                                  rows: item.rows ? item.rows : 3
                                },
                                model: {
                                  value: _vm.formModel[item.key],
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.formModel,
                                      item.key,
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "formModel[item.key]"
                                }
                              })
                            : item.type === _vm.FormControlType.TEXT_RICH
                            ? _c("jtl-editor-component", {
                                attrs: { disabled: _vm.getDisabled(item) },
                                model: {
                                  value: _vm.formModel[item.key],
                                  callback: function($$v) {
                                    _vm.$set(_vm.formModel, item.key, $$v)
                                  },
                                  expression: "formModel[item.key]"
                                }
                              })
                            : item.type === _vm.FormControlType.SELECT
                            ? _c(
                                "a-select",
                                {
                                  attrs: {
                                    placeholder: item.placeholder,
                                    mode: item.mode,
                                    "allow-clear": true,
                                    disabled: _vm.getDisabled(item),
                                    "filter-option": false,
                                    "show-search": ""
                                  },
                                  on: {
                                    change: function($event) {
                                      return _vm.onSelectChange(item)
                                    },
                                    search: function($event) {
                                      return item.onSearch($event, item)
                                    }
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "dropdownRender",
                                        fn: function(menu) {
                                          return _c(
                                            "div",
                                            {},
                                            [
                                              _c("v-nodes", {
                                                attrs: { vnodes: menu }
                                              }),
                                              _c(
                                                "span",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        item.showLoadMoreButton,
                                                      expression:
                                                        "item.showLoadMoreButton"
                                                    }
                                                  ]
                                                },
                                                [
                                                  _c("a-divider", {
                                                    staticStyle: {
                                                      margin: "4px 0"
                                                    }
                                                  }),
                                                  _c(
                                                    "a",
                                                    {
                                                      staticClass:
                                                        "jtl-link select-load-more",
                                                      on: {
                                                        mousedown: function(e) {
                                                          return e.preventDefault()
                                                        },
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return item.loadMore(
                                                            item
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [_vm._v("加载更多")]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  ),
                                  model: {
                                    value: _vm.formModel[item.key],
                                    callback: function($$v) {
                                      _vm.$set(_vm.formModel, item.key, $$v)
                                    },
                                    expression: "formModel[item.key]"
                                  }
                                },
                                _vm._l(item.FilterOptions, function(option) {
                                  return _c(
                                    "a-select-option",
                                    {
                                      key: option.value,
                                      attrs: {
                                        value: option.value,
                                        disabled: option.disabled
                                      }
                                    },
                                    [
                                      option.disabled
                                        ? _c(
                                            "a-popover",
                                            {
                                              attrs: {
                                                content: option.disabledMessage
                                              }
                                            },
                                            [_vm._v(_vm._s(option.name))]
                                          )
                                        : _c(
                                            "jtl-tooltip-component",
                                            { attrs: { title: option.name } },
                                            [_vm._v(_vm._s(option.name))]
                                          )
                                    ],
                                    1
                                  )
                                }),
                                1
                              )
                            : item.type ===
                              _vm.FormControlType.TOPO_TARGET_SELECT
                            ? _c("topo-target-select", {
                                attrs: {
                                  placeholder: item.placeholder,
                                  mode: item.mode,
                                  disabled: _vm.getDisabled(item)
                                },
                                model: {
                                  value: _vm.formModel[item.key],
                                  callback: function($$v) {
                                    _vm.$set(_vm.formModel, item.key, $$v)
                                  },
                                  expression: "formModel[item.key]"
                                }
                              })
                            : item.type === _vm.FormControlType.TREE_SELECT
                            ? _c("jtl-tree-select-component", {
                                staticClass: "width100",
                                attrs: {
                                  "tree-data": item.options,
                                  placeholder: item.placeholder,
                                  disabled: _vm.getDisabled(item),
                                  multiple: item.mode === "multiple"
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.onSelectChange(item)
                                  }
                                },
                                model: {
                                  value: _vm.formModel[item.key],
                                  callback: function($$v) {
                                    _vm.$set(_vm.formModel, item.key, $$v)
                                  },
                                  expression: "formModel[item.key]"
                                }
                              })
                            : item.type === _vm.FormControlType.UPLOAD
                            ? _c("jtl-upload-component", {
                                staticClass: "inline-block",
                                attrs: {
                                  name: item.key,
                                  "list-type": item.listType,
                                  accept: item.accept,
                                  "show-upload-list": item.showUploadList,
                                  disabled: _vm.getDisabled(item),
                                  "max-file-size": item.maxFileSize,
                                  "max-file-number": item.maxFileNumber,
                                  "not-upload-oss": item.notUploadOss
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.validateField(item.key)
                                  }
                                },
                                model: {
                                  value: _vm.formModel[item.key],
                                  callback: function($$v) {
                                    _vm.$set(_vm.formModel, item.key, $$v)
                                  },
                                  expression: "formModel[item.key]"
                                }
                              })
                            : item.type === _vm.FormControlType.SELECT_DATERANGE
                            ? _c("a-range-picker", {
                                staticClass: "width100",
                                attrs: {
                                  "disabled-date":
                                    item.disabledDate ||
                                    (item.readonly &&
                                      _vm.viewMode ===
                                        _vm.ViewModeType.UPDATE) ||
                                    _vm.IsView,
                                  ranges: item.ranges,
                                  "show-time": "",
                                  disabled: _vm.getDisabled(item)
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.validateField(item.key)
                                  }
                                },
                                model: {
                                  value: _vm.formModel[item.key],
                                  callback: function($$v) {
                                    _vm.$set(_vm.formModel, item.key, $$v)
                                  },
                                  expression: "formModel[item.key]"
                                }
                              })
                            : item.type === _vm.FormControlType.SELECT_DATE
                            ? _c("a-date-picker", {
                                staticClass: "width100",
                                attrs: {
                                  "disabled-date":
                                    item.disabledDate ||
                                    (item.readonly &&
                                      _vm.viewMode ===
                                        _vm.ViewModeType.UPDATE) ||
                                    _vm.IsView,
                                  placeholder: item.placeholder,
                                  format: item.format,
                                  "show-time": "",
                                  disabled: _vm.getDisabled(item)
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.validateField(item.key)
                                  }
                                },
                                model: {
                                  value: _vm.formModel[item.key],
                                  callback: function($$v) {
                                    _vm.$set(_vm.formModel, item.key, $$v)
                                  },
                                  expression: "formModel[item.key]"
                                }
                              })
                            : item.type === _vm.FormControlType.TIME_PICKER
                            ? _c("a-time-picker", {
                                staticClass: "width100",
                                attrs: {
                                  placeholder: item.placeholder,
                                  format: item.format,
                                  disabled: _vm.getDisabled(item)
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.validateField(item.key)
                                  }
                                },
                                model: {
                                  value: _vm.formModel[item.key],
                                  callback: function($$v) {
                                    _vm.$set(_vm.formModel, item.key, $$v)
                                  },
                                  expression: "formModel[item.key]"
                                }
                              })
                            : item.type === _vm.FormControlType.TIME_PICKERS
                            ? _c("time-pickers-component", {
                                ref:
                                  _vm.JTL.CONSTANT.DEFAULT_CUSTOMER_FORM_NAME,
                                refInFor: true,
                                staticClass: "width100",
                                attrs: {
                                  placeholder: item.placeholder,
                                  disabled: _vm.getDisabled(item)
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.validateField(item.key)
                                  }
                                },
                                model: {
                                  value: _vm.formModel[item.key],
                                  callback: function($$v) {
                                    _vm.$set(_vm.formModel, item.key, $$v)
                                  },
                                  expression: "formModel[item.key]"
                                }
                              })
                            : item.type === _vm.FormControlType.SWITCH
                            ? _c("a-switch", {
                                attrs: {
                                  "checked-children": item.checkText,
                                  "un-checked-children": item.uncheckText,
                                  disabled: _vm.getDisabled(item)
                                },
                                model: {
                                  value: _vm.formModel[item.key],
                                  callback: function($$v) {
                                    _vm.$set(_vm.formModel, item.key, $$v)
                                  },
                                  expression: "formModel[item.key]"
                                }
                              })
                            : item.type === _vm.FormControlType.RADIO_GROUP
                            ? _c(
                                "a-radio-group",
                                {
                                  attrs: {
                                    name: item.key,
                                    disabled: _vm.getDisabled(item)
                                  },
                                  on: {
                                    change: function($event) {
                                      return _vm.onSelectChange(item)
                                    }
                                  },
                                  model: {
                                    value: _vm.formModel[item.key],
                                    callback: function($$v) {
                                      _vm.$set(_vm.formModel, item.key, $$v)
                                    },
                                    expression: "formModel[item.key]"
                                  }
                                },
                                _vm._l(item.options, function(option) {
                                  return _c(
                                    "a-radio",
                                    {
                                      key: option.value,
                                      attrs: { value: option.value }
                                    },
                                    [_vm._v(" " + _vm._s(option.name) + " ")]
                                  )
                                }),
                                1
                              )
                            : item.type === _vm.FormControlType.CHECKBOX
                            ? _c("a-checkbox", {
                                attrs: { disabled: _vm.getDisabled(item) },
                                on: {
                                  change: function($event) {
                                    return _vm.onSelectChange(item)
                                  }
                                },
                                model: {
                                  value: _vm.formModel[item.key],
                                  callback: function($$v) {
                                    _vm.$set(_vm.formModel, item.key, $$v)
                                  },
                                  expression: "formModel[item.key]"
                                }
                              })
                            : item.type === _vm.FormControlType.CHECKBOX_GROUP
                            ? _c(
                                "a-checkbox-group",
                                {
                                  attrs: {
                                    name: item.key,
                                    disabled: _vm.getDisabled(item)
                                  },
                                  model: {
                                    value: _vm.formModel[item.key],
                                    callback: function($$v) {
                                      _vm.$set(_vm.formModel, item.key, $$v)
                                    },
                                    expression: "formModel[item.key]"
                                  }
                                },
                                _vm._l(item.options, function(option) {
                                  return _c(
                                    "a-checkbox",
                                    {
                                      key: option.value,
                                      attrs: { value: option.value }
                                    },
                                    [_vm._v(" " + _vm._s(option.name) + " ")]
                                  )
                                }),
                                1
                              )
                            : item.type === _vm.FormControlType.CASCADER_CITYS
                            ? _c("a-cascader", {
                                attrs: {
                                  name: item.key,
                                  options: item.options,
                                  placeholder: item.placeholder
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.onSelectChange(item)
                                  }
                                },
                                model: {
                                  value: _vm.formModel[item.key],
                                  callback: function($$v) {
                                    _vm.$set(_vm.formModel, item.key, $$v)
                                  },
                                  expression: "formModel[item.key]"
                                }
                              })
                            : item.type ===
                              _vm.FormControlType.ATTRIBUTE_DATA_TYPE_VALUE
                            ? _c("attribute-data-type-value-component", {
                                attrs: { "form-name": item.key },
                                on: {
                                  change: function($event) {
                                    return _vm.validateField(item.key)
                                  }
                                },
                                model: {
                                  value: _vm.formModel[item.key],
                                  callback: function($$v) {
                                    _vm.$set(_vm.formModel, item.key, $$v)
                                  },
                                  expression: "formModel[item.key]"
                                }
                              })
                            : item.type ===
                              _vm.FormControlType.THINGS_MODEL_TYPE
                            ? _c("things-model-type-component", {
                                ref:
                                  _vm.JTL.CONSTANT.DEFAULT_CUSTOMER_FORM_NAME,
                                refInFor: true,
                                attrs: {
                                  "form-name": item.key,
                                  "things-type": item.thingsType,
                                  disabled: _vm.getDisabled(item)
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.validateField(item.key)
                                  }
                                },
                                model: {
                                  value: _vm.formModel[item.key],
                                  callback: function($$v) {
                                    _vm.$set(_vm.formModel, item.key, $$v)
                                  },
                                  expression: "formModel[item.key]"
                                }
                              })
                            : item.type === _vm.FormControlType.DATA_PARAM
                            ? _c(
                                "div",
                                [
                                  _c("data-param-component", {
                                    attrs: {
                                      "form-name": item.key,
                                      disabled: _vm.getDisabled(item)
                                    },
                                    on: {
                                      delete: function($event) {
                                        return _vm.deleteDataParam(
                                          _vm.formModel[item.key],
                                          $event
                                        )
                                      },
                                      edit: function($event) {
                                        return _vm.editDataParam(
                                          item.key,
                                          $event
                                        )
                                      }
                                    },
                                    model: {
                                      value: _vm.formModel[item.key],
                                      callback: function($$v) {
                                        _vm.$set(_vm.formModel, item.key, $$v)
                                      },
                                      expression: "formModel[item.key]"
                                    }
                                  }),
                                  !(
                                    (item.readonly &&
                                      _vm.viewMode ===
                                        _vm.ViewModeType.UPDATE) ||
                                    _vm.IsView
                                  )
                                    ? _c(
                                        "a",
                                        {
                                          staticClass: "jtl-link",
                                          on: {
                                            click: function($event) {
                                              return _vm.addDataParam(item.key)
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(" +增加参数 "),
                                          _c("data-param-edit-dialog", {
                                            ref:
                                              "dataParamEditDialog_" + item.key,
                                            refInFor: true,
                                            on: {
                                              dialogOK: function($event) {
                                                return _vm.dataParamFormDialogOK(
                                                  item.key,
                                                  $event
                                                )
                                              }
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            : item.type === _vm.FormControlType.SELECT_DEVICE
                            ? _c("jtl-select-device-component", {
                                staticClass: "width100",
                                attrs: {
                                  "form-name": item.key,
                                  disabled: _vm.getDisabled(item)
                                },
                                model: {
                                  value: _vm.formModel[item.key],
                                  callback: function($$v) {
                                    _vm.$set(_vm.formModel, item.key, $$v)
                                  },
                                  expression: "formModel[item.key]"
                                }
                              })
                            : item.type ===
                              _vm.FormControlType.SELECT_DEVICE_ATTRIBUTE
                            ? _c("jtl-select-device-attribute-component", {
                                ref:
                                  _vm.JTL.CONSTANT.DEFAULT_CUSTOMER_FORM_NAME,
                                refInFor: true,
                                staticClass: "width100",
                                attrs: {
                                  "form-name": item.key,
                                  mode: item.mode,
                                  multiple: item.multiple,
                                  "select-type": item.selectType,
                                  "scope-id":
                                    _vm.formModel[item.scopeId] || item.scopeId,
                                  disabled: _vm.getDisabled(item)
                                },
                                model: {
                                  value: _vm.formModel[item.key],
                                  callback: function($$v) {
                                    _vm.$set(_vm.formModel, item.key, $$v)
                                  },
                                  expression: "formModel[item.key]"
                                }
                              })
                            : item.type === _vm.FormControlType.SELECT_SPACE
                            ? _c("jtl-select-space-component", {
                                staticClass: "width100",
                                attrs: {
                                  "form-name": item.key,
                                  disabled: _vm.getDisabled(item)
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.validateField(item.key)
                                  }
                                },
                                model: {
                                  value: _vm.formModel[item.key],
                                  callback: function($$v) {
                                    _vm.$set(_vm.formModel, item.key, $$v)
                                  },
                                  expression: "formModel[item.key]"
                                }
                              })
                            : item.type === _vm.FormControlType.SELECT_GROUP
                            ? _c("jtl-select-group-component", {
                                staticClass: "width100",
                                attrs: {
                                  "group-type": item.groupType,
                                  "form-name": item.key,
                                  disabled: _vm.getDisabled(item)
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.validateField(item.key)
                                  }
                                },
                                model: {
                                  value: _vm.formModel[item.key],
                                  callback: function($$v) {
                                    _vm.$set(_vm.formModel, item.key, $$v)
                                  },
                                  expression: "formModel[item.key]"
                                }
                              })
                            : item.type === _vm.FormControlType.TABLE
                            ? _c("jtl-form-table", {
                                ref:
                                  _vm.JTL.CONSTANT.DEFAULT_CUSTOMER_FORM_NAME,
                                refInFor: true,
                                attrs: {
                                  columns: item.columns,
                                  disabled: _vm.getDisabled(item),
                                  "delete-action": item.deleteAction,
                                  "add-action": item.addAction,
                                  "cell-type": item.cellType
                                },
                                on: {
                                  formTableAdd: function($event) {
                                    return _vm.formTableAdd(item)
                                  },
                                  formTableChange: function($event) {
                                    return _vm.formTableChange($event, item)
                                  }
                                },
                                model: {
                                  value: _vm.formModel[item.key],
                                  callback: function($$v) {
                                    _vm.$set(_vm.formModel, item.key, $$v)
                                  },
                                  expression: "formModel[item.key]"
                                }
                              })
                            : item.type ===
                              _vm.FormControlType.MONTH_SEASON_RULE
                            ? _c("month-season-rule-component", {
                                ref:
                                  _vm.JTL.CONSTANT.DEFAULT_CUSTOMER_FORM_NAME,
                                refInFor: true,
                                staticClass: "width100",
                                attrs: {
                                  "rule-type": item.ruleType,
                                  "form-name": item.key
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.validateField(item.key)
                                  }
                                },
                                model: {
                                  value: _vm.formModel[item.key],
                                  callback: function($$v) {
                                    _vm.$set(_vm.formModel, item.key, $$v)
                                  },
                                  expression: "formModel[item.key]"
                                }
                              })
                            : item.type === _vm.FormControlType.RATE
                            ? _c("a-rate", {
                                model: {
                                  value: _vm.formModel[item.key],
                                  callback: function($$v) {
                                    _vm.$set(_vm.formModel, item.key, $$v)
                                  },
                                  expression: "formModel[item.key]"
                                }
                              })
                            : item.type === _vm.FormControlType.INPUT_GROUP
                            ? _c("jtl-input-group", {
                                ref:
                                  _vm.JTL.CONSTANT.DEFAULT_CUSTOMER_FORM_NAME,
                                refInFor: true,
                                attrs: {
                                  disabled: _vm.getDisabled(item),
                                  "add-desc": item.addDesc,
                                  "name-desc": item.nameDesc,
                                  "value-desc": item.valueDesc
                                },
                                model: {
                                  value: _vm.formModel[item.key],
                                  callback: function($$v) {
                                    _vm.$set(_vm.formModel, item.key, $$v)
                                  },
                                  expression: "formModel[item.key]"
                                }
                              })
                            : item.type === _vm.FormControlType.TREE
                            ? _c("jtl-tree-component", {
                                staticClass: "width100",
                                attrs: {
                                  loading: item.loading,
                                  "tree-data": item.options,
                                  multiple: item.mode === "multiple",
                                  "form-name": item.key,
                                  disabled: _vm.getDisabled(item)
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.validateField(item.key)
                                  }
                                },
                                model: {
                                  value: _vm.formModel[item.key],
                                  callback: function($$v) {
                                    _vm.$set(_vm.formModel, item.key, $$v)
                                  },
                                  expression: "formModel[item.key]"
                                }
                              })
                            : item.type === _vm.FormControlType.SELECT_USER
                            ? _c("select-user-component", {
                                ref:
                                  _vm.JTL.CONSTANT.DEFAULT_CUSTOMER_FORM_NAME,
                                refInFor: true,
                                model: {
                                  value: _vm.formModel[item.key],
                                  callback: function($$v) {
                                    _vm.$set(_vm.formModel, item.key, $$v)
                                  },
                                  expression: "formModel[item.key]"
                                }
                              })
                            : item.type === _vm.FormControlType.LINKAGE_DEVICE
                            ? _c("linkage-device-component", {
                                ref:
                                  _vm.JTL.CONSTANT.DEFAULT_CUSTOMER_FORM_NAME,
                                refInFor: true,
                                attrs: {
                                  "linkage-type": item.linkageType,
                                  "linkage-id": _vm.formModel.id,
                                  "group-id": _vm.formModel.groupId,
                                  disabled: _vm.getDisabled(item)
                                },
                                model: {
                                  value: _vm.formModel[item.key],
                                  callback: function($$v) {
                                    _vm.$set(_vm.formModel, item.key, $$v)
                                  },
                                  expression: "formModel[item.key]"
                                }
                              })
                            : item.type === _vm.FormControlType.RESOURCE_URL
                            ? _c("resource-url-component", {
                                ref:
                                  _vm.JTL.CONSTANT.DEFAULT_CUSTOMER_FORM_NAME,
                                refInFor: true,
                                model: {
                                  value: _vm.formModel[item.key],
                                  callback: function($$v) {
                                    _vm.$set(_vm.formModel, item.key, $$v)
                                  },
                                  expression: "formModel[item.key]"
                                }
                              })
                            : item.type === _vm.FormControlType.FORM_ENUM
                            ? _c("form-enum-component", {
                                ref:
                                  _vm.JTL.CONSTANT.DEFAULT_CUSTOMER_FORM_NAME,
                                refInFor: true,
                                model: {
                                  value: _vm.formModel[item.key],
                                  callback: function($$v) {
                                    _vm.$set(_vm.formModel, item.key, $$v)
                                  },
                                  expression: "formModel[item.key]"
                                }
                              })
                            : _vm._e(),
                          item.message
                            ? _c(
                                "span",
                                [
                                  item.noMessageTooltip
                                    ? _c(
                                        "span",
                                        { staticClass: "form-item-message" },
                                        [_vm._v(_vm._s(item.message))]
                                      )
                                    : _c(
                                        "a-tooltip",
                                        {
                                          attrs: {
                                            placement: "right",
                                            title: item.message
                                          }
                                        },
                                        [
                                          _c("a-icon", {
                                            staticClass: "hit-icon",
                                            attrs: { type: "question-circle" }
                                          })
                                        ],
                                        1
                                      )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e()
                ]
              })
            ],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }