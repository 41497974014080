import { UserStoreModule } from '@/store/modules/user';

export function beforeEnter(to, from, next) {
    if (UserStoreModule.HasError || to.fullPath === '/sign/login') {
        next();
        return;
    }
    if (UserStoreModule.IsLogin) {
        next(to.query.redirectPath || UserStoreModule.RedirectPath);
    } else {
        if (to.query.redirectPath) {
            UserStoreModule.SET_REDIRECT_PATH(to.query.redirectPath);
        }
        next('/sign/login');
    }
}

export default [
    {
        path: '/sign*',
        redirect: '/sign/login',
        beforeEnter: beforeEnter,
        component: () => import('@/pages/sign/sign.vue'),
        children: [
            {
                path: 'login',
                component: () => import('@/pages/sign/login.vue')
            },
            {
                path: 'deny',
                component: () => import('@/pages/sign/permission-deny.vue')
            },
            {
                path: 'error',
                component: () => import('@/pages/sign/error.vue')
            }
        ]
    }
];
