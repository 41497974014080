import { BaseModel } from './base-model';
import { SubSystemType } from './enum';

export class QueryModel extends BaseModel {
    toService(): any {
        const data: any = super.toService();
        _.forOwn(data, (value, key) => {
            if (!value) {
                delete data[key];
            }
        });
        // data.limit = this.size;
        // delete data.size;
        return data;
    }

    getParams() {
        const params: any = super.toService();
        _.forOwn(params, (value, key) => {
            if (!value) {
                delete params[key];
            }
        });
        return { params };
    }
}

export class QueryPageModel extends QueryModel {
    // public size: number = JTL.CONSTANT.PAGE_SIZE;
    // public page: number = 1;

    // get Skip(): number {
    //     return (this.page - 1) * this.size;
    // }

}

export class SubSystemQueryModel extends QueryPageModel {
    constructor(subSystemType?: SubSystemType) {
        super();
        this.subSystemType = subSystemType;
    }
    subSystemType: SubSystemType;
}

export class GroupQueryModel extends QueryPageModel {
    groupIds: Array<string> = undefined;

    get GroupIds() {
        if (this.groupIds) {
            return _.map(_.filter(this.groupIds, item => item !== ' '), item => _.trim(item));
        }
        return undefined;
    }
}
