











import { Component, Vue } from 'vue-property-decorator';
import zhCN from '@jtl/ant-design-vue/es/locale/zh_CN';
import { BaseComponent } from './mixins/base-component';

@Component
export default class App extends BaseComponent {
    /**
     * 全局语言配置
     */
    zhCN = zhCN;

    /**
     * 是否全屏loading
     */
    fullScreenLoading: boolean = false;

    /**
     * 全屏加载提示文字
     */
    fullScreenLoadingText: string = null;

    created() {
        // 订阅打开全屏全屏正在加载事件
        this.$eventHub.$on('START_FULL_SCREEN_LOADING', (loadingText: string) => {
            this.fullScreenLoading = true;
            this.fullScreenLoadingText = loadingText;
        });
        // 订阅关闭全屏全屏正在加载事件
        this.$eventHub.$on('STOP_FULL_SCREEN_LOADING', () => {
            this.fullScreenLoading = false;
            this.fullScreenLoadingText = null;
        });
    }
    mounted() {
        // const loadingWrapper = document.getElementById('page_loading_wrapper');
        // if (loadingWrapper) {
        //     loadingWrapper.remove();
        // }
        this.stopFullScreenLoading();
    }
}
