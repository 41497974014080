import { VuexModule, Module, Action, Mutation, getModule } from 'vuex-module-decorators';
import store from '@/store';
import { UserModel } from '@/model/user-model';
import { initSentryUserInfo } from '@/sentry';
import { ProjectEntityModel } from '@/entity-model/project-entity';
import { PermissionType } from '@/model/enum';

/**
 * 获取环境变量里配置的值
 * 如果是相对地址，则加上/
 * @param logoName 名称
 * @param defaultValue 默认值
 * @returns
 */
function getEnvValue(name: string, defaultValue?: string) {
    const envLogo = process.env[name];
    if (!envLogo) {
        return defaultValue;
    }
    if (envLogo.indexOf('http://') > -1) {
        return envLogo;
    }
    return `/${envLogo}`;
}

// localStorage常量key
const JTL_USERINFO_LOCAL = 'jtl_userinfo';
const JTL_REDIRECT_PATH_LOCAL = 'jtl_redirect_path';
const JTL_TOKEN_LOCAL = 'jtl_token';
const JTL_REFRESH_TOKEN_LOCAL = 'jtl_refresh_token';
const JTL_CURRENT_PROJECT = 'jtl_current_project';
const EVERYONE_MENUS = ['/dashboard/index', '/dashboard/person-center', '/dashboard/project-select'];

/**
 * 从localStorage获取jtl_userinfo
 */
function getUserInfoFromLocal() {
    let userInfoFromLocal: UserModel;

    try {
        const localData = JSON.parse(localStorage.getItem(JTL_USERINFO_LOCAL));
        localData.token = localStorage.getItem(JTL_TOKEN_LOCAL);
        localData.refreshToken = localStorage.getItem(JTL_REFRESH_TOKEN_LOCAL);
        userInfoFromLocal = new UserModel(localData);
    } catch (e) {
        const token = localStorage.getItem(JTL_TOKEN_LOCAL);
        userInfoFromLocal = new UserModel();
        userInfoFromLocal.token = token;
        $log.warn('缓存用户数据为空或格式不正确', localStorage.getItem(JTL_USERINFO_LOCAL));
    }

    initSentryUserInfo(userInfoFromLocal);
    return userInfoFromLocal;
}

function getProjectFromLocal() {
    let localData: any;
    try {
        localData = JSON.parse(localStorage.getItem(JTL_CURRENT_PROJECT));
    } catch (e) {
        // log?
    }
    return localData;
}

@Module({ dynamic: true, store, name: 'user' })
class User extends VuexModule {
    private userInfo: UserModel = null;
    private userExtraInfo: UserModel = null;
    private userPermissions: Array<any> = null;
    private hasError: boolean = false;
    private currentProject: ProjectEntityModel = null;

    constructor() {
        super({});
        this.userInfo = getUserInfoFromLocal();
        const projectLocal = getProjectFromLocal();
        if (projectLocal) {
            this.currentProject = new ProjectEntityModel().toModel(projectLocal);
        }
    }

    @Mutation
    public SET_USERINFO(userinfo?: UserModel): void {
        this.userInfo = userinfo;

        if (userinfo && !userinfo.IsEmpty) {
            localStorage.setItem(JTL_TOKEN_LOCAL, userinfo.token);
            localStorage.setItem(JTL_REFRESH_TOKEN_LOCAL, userinfo.refreshToken);
        } else {
            localStorage.removeItem(JTL_TOKEN_LOCAL);
            localStorage.removeItem(JTL_REFRESH_TOKEN_LOCAL);
        }
    }

    @Mutation
    public SET_USER_EXTRA_INFO(userInfo: any): void {
        if (userInfo) {
            this.userExtraInfo = new UserModel(userInfo);
            localStorage.setItem(JTL_USERINFO_LOCAL, JSON.stringify(this.userExtraInfo.getLocalData()));
            initSentryUserInfo(this.userExtraInfo);
            if (this.userExtraInfo.IsSuperAdmin) {
                this.currentProject = null;
            }
        } else {
            localStorage.removeItem(JTL_USERINFO_LOCAL);
            initSentryUserInfo();
        }

        // init the favicon
        const link: any = document.querySelector("link[rel*='icon']") || document.createElement('link');
        link.type = 'image/x-icon';
        link.rel = 'shortcut icon';
        // link.href = this.Logo;
        link.href = _.get(this.currentProject, 'logo') || _.get(this.userExtraInfo, 'logo') || _.get(JTL, 'CONFIG.logo') || getEnvValue('VUE_APP_LOGO', '/qulogo.ico');
        document.getElementsByTagName('head')[0].appendChild(link);
    }

    @Mutation
    public SET_REDIRECT_PATH(redirectPath?: string): void {
        if (redirectPath) {
            localStorage.setItem(JTL_REDIRECT_PATH_LOCAL, redirectPath);
        } else {
            localStorage.removeItem(JTL_REDIRECT_PATH_LOCAL);
        }
    }

    @Mutation
    public SET_USER_PERMISSION(permissions: Array<string>): void {
        this.userPermissions = permissions;
        // console.log('set permission', this.userPermissions);
    }

    @Mutation
    public SET_CURRENT_PROJECT(project: ProjectEntityModel): void {
        this.currentProject = project;
        if (project) {
            localStorage.setItem(JTL_CURRENT_PROJECT, JSON.stringify(project.toService()));
        } else {
            localStorage.removeItem(JTL_CURRENT_PROJECT);
        }
    }

    @Mutation
    public SET_HAS_ERROR(hasError): void {
        this.hasError = hasError;
    }

    /**
     * 获取用户权限
     */
    get UserPermissions(): Array<string> {
        const currentPermissions = _.get(_.find(this.userPermissions, item => (item.type === this.CurrentUserPermissionType)), 'urls') || [];
        return _.union(EVERYONE_MENUS, currentPermissions);
    }

    /**
     * 获取用户权限类型
     */
    get CurrentUserPermissionType(): PermissionType {
        if (_.get(this.userExtraInfo, 'IsSuperAdmin')) {
            return PermissionType.PLATFORM;
        }
        return this.CurrentProjectId ? PermissionType.PROJECT : PermissionType.CORP;
    }

    /**
     * 获取默认路由
     */
    get DefaultRoute(): string {
        let defaultRoute = null;
        // return _.get(this.UserPermissions, '[0]');
        switch (this.CurrentUserPermissionType) {
            case PermissionType.PLATFORM:
                defaultRoute = '/admin/corp-list';
                break;
            // case PermissionType.CORP:
            //     defaultRoute = '/dashboard/project-list';
            //     break;
            default:
            case PermissionType.PROJECT:
                defaultRoute = '/dashboard/index';
                break;
        }
        return defaultRoute;
    }

    /**
     * 获取登录用户的token
     */
    get Token() {
        return _.get(this.userInfo, 'token');
    }
    /**
     * 获取登录用户信息
     */
    get UserInfo() {
        return this.userExtraInfo;
    }

    /**
     * 是否是超级管理员
     */
    get IsSuperAdmin() {
        return false;
        // return _.get(this.userExtraInfo, 'IsSuperAdmin');
    }

    /**
     * 是否是平台用户
     */
    get IsAdmin() {
        return true;
    }

    /**
     * 获取登录用户的RefreshToken
     */
    get RefreshToken() {
        return _.get(this.userInfo, 'refreshToken');
    }

    /**
     * 获取用户登陆状态
     */
    get IsLogin(): boolean {
        return !!this.Token;
    }

    get HasError(): boolean {
        return this.hasError;
    }

    /**
     * 获取登陆后的跳转路径
     */
    get RedirectPath(): string {
        return localStorage.getItem(JTL_REDIRECT_PATH_LOCAL) || this.DefaultRoute;
    }

    /**
     * 获取登录用户的用户名
     */
    get UserName(): string {
        return _.get(this.userExtraInfo, 'username') || _.get(this.userExtraInfo, 'email');
    }

    get CorpName(): string {
        const corpName = _.get(this.userExtraInfo, 'corpName') || _.get(this.userInfo, 'corpName');
        if (corpName) {
            return `${corpName}智慧运管平台`;
        }
        return `${JTL?.CONFIG?.corpName || process.env.VUE_APP_CORP_NAME}智慧运管平台`;
    }

    get Logo(): string {
        return _.get(this.currentProject, 'logo') || _.get(this.userExtraInfo, 'logo') || _.get(JTL, 'CONFIG.logo') || getEnvValue('VUE_APP_LOGO', '/qulogo.ico');
    }

    get LoginImage(): string {
        return _.get(this.currentProject, 'loginBg') || _.get(JTL, 'CONFIG.webLoginBg') || getEnvValue('VUE_APP_WEB_LOGIN_BG', '/img/login-bg.png');
    }

    /**
     * 获取登录用户的ID
     */
    get UserId(): string {
        return _.get(this.userExtraInfo, 'id');
    }

    /**
     * 获取当前选择的项目
     */
    get CurrentProject(): ProjectEntityModel {
        return this.currentProject;
    }

    /**
     * 获取当前选择项目的ID
     */
    get CurrentProjectId(): string {
        return _.get(this.currentProject, 'id');
    }

    // @Action
    // rsyncUserInfo() {
    //     const localToken = localStorage.getItem(JTL_TOKEN_LOCAL);
    //     if (this.userInfo.token && localToken !== this.userInfo.token) {
    //         this.SET_USERINFO(getUserInfoFromLocal());
    //         if (!this.IsLogin) {
    //             window.location.reload();
    //         }
    //     }
    //     // let userInfoFromLocal: UserModel = getUserInfoFromLocal();
    //     // if (!(JSON.stringify(userInfoFromLocal) === JSON.stringify(this.userInfo))) {
    //     //     this.SET_USERINFO(userInfoFromLocal);
    //     //     if (!this.IsLogin) {
    //     //         window.location.reload();
    //     //     }
    //     // }
    // }

    @Action
    rsyncUserInfo() {
        const localToken = localStorage.getItem(JTL_TOKEN_LOCAL);

        if (this.userInfo.token && localToken !== this.userInfo.token) {
            this.SET_USERINFO(getUserInfoFromLocal());
            if (!this.IsLogin) {
                window.location.reload();
            }
        }
        // let userInfoFromLocal: UserModel = getUserInfoFromLocal();
        // if (!(JSON.stringify(userInfoFromLocal) === JSON.stringify(this.userInfo))) {
        //     this.SET_USERINFO(userInfoFromLocal);
        //     if (!this.IsLogin) {
        //         window.location.reload();
        //     }
        // }
    }
}

export const UserStoreModule = getModule(User);

// 每隔5秒，同步localStorage的jtl_userinfo与Vuex里的userInfo
// setInterval(() => {
//     UserStoreModule.rsyncUserInfo();
// }, 5000);
