import { FormControl, FormControlType, FormControlOptionModel, FormControlThingsTypeModel } from '@/model/form-control';
import { QueryPageModel } from '@/model/query-model';
import { AttributeType, ReadWriteEnum, ThingsEntityType, ThingsFunctionType } from '@/model/enum';
import { ThingsModelTypeModel } from './things-model-type-entity';
import { thingsModelTypeFilter, readWriteFilter, attributeTypeFilter } from '@/filter';
import { ThingsEntityModel } from './things-entity';
export class ThingsAttributeEntityModel extends ThingsEntityModel {
    constructor(type?: ThingsEntityType, entityId?: string) {
        super(type, entityId);
        this.thingsModelTypeModel = new ThingsModelTypeModel();
        this.functionType = ThingsFunctionType.ATTRIBUTE;
    }

    @FormControl({
        label: '属性范围',
        type: FormControlType.RADIO_GROUP,
        required: true,
        options: [
            { name: '服务端', value: AttributeType.SERVER },
            { name: '客户端', value: AttributeType.CLIENT }
        ],
        index: 3
    } as FormControlOptionModel)
    attributeType: AttributeType = AttributeType.CLIENT;

    @FormControl({
        type: FormControlType.THINGS_MODEL_TYPE,
        layout: { labelCol: 0, wrapperCol: 24, width: '100%' },
        thingsType: ThingsFunctionType.ATTRIBUTE,
        index: 4
    } as FormControlThingsTypeModel)
    thingsModelTypeModel: ThingsModelTypeModel = undefined;

    @FormControl({
        label: '读写类型',
        type: FormControlType.RADIO_GROUP,
        required: true,
        options: [
            { name: '读写', value: ReadWriteEnum.READ_WRITE },
            { name: '只读', value: ReadWriteEnum.READONLY }
        ],
        index: 5
    } as FormControlOptionModel)
    accessMode: ReadWriteEnum = ReadWriteEnum.READ_WRITE;

    static getTableColumns() {
        return [
            {
                title: '属性名称',
                dataIndex: 'name',
                scopedSlots: { customRender: 'name' }
            },
            {
                title: '标识符',
                dataIndex: 'identifier'
            },
            {
                title: '数据类型',
                dataIndex: 'thingsModelTypeModel.thingsModelType',
                customRender: (text, record, index) => {
                    return thingsModelTypeFilter(text);
                }
            },
            // {
            //     title: '数据定义',
            //     dataIndex: 'thingsModelTypeModel.thingsModelType',
            //     customRender: (text, record, index) => {
            //         return thingsModelTypeFilter(text);
            //     }
            // },
            {
                title: '读写类型',
                dataIndex: 'accessMode',
                customRender: (text, record, index) => {
                    return readWriteFilter(text);
                }
            },
            {
                title: '属性范围',
                dataIndex: 'attributeType',
                customRender: (text, record, index) => {
                    return attributeTypeFilter(text);
                }
            },
            {
                title: '操作',
                dataIndex: 'action',
                scopedSlots: { customRender: 'action' }
            }
        ];
    }

    toModel(json): any {
        super.toModel(json);
        this.accessMode = _.get(json, 'jsonValue.accessMode');
        this.thingsModelTypeModel = new ThingsModelTypeModel().toModel(_.get(json, 'jsonValue'));
        return this;
    }

    toService() {
        const data: any = {
            id: this.id,
            entityId: this.entityId,
            attributeKey: this.identifier,
            attributeType: this.attributeType,
            type: this.entityType
        };
        data.jsonValue = this.thingsModelTypeModel.toService();
        data.jsonValue.accessMode = this.accessMode;
        data.jsonValue.name = this.name;
        data.jsonValue.identifier = this.identifier;
        data.jsonValue.remark = this.remark;
        return data;
    }
}

export class ThingsAttributeQueryModel extends QueryPageModel {
    name: string = undefined;

    toService() {
        const data = super.toService();
        data.params = this.name;
        delete data.name;
        return data;
    }
}
