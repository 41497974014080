var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "jtl-entity-attribute-form" },
    [
      _c(
        "a-form",
        { attrs: { name: "jtl-entity-attribute-form" } },
        [
          _vm.record.type === _vm.FacilityAttributeDataType.INT
            ? _c(
                "a-form-item",
                {
                  attrs: {
                    "has-feedback": _vm.record.feedback,
                    "validate-status": _vm.record.validatestatus
                  }
                },
                [
                  _c("a-input", {
                    staticStyle: { width: "200px" },
                    attrs: { "allow-clear": "" },
                    on: {
                      change: function($event) {
                        return _vm.validateField(_vm.record)
                      }
                    },
                    model: {
                      value: _vm.record.dataValue,
                      callback: function($$v) {
                        _vm.$set(_vm.record, "dataValue", $$v)
                      },
                      expression: "record.dataValue"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.record.type === _vm.FacilityAttributeDataType.FLOAT
            ? _c(
                "a-form-item",
                {
                  attrs: {
                    "has-feedback": _vm.record.feedback,
                    "validate-status": _vm.record.validatestatus
                  }
                },
                [
                  _c("a-input", {
                    staticStyle: { width: "200px" },
                    attrs: { "allow-clear": "" },
                    on: {
                      change: function($event) {
                        return _vm.validateField(_vm.record)
                      }
                    },
                    model: {
                      value: _vm.record.dataValue,
                      callback: function($$v) {
                        _vm.$set(_vm.record, "dataValue", $$v)
                      },
                      expression: "record.dataValue"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.record.type === _vm.FacilityAttributeDataType.STRING
            ? _c(
                "a-form-item",
                [
                  _c("a-input", {
                    staticStyle: { width: "200px" },
                    attrs: { "allow-clear": "" },
                    on: {
                      change: function($event) {
                        return _vm.validateField(_vm.record)
                      }
                    },
                    model: {
                      value: _vm.record.dataValue,
                      callback: function($$v) {
                        _vm.$set(_vm.record, "dataValue", $$v)
                      },
                      expression: "record.dataValue"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          [
            _vm.FacilityAttributeDataType.DEVICE,
            _vm.FacilityLinkType.DEVICE
          ].indexOf(_vm.record.type) > -1 &&
          _vm.selectType !==
            _vm.FormControlSelectDeviceAttributeType.DEVICE_TYPE
            ? _c(
                "a-form-item",
                [
                  _c(
                    "a-select",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isEdit,
                          expression: "isEdit"
                        }
                      ],
                      attrs: {
                        "allow-clear": true,
                        "show-search": "",
                        placeholder: "请选择设备",
                        mode: "default",
                        "filter-option": _vm.filterOption
                      },
                      on: {
                        change: function($event) {
                          return _vm.validateField(_vm.record)
                        },
                        search: _vm.deviceOnSearchTextChange
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "dropdownRender",
                            fn: function(menu) {
                              return _c(
                                "div",
                                {},
                                [
                                  _c("v-nodes", { attrs: { vnodes: menu } }),
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            _vm.deviceOptions &&
                                            _vm.deviceOptionPage * 10 <
                                              _vm.deviceOptions.length,
                                          expression:
                                            "deviceOptions && deviceOptionPage * 10 < deviceOptions.length"
                                        }
                                      ]
                                    },
                                    [
                                      _c("a-divider", {
                                        staticStyle: { margin: "4px 0" }
                                      }),
                                      _c(
                                        "a",
                                        {
                                          staticClass:
                                            "jtl-link select-load-more",
                                          on: {
                                            mousedown: function(e) {
                                              return e.preventDefault()
                                            },
                                            click: function($event) {
                                              _vm.deviceOptionPage++
                                            }
                                          }
                                        },
                                        [_vm._v("加载更多")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            }
                          }
                        ],
                        null,
                        false,
                        2353175122
                      ),
                      model: {
                        value: _vm.record.dataValue,
                        callback: function($$v) {
                          _vm.$set(_vm.record, "dataValue", $$v)
                        },
                        expression: "record.dataValue"
                      }
                    },
                    _vm._l(_vm.DeviceOptions, function(option) {
                      return _c(
                        "a-select-option",
                        {
                          key: option.value,
                          attrs: { value: option.value, title: option.name }
                        },
                        [
                          _vm._v(" " + _vm._s(option.name)),
                          option.remark
                            ? _c("span", [
                                _vm._v("(" + _vm._s(option.remark) + ")")
                              ])
                            : _vm._e()
                        ]
                      )
                    }),
                    1
                  ),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.isEdit,
                          expression: "!isEdit"
                        }
                      ],
                      staticClass: "edit-wrapper"
                    },
                    [
                      _vm.hasEdit
                        ? _c("a-icon", {
                            attrs: { type: "edit" },
                            on: { click: _vm.editLinkDeviceClick }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm._l(_vm.record.relations, function(item, index) {
                        return _c("a-tag", { key: index }, [
                          _vm._v(" " + _vm._s(item.entityName) + " ")
                        ])
                      })
                    ],
                    2
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.FacilityAttributeDataType.ENUM === _vm.record.type
            ? _c(
                "a-form-item",
                [
                  _c(
                    "a-select",
                    {
                      attrs: {
                        "allow-clear": true,
                        "show-search": "",
                        placeholder: "请选择" + _vm.record.name,
                        mode: "default"
                      },
                      on: {
                        change: function($event) {
                          return _vm.validateField(_vm.record)
                        }
                      },
                      model: {
                        value: _vm.record.dataValue,
                        callback: function($$v) {
                          _vm.$set(_vm.record, "dataValue", $$v)
                        },
                        expression: "record.dataValue"
                      }
                    },
                    _vm._l(_vm.record.enumList, function(option) {
                      return _c(
                        "a-select-option",
                        {
                          key: option.value,
                          attrs: { value: option.value, title: option.name }
                        },
                        [_vm._v(" " + _vm._s(option.name) + " ")]
                      )
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          [
            _vm.FacilityAttributeDataType.ATTRIBUTE,
            _vm.FacilityAttributeDataType.SERVER_ATTRIBUTE,
            _vm.FacilityLinkType.DEVICE_ATTR
          ].indexOf(_vm.record.type) > -1
            ? _c(
                "a-form-item",
                [
                  _vm.isEdit
                    ? _c("jtl-select-device-attribute-component", {
                        attrs: {
                          "scope-id": _vm.facilityTypeId,
                          identifier: _vm.record.identifier,
                          "select-type": _vm.selectType,
                          "linked-device-attribute-list":
                            _vm.record.linkedDeviceAttributeList,
                          mode:
                            _vm.selectType ===
                            _vm.FormControlSelectDeviceAttributeType
                              .FACILITY_CONFIG
                              ? "multiple"
                              : "default"
                        },
                        on: {
                          change: function($event) {
                            return _vm.validateField(_vm.record)
                          }
                        },
                        model: {
                          value: _vm.record.dataValue,
                          callback: function($$v) {
                            _vm.$set(_vm.record, "dataValue", $$v)
                          },
                          expression: "record.dataValue"
                        }
                      })
                    : _vm._e(),
                  !_vm.isEdit
                    ? _c(
                        "span",
                        { staticClass: "edit-wrapper" },
                        [
                          _vm.hasEdit
                            ? _c("a-icon", {
                                attrs: { type: "edit" },
                                on: {
                                  click: function($event) {
                                    _vm.isEdit = !_vm.isEdit
                                  }
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm._l(_vm.record.relations, function(item, index) {
                            return _c("a-tag", { key: index }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    item.entityName +
                                      " | " +
                                      item.attrName +
                                      "(" +
                                      item.attrKey +
                                      ")"
                                  ) +
                                  " "
                              )
                            ])
                          })
                        ],
                        2
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          _vm.record.type === _vm.FacilityAttributeDataType.PEOPLE
            ? _c(
                "a-form-item",
                [
                  _c("jtl-select-people-component", {
                    on: {
                      change: function($event) {
                        return _vm.validateField(_vm.record)
                      }
                    },
                    model: {
                      value: _vm.record.dataValue,
                      callback: function($$v) {
                        _vm.$set(_vm.record, "dataValue", $$v)
                      },
                      expression: "record.dataValue"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.record.type === _vm.FacilityAttributeDataType.BOOLEAN
            ? _c(
                "a-form-item",
                [
                  _c("a-switch", {
                    attrs: {
                      "checked-children": "true",
                      "un-checked-children": "false"
                    },
                    on: {
                      change: function($event) {
                        return _vm.validateField(_vm.record)
                      }
                    },
                    model: {
                      value: _vm.record.dataValue,
                      callback: function($$v) {
                        _vm.$set(_vm.record, "dataValue", $$v)
                      },
                      expression: "record.dataValue"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.record.type === _vm.FacilityAttributeDataType.SPACE
            ? _c(
                "a-form-item",
                [
                  _c("jtl-select-space-component", {
                    on: {
                      change: function($event) {
                        return _vm.validateField(_vm.record)
                      }
                    },
                    model: {
                      value: _vm.record.dataValue,
                      callback: function($$v) {
                        _vm.$set(_vm.record, "dataValue", $$v)
                      },
                      expression: "record.dataValue"
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }