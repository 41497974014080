import { FormControl, FormControlType, FormControlTextModel, FormControlOptionModel, FormControlModel } from '@/model/form-control';
import { GroupQueryModel } from '@/model/query-model';
import { QueryControl, QueryControlType } from '@/model/query-control';
import FacilityGroupService from '@/service/facility-group';
import SpaceV2Service from '@/service/space-v2';
import CommonService from '@/service/common';
import { generateUUID } from '@/utils/base_util';
import { BaseEntityModel } from '@/model/base-model';
import { GroupEntityType, EntityStatus } from '@/model/enum';
import LabelService from '@/service/label';
import { entityStatusList } from '@/filter/entity-status';

export class FacilityV2EntityModel extends BaseEntityModel {
    constructor(groupId?: string) {
        super();
        if (groupId) {
            this.groupIds = [groupId];
        }
    }

    @FormControl({
        label: '分组',
        type: FormControlType.TREE_SELECT,
        optionsPromise: FacilityGroupService.getGroupTree,
        optionsPromiseParam: GroupEntityType.FACILITY,
        mode: 'multiple',
        required: true,
        index: 1
    } as FormControlOptionModel)
    groupIds: Array<string> = undefined;

    @FormControl({
        label: '出厂编号',
        type: FormControlType.TEXT,
        required: false,
        message: '外部编号',
        max: 255,
        index: 2
    } as FormControlTextModel)
    code: string = undefined;

    @FormControl({
        label: '设施编号',
        type: FormControlType.TEXT,
        required: false,
        message: '系统自动生成编号',
        max: 255,
        index: 2,
        readonly: true,
        addHidden: true
    } as FormControlTextModel)
    facilityCode: string = undefined;

    @FormControl({
        label: '名称',
        type: FormControlType.TEXT,
        required: true,
        max: 255,
        index: 3
    } as FormControlTextModel)
    name: string = undefined;

    @FormControl({
        label: '类型',
        type: FormControlType.SELECT,
        required: true,
        optionsPromise: CommonService.getAllFacilityType,
        index: 4,
        message: '请选取已配置类型'
    } as FormControlOptionModel)
    typeId: string = undefined;
    typeName: string = undefined;

    @FormControl({
        label: '空间位置',
        type: FormControlType.TREE_SELECT,
        optionsPromise: SpaceV2Service.getLocationTree,
        required: false,
        index: 5
    } as FormControlModel<string>)
    locationId: string = undefined;
    locationName: string = undefined;
    locations: Array<string> = undefined;

    @FormControl({
        label: '是否启用',
        type: FormControlType.RADIO_GROUP,
        options: entityStatusList
    } as FormControlOptionModel)
    enable: EntityStatus = EntityStatus.ENABLE;

    @FormControl({
        label: '标签',
        type: FormControlType.SELECT,
        optionsPromise: LabelService.getAllLabel.bind(LabelService),
        mode: 'multiple',
        required: false
    } as FormControlOptionModel)
    tagIds: Array<string> = undefined;

    get LocationName() {
        return _.takeRight(this.locations, 2).join(' / ') || this.locationName;
    }

    /**
     * 是否有子设备
     */
    hasChildren: boolean = undefined;

    /**
     * 子设备
     */
    children: Array<FacilityV2EntityModel> = undefined;
    childrenTotalCount: number = 0;
    childrenPage: number = 0;
    parent: FacilityV2EntityModel;
    expand: boolean = false;
    parentId: string = undefined;
    check: boolean = false;

    get Children() {
        if (this.children && this.children.length > 0) {
            if (this.children.length === this.childrenTotalCount) {
                return this.children;
            }
            return [...this.children, {
                uuid: generateUUID(),
                id: null,
                parent: this,
                name: '点击加载更多...'
            }];
        }
        return this.children;
    }

    static getTableColumns() {
        return [
            {
                title: '名称',
                dataIndex: 'name',
                scopedSlots: { customRender: 'name' }
            },
            {
                title: '类型',
                dataIndex: 'typeName'
            },
            {
                title: '位置',
                dataIndex: 'LocationName'
            },
            {
                title: '创建时间',
                dataIndex: 'createdTime'
            },
            {
                title: '启用状态',
                dataIndex: 'enable',
                scopedSlots: { customRender: 'enabled' }
            },
            {
                title: '操作',
                dataIndex: 'action',
                scopedSlots: { customRender: 'action' }
            }
        ];
    }

    toModel(json): any {
        super.toModel(json);
        // if (this.hasChildren) {
        //     this.children = [];
        // }
        this.enable = `${json.enable}` as EntityStatus;
        return this;
    }

    toService() {
        // const data = super.toService();
        // delete data.spaceName;
        // delete data.typeName;
        // return data;
        return {
            id: this.id,
            code: this.code,
            parentId: this.parentId,
            typeId: this.typeId,
            name: this.name,
            locationId: this.locationId,
            groupIds: this.groupIds,
            tagIds: this.tagIds,
            enable: this.enable
        } as any;
    }
}

export class FacilityV2QueryModel extends GroupQueryModel {
    @QueryControl({
        label: '安装位置',
        type: QueryControlType.TREE_SELECT,
        selectChildren: true,
        optionsPromise: SpaceV2Service.getLocationTree,
        span: 8
    })
    locationIds: Array<string> = undefined;

    @QueryControl({
        label: '设施类型',
        type: QueryControlType.SELECT,
        mode: 'multiple',
        optionsPromise: CommonService.getAllFacilityType,
        span: 8
    })
    typeIdList: Array<string> = undefined;

    @QueryControl({
        label: '名称',
        type: QueryControlType.TEXT,
        span: 8
    })
    name: string = undefined;

    forceRoot: boolean = false;
    parentId: string = undefined;

    get TypeIdList() {
        if (this.typeIdList && this.typeIdList.length > 0) {
            return this.typeIdList;
        }
        return undefined;
    }

    toService() {
        return {
            params: {
                name: this.name,
                locationIds: this.locationIds,
                typeIdList: this.TypeIdList,
                groupIds: this.GroupIds,
                forceRoot: this.forceRoot,
                parentId: this.parentId
            }
        };
    }
}

export class LinkageFacilityQueryModel extends FacilityV2QueryModel {
    linkageId: string = undefined;
    groupId: string = undefined;

    toService() {
        // this.forceRoot = false;
        const data: any = {
            params: {
                facilitySearchDto: super.toService().params,
                linkageId: this.linkageId,
                groupId: this.groupId
            }
        };
        return data;
    }
}
