import { Component } from 'vue-property-decorator';
import TopoBaseView from './topo-base-view';

@Component
export default class TopoBasePanelView extends TopoBaseView {
    /**
     * 拖拽移入移出计数
     * 如果panel组件有子组件，鼠标移动到子组件上时+1，同时触发移出panel组件事件
     * 大于0时: 在panel中拖拽
     * 等于0时: 移出panel组件以及子组件
     */
    dragEnterCount: number = 0;
    dragenter() {
        // console.log('dragenter', this.dragEnterCount);
        this.dragEnterCount++;
    }
    dragleave() {
        // console.log('dragleave', this.dragEnterCount);
        this.dragEnterCount--;
        if (this.dragEnterCount < 0) {
            this.dragEnterCount = 0;
        }
    }
    drop() {
        this.dragEnterCount = 0;
    }

    get IdDragOver() {
        return this.dragEnterCount > 0;
    }
}
