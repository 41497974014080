import { BaseModel, BaseEntityModel } from '@/model/base-model';
import { DeviceTypeEnum, DeviceStatus, GroupEntityType, TemplateType } from '@/model/enum';
import { FormControl, FormControlType, FormControlModel, FormControlTextModel, FormControlOptionModel, FormControlUploadModel } from '@/model/form-control';
import CommonService from '@/service/common';
import { ThingsTemplateQueryModel } from './things-template-entity';
import { ThingsFunctionGroupModel } from './things-function-entity';
import { dateFormat, dateFromNow } from '@/filter';
import { generateUUID } from '@/utils/base_util';
import { QueryControl, QueryControlType } from '@/model/query-control';
import { deviceStatusList } from '@/filter/device-status';

export class DeviceEntityModel extends ThingsFunctionGroupModel {
    constructor(groupId?: string) {
        super(groupId, GroupEntityType.DEVICE);
    }

    @FormControl({
        label: '编号',
        type: FormControlType.TEXT,
        required: false,
        max: 255
    } as FormControlTextModel)
    code: string = undefined;
    accessToken: string = undefined;

    @FormControl({
        label: '设备名称',
        type: FormControlType.TEXT,
        required: true,
        max: 255
    } as FormControlTextModel)
    displayName: string = undefined;
    /**
     * 协议
     */
    protocol: string = undefined;

    /**
     * 网关/设备
     */
    deviceType: DeviceTypeEnum = undefined;

    /**
     * 禁用/启用状态
     */
    status: boolean = undefined;
    /**
     * 激活/未激活状态
     */
    active: boolean = undefined;
    /**
     * 在线/离线状态
     */
    online: boolean = undefined;

    get Online() {
        return this.online ? DeviceStatus.ONLINE : DeviceStatus.OFFLINE;
    }

    get Active() {
        return this.active ? '激活' : '未激活';
    }

    /**
     * 最后上线时间
     */
    lastActivityTime: number = undefined;

    /**
     * 空/参数
     */
    attributeEmptyRatio: string = undefined;

    @FormControl({
        label: '所属产品',
        type: FormControlType.SELECT,
        optionsPromise: CommonService.getDeviceTypeOptions,
        readonly: true,
        required: true
    } as FormControlOptionModel)
    typeId: string = undefined;
    typeName: string = undefined;

    /**
     * 设备类型id
     */
    @FormControl({
        label: '安装位置',
        type: FormControlType.SELECT_SPACE
    } as FormControlModel<string>)
    positionId: string = undefined;
    positionName: string = undefined;

    // @FormControl({
    //     label: '负责人',
    //     type: FormControlType.SELECT,
    //     optionsPromise: CommonService.getPersonList
    // } as FormControlOptionModel)
    // personId: string = undefined;
    // personName: string = undefined;

    @FormControl({
        label: '备注名称',
        type: FormControlType.TEXT
    } as FormControlTextModel)
    remark: string = undefined;

    /**
     * 是否有子设备
     */
    hasChildren: boolean = undefined;

    /**
     * 子设备
     */
    children: Array<DeviceEntityModel> = undefined;
    childrenTotalCount: number = 0;
    childrenPage: number = 0;
    parent: DeviceEntityModel;
    expand: boolean = false;

    parentPath: string = null;

    get Children() {
        if (this.children && this.children.length > 0) {
            if (this.children.length === this.childrenTotalCount) {
                return this.children;
            }
            return [...this.children, {
                uuid: generateUUID(),
                id: null,
                parent: this,
                displayName: '点击加载更多...'
            }];
        }
        return this.children;
    }

    check: boolean = undefined;

    static getTableColumns() {
        return [
            // {
            //     scopedSlots: { customRender: 'expandIcon' },
            //     width: 50
            // },
            {
                title: '设备名称',
                dataIndex: 'displayName',
                scopedSlots: { customRender: 'name' },
                width: 300,
                ellipsis: true,
                fixed: 'left'
            },
            {
                title: '所属产品',
                dataIndex: 'typeName',
                width: 150,
                ellipsis: true
            },
            {
                title: '在线/激活',
                scopedSlots: { customRender: 'deviceStatus' },
                width: 120
            },
            {
                title: '安装位置',
                dataIndex: 'positionName',
                width: 120,
                ellipsis: true
            },
            {
                title: '空/参数',
                dataIndex: 'attributeEmptyRatio',
                width: 120,
                ellipsis: true
            },
            {
                title: '最后活跃时间',
                dataIndex: 'lastActivityTime',
                customRender: (text, record, index) => {
                    if (text) {
                        return `${dateFormat(text)} (${dateFromNow(text)})`;
                    }
                    return '-';
                },
                width: 250
            },
            {
                title: '操作',
                dataIndex: 'action',
                scopedSlots: { customRender: 'action' },
                width: 380,
                fixed: 'right'
            }
        ];
    }

    toModel(json): any {
        super.toModel(json);
        // if (_.get(json, 'children')) {
        //     this.children = _.map(_.get(json, 'children'), item => new DeviceEntityModel().toModel(item));
        // }
        this.deviceType = _.get(json, 'deviceType') || _.get(json, 'productType');
        if (this.hasChildren) {
            this.children = [];
        }
        return this;
    }

    toService() {
        return {
            id: this.id,
            code: this.code,
            groupId: this.groupId,
            displayName: this.displayName,
            typeId: this.typeId,
            // personId: this.personId,
            positionId: this.positionId,
            remark: this.remark
        };
    }
}

export class DeviceCopyEntityModel extends BaseEntityModel {
    isCopy: boolean = true;
    @FormControl({
        label: '基础名称',
        type: FormControlType.TEXT,
        required: true
    } as FormControlTextModel)
    baseName: string = undefined;

    @FormControl({
        label: '后缀名称起始值',
        type: FormControlType.NUMBER,
        required: true
    } as FormControlTextModel)
    suffixStartValue: string = undefined;

    @FormControl({
        label: '设备数量',
        type: FormControlType.NUMBER,
        min: 1,
        max: 1000,
        required: true
    } as FormControlTextModel)
    deviceCount: number = undefined;
    parentId: string = undefined;

    toService() {
        return {
            baseName: this.baseName,
            deviceCount: this.deviceCount,
            // deviceTypeId: this.typeId,
            // groupId: this.groupId,
            parentDeviceId: this.parentId,
            // positionId: this.positionId,
            suffixStartValue: this.suffixStartValue
        };
    }
}

export class DeviceImportEntityModel extends ThingsFunctionGroupModel {
    isImport: boolean = true;
    @FormControl({
        label: '所属产品',
        type: FormControlType.SELECT,
        optionsPromise: CommonService.getDeviceTypeOptions,
        readonly: false,
        required: true
    } as FormControlOptionModel)
    typeId: string = undefined;

    /**
     * 设备类型id
     */
    @FormControl({
        label: '安装位置',
        type: FormControlType.SELECT_SPACE
    } as FormControlModel<string>)
    positionId: string = undefined;

    @FormControl({
        label: '基础名称',
        type: FormControlType.TEXT,
        required: true
    } as FormControlTextModel)
    baseName: string = undefined;

    @FormControl({
        label: '后缀名称起始值',
        type: FormControlType.NUMBER,
        required: true
    } as FormControlTextModel)
    suffixStartValue: string = undefined;

    @FormControl({
        label: '设备数量',
        type: FormControlType.NUMBER,
        min: 1,
        max: 1000,
        required: true
    } as FormControlTextModel)
    deviceCount: number = undefined;
    parentId: string = undefined;

    toService() {
        return {
            baseName: this.baseName,
            deviceCount: this.deviceCount,
            deviceTypeId: this.typeId,
            groupId: this.groupId,
            parentDeviceId: this.parentId,
            positionId: this.positionId,
            suffixStartValue: this.suffixStartValue
        };
    }
}

export class DeviceImportExcelEntityModel extends ThingsFunctionGroupModel {
    /**
     * 设备类型id
     */
    @FormControl({
        label: '安装位置',
        type: FormControlType.SELECT_SPACE
    } as FormControlModel<string>)
    positionId: string = undefined;

    @FormControl({
        label: '批量上传',
        type: FormControlType.UPLOAD,
        accept: '.xlsx',
        maxFileNumber: 1,
        notUploadOss: true,
        required: true
    } as FormControlUploadModel)
    excelFile: Array<any> = undefined;
    parentId: string = undefined;

    toService() {
        const formData = new FormData();
        formData.append('file', _.get(this.excelFile, '[0].file'));
        if (this.parentId) {
            formData.append('parentId', this.parentId);
        }
        if (this.groupId) {
            formData.append('groupId', this.groupId);
        }
        if (this.positionId) {
            formData.append('positionId', this.positionId);
        }
        return formData;
        // return {
        //     file: _.get(this.excelFile, '[0].file'),
        //     groupId: this.groupId,
        //     parentId: this.parentId,
        //     positionId: this.positionId
        // };
    }
}
export class ImportExcelMatchEntityModel extends BaseModel {
    @FormControl({
        label: '批量上传',
        type: FormControlType.UPLOAD,
        accept: '.xlsx',
        maxFileNumber: 1,
        notUploadOss: true,
        required: true
    } as FormControlUploadModel)
    excelFile: Array<any> = undefined;

    toService() {
        const formData = new FormData();
        formData.append('file', _.get(this.excelFile, '[0].file'));
        return formData;
    }
}
export class ExportTemplateEntityModel extends BaseModel {
    @FormControl({
        label: '类型',
        type: FormControlType.RADIO_GROUP,
        options: [
            { name: '设备模板', value: TemplateType.DEVICE },
            { name: '参数模板', value: TemplateType.ATRIBUTE }
        ],
        cascad: true,
        required: true
    } as FormControlOptionModel)
    type: TemplateType = TemplateType.DEVICE;

    @FormControl({
        label: '产品',
        optionsPromise: CommonService.getDeviceTypeOptions,
        type: FormControlType.SELECT,
        invisibleFunction: model => model.type !== TemplateType.DEVICE,
        required: true
    } as FormControlOptionModel)
    deviceTempId: string = undefined;

    @FormControl({
        label: '产品',
        optionsPromise: CommonService.getProtocolNotNullList,
        type: FormControlType.SELECT,
        invisibleFunction: model => model.type !== TemplateType.ATRIBUTE,
        required: true
    } as FormControlOptionModel)
    protocolTempId: string = undefined;

    toService() {
        const data = { deviceTypeId: '' };
        if (this.protocolTempId) {
            data.deviceTypeId = this.protocolTempId;
        } else {
            data.deviceTypeId = this.deviceTempId;
        }
        return data;
    }
}

export class DeviceQueryModel extends ThingsTemplateQueryModel {
    deviceId: string = undefined;
    parentId: string = undefined;

    @QueryControl({
        label: '所属产品',
        type: QueryControlType.SELECT,
        optionsPromise: CommonService.getDeviceTypeOptions,
        hasAllOption: true,
        span: 6,
        index: 1
    })
    typeId: string = JTL.CONSTANT.ALL_OPTION.value;

    @QueryControl({
        label: '在线状态',
        type: QueryControlType.SELECT,
        options: deviceStatusList,
        hasAllOption: true,
        span: 6,
        index: 2
    })
    online: string = JTL.CONSTANT.ALL_OPTION.value;
    // status: string = undefined;
    @QueryControl({
        label: '激活状态',
        type: QueryControlType.SELECT,
        options: [
            { name: '激活', value: 'true' },
            { name: '未激活', value: 'false' }
        ],
        hasAllOption: true,
        span: 6,
        index: 3
    })
    active: string = JTL.CONSTANT.ALL_OPTION.value;
    spaceIds: Array<string> = undefined;
    supportedTypeIdList: Array<string> = undefined;
    forceRoot: boolean = true;

    @QueryControl({
        label: '名称',
        type: QueryControlType.TEXT,
        span: 6,
        index: 4
    })
    name: string = undefined;

    toService() {
        const data = {
            params: {
                groupIds: this.GroupIds,
                spaceIds: _.filter(this.spaceIds, item => item),
                displayName: this.name || '',
                typeId: this.typeId || '',
                deviceId: this.deviceId || '',
                parentId: this.parentId,
                online: this.online,
                // status: this.status,
                active: this.active,
                supportedTypeIdList: this.supportedTypeIdList || [],
                forceRoot: this.forceRoot
            }
        };
        return data;
    }
}

export class SelectDeviceModel extends BaseModel {
    id: string = undefined;
    name: string = undefined;
    attributes: Array<SelectDeviceAttributeModel> = undefined;
    // get Visible() {
    //     return _.filter(this.attributes, item => item.visible).length > 0;
    // }
    toModel(json) {
        this.id = _.get(json, 'entityId') || _.get(json, 'deviceId') || _.get(json, 'deviceTypeId');
        this.name = _.get(json, 'entityName') || _.get(json, 'deviceName') || _.get(json, 'name') || _.get(json, 'deviceTypeName');
        if (_.get(json, 'values')) {
            this.attributes = _.map(_.get(json, 'values'), item => new SelectDeviceAttributeModel().toModel(Object.assign({ deviceName: this.name, entityId: this.id }, item)));
        } else if (_.get(json, 'attr') || _.get(json, 'attrKey')) {
            this.attributes = [new SelectDeviceAttributeModel().toModel(Object.assign({ deviceName: this.name, entityId: this.id }, json))];
        }
        return this;
    }
}
export class SelectDeviceAttributeModel extends BaseModel {
    deviceId: string = undefined;
    deviceName: string = undefined;
    key: string = undefined;
    attributeName: string = undefined;
    attributeType: string = undefined;
    disabled: boolean = false;

    get DeviceIdAndKey() {
        if (this.deviceId && this.key) {
        // return `${this.deviceId}|${this.key}|${this.attributeType}`;
            return `${this.deviceId}|${this.key}`;
        }
        return undefined;
    }
    set DeviceIdAndKey(val: string) {
        const valArray = val.split('|');
        if (Array.isArray(valArray) && valArray.length === 2) {
            this.deviceId = valArray[0];
            this.key = valArray[1];
        }
    }

    get DeviceNameAndDeviceIdAndAttributeNameAndAttributeKey() {
        if (this.deviceName && this.deviceId && this.key) {
        // return `${this.deviceId}|${this.key}|${this.attributeType}`;
            return `${this.deviceName}|${this.deviceId}|${this.attributeName}|${this.key}`;
        }
        return undefined;
    }
    set DeviceNameAndDeviceIdAndAttributeNameAndAttributeKey(val: string) {
        const valArray = val.split('|');
        if (Array.isArray(valArray) && valArray.length === 4) {
            this.deviceName = valArray[0];
            this.deviceId = valArray[1];
            this.attributeName = valArray[2];
            this.key = valArray[3];
        }
    }
    get DeviceNameAndAttributeNameAndAttributeKey() {
        if (this.deviceName && this.attributeName && this.key) {
            return `${this.deviceName} | ${this.attributeName}(${this.key})`;
        }
        return undefined;
    }

    toModel(json) {
        this.deviceId = _.get(json, 'deviceId') || _.get(json, 'entityId') || _.get(json, 'devId');
        this.key = _.get(json, 'keys') || _.get(json, 'key') || _.get(json, 'attr') || _.get(json, 'attrKey');
        this.attributeType = _.get(json, 'attributeType') || _.get(json, 'attrType');
        this.attributeName = _.get(json, 'attrName');
        this.deviceName = _.get(json, 'deviceName');
        return this;
    }
}

export function getDeviceId(unionKeyStr: string): string {
    const valArray = unionKeyStr?.split('|');
    if (Array.isArray(valArray)) {
        if (valArray.length === 2) {
            return valArray[0];
        } else if (valArray.length === 4) {
            return valArray[1];
        }
    }
    return unionKeyStr;
}

export function getDeviceIdAndAttributeKey(unionKeyStr: string): {deviceId: string, attributeKey: string} {
    const valArray = unionKeyStr.split('|');
    if (Array.isArray(valArray)) {
        if (valArray.length === 4) {
            return {
                deviceId: valArray[1],
                attributeKey: valArray[3]
            };
        } else if (valArray.length === 2) {
            return {
                deviceId: valArray[0],
                attributeKey: valArray[1]
            };
        }
    }
    return {
        deviceId: '',
        attributeKey: ''
    };
}

export class SelectDeviceAttributeRadioModel extends SelectDeviceAttributeModel {
    radio: number = undefined;

    toModel(json) {
        super.toModel(json);
        this.radio = _.get(json, 'radio');
        return this;
    }
}
