import { render, staticRenderFns } from "./data-empty.vue?vue&type=template&id=7ccdae47&"
var script = {}
import style0 from "./data-empty.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\git.fe\\jtl3d-bi-admin\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7ccdae47')) {
      api.createRecord('7ccdae47', component.options)
    } else {
      api.reload('7ccdae47', component.options)
    }
    module.hot.accept("./data-empty.vue?vue&type=template&id=7ccdae47&", function () {
      api.rerender('7ccdae47', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/jtl-chart/components/data-empty.vue"
export default component.exports