export type SelectMode = 'default' | 'multiple' | 'tags' | 'combobox';
export type UploadListType = 'text' | 'picture' | 'picture-card';

export enum ViewModeType {
    NEW = 0, // 新增模式
    UPDATE = 1, // 编辑模式
    VIEW = 2, // 查看模式
}

export enum EnableType {
    ENABLE = 1, // 启用状态
    DISABLE = 0 // 禁用状态
}

export enum DeviceTypeEnum {
    INNER_GATEWAY = 1, // 系统内置网关，不能删除，不能修改
    GATEWAY = 2, // 普通网关
    DEVICE = 3 // 普通设备
}

/**
 * depreciated
 */
export enum AttributeDataType {
    STRING = 1,
    INT = 2,
    FLOAT = 3,
    BOOLEAN = 4,
    JSON = 5
}

export enum FacilityAttributeDataType {
    DEVICE = 1,
    INT = 4,
    FLOAT = 2,
    BOOLEAN = 3,
    STRING = 7,
    PEOPLE = 6,
    ATTRIBUTE = 8,
    SPACE = 9,
    SERVER_ATTRIBUTE = 10,
    ENUM = 11
}

export enum ThingsModelType {
    STRING = 'string',
    TEXT = 'text',
    PASSWORD = 'password',
    INT = 'int',
    FLOAT = 'float',
    DOUBLE = 'double',
    BOOLEAN = 'bool',
    ENUM = 'enum',
    DATE = 'date',
    JSON = 'json'
}

export enum AttributeType {
    SERVER = 'SERVER_SCOPE', // 服务端属性
    SHARE = 'SHARE_SCOPE', // 共享属性
    CLIENT = 'CLIENT_SCOPE' // 设备属性
}

export enum ReadWriteEnum {
    READONLY = 'r',
    READ_WRITE = 'rw'
}

export enum AttributeEntityType {
    DEVICE = 1,
    DEVICE_TYPE = 2,
    FACILITY = 3,
    FACILITY_TYPE = 4
}

export enum DeviceStatus {
    ONLINE = 'true',
    OFFLINE = 'false',
    // ENABLE = 'enable',
    // DISABLE = 'disable',
    // ACTIVE = 'active',
    // DISACTIVE = 'disactive'
}

export enum EntityStatus {
    ENABLE = 'true',
    DISABLE = 'false',
}

export enum EventLevelType {
    INFO = 1, // 信息
    ALERT = 2, // 告警
    ERROR = 3 // 故障
}
export enum ProtocolType{
    MODBUS='Modbus',
    BACNET='Bacnet',
    OPCWEB='OPCWeb'
}
export enum VariableOption{
    ANALOGINPUT='analog-input',
    ANALOGOUTPUT='analog-output',
    ANALOGVALUE='analog-value',
    BINARYINPUT='binary-input',
    BINARYOUTPUT='binary-output',
    BINARYVALUE='binary-value'
}

// 配置类型
export enum PropertyEntityType {
    MODEL_CONF = 'model', // 空间位置
    EVENT_LEVEL_CONF = 'eventLevel', // 事件级别
    EVENT_STATUS_CONF = 'eventStatus', // 处理状态
    WORK_ORDER_TYPE_CONF = 'workOrderType', // 类型
    WORK_ORDER_LEVEL_CONF = 'workOrderLevel', // 级别
    WORK_ORDER_EVALUATION_LEVEL_CONF = 'workOrderEvalLevel', // 满意度
    UNIT_CONF ='unit', // 单位
    EVENT_TYPE_CONF ='eventType', // 事件分类（工单）
    SERVICE_REQUEST_TYPE_CONF = 'serviceRequestType', // 服务请求分类（工单）
    ASSET_STATUS = 'assetStatus', // 资产状态
    EVENT_GRADE = 'eventGrade', // 事件级别
    LABEL_TYPE = 'tagType', // 标签类型
}

export enum ThingsFunctionType {
    ATTRIBUTE = 'attribute',
    EVENT = 'event',
    METHOD = 'method',
    FEATURE = 'feature'
}

export enum ThingsEntityType {
    FUNCTION = 1, // 物功能
    TEMPLATE = 2, // 物模板
    FEATURE = 3, // 物特征
    DEVICE_TYPE = 4, // 产品
}

export enum SyncType {
    // 0:同步到本地 1:同步到目标服务
    UPLOAD = 1,
    DOWNLOAD = 0
}

export enum GroupEntityType {
    TEMPLATE = 1, // 物模板
    FEATURE = 2, // 物特征
    DEVICE_TYPE = 3, // 产品
    DEVICE = 4, // 设备
    ORG = 5, // 组织架构分组
    FACILITY = 'FACILITYV2', // 设施分组
    ASSET = 7, // 资产分组
    FACILITY_CONFIG = 10, // api没有这个类型，只在前端使用
    TAG = 11, // 标签分组
    FACILITY_TYPE = 'FACILITYTYPE',
    LINKAGE = 'LINKAGE',
    // 运维相关分组
    BACKUP_ASSET = 'BACKUP', // 备品分组
    KNOWLEDGE = 'KNOWLEDGE', // 知识库
}

export enum TagEntityType {
    FACILITY_V2 = 'FACILITY_V2',
    LOCATION ='LOCATION',
    FACILITY_TYPE = 'FACILITY_TYPE'
}

export enum WorkOrderType {
    EVENT = 'Event',
    PROBLEM = 'Problem',
    CHANGE = 'Change',
    PUBLISH = 'Publish',
    LORE = 'Lore',
    REPAIR = 'Repair',
    INSPECTION = 'Inspection',
    MAINTENANCE = 'Maintenance'
}

export enum ServiceOptionType {
    SERVICE_CATEGORY = 'ServiceCategory',
    WORK_ORDER_STATUS_ENUM = 'WorkOrderStatusEnum',
    WORK_ORDER_OVERTIME_STATUS_ENUM = 'WorkOrderOverTimeStatusEnum'
}

// 业务配置 - 设施类型
export enum FacilityType{
    SPACE = 'space', // 环境空间类型
    OFFICE = 'office', // 环境空间-办公室管理
    MEETING = 'meeting', // 环境空间-会议室管理
    DEPARTMENT = 'department',
    FACILITY = 'facility', // 设施管理类型&设备
    SECURITY = 'security', // 综合安防类型&设备
    PEDESTRIAN = 'pedestrian', // 通行管理-人行管理
    VEHICLE = 'vehicle', // 通行管理-车辆管理
    PARKING_SPACE ='parking', // 通行管理-车位管理
    ENERGY ='energy', // 能源管理类型
    EnergyManage = 'energy-manage',
    HVAC = 'hvac', // 暖通
    LIGHTING = 'lighting', // 照明
    COLD_HEAT_SOURCE = 'cold-heat-source', // DHC
    // Elevator = 'elevator', // 电梯
    // EnvironmentalQuality = 'environmental-quality', // 环境质量
    ENGINE_ROOM = 'engine-room', // 机房
    FACILITY_OTHER = 'facility-other', // 设施其他

    SECURITY_HOUSE = 'security-house', // 安保岗亭
    // ShuttleBus = 'shuttle-bus', // 班车管理
    ACCESS_OTHER = 'access-other', // 其他
    CUSTOMER_FLOW = 'customer-flow', // 客流管理

    CAMERA = 'camera', // 摄像头
    FIRE = 'fire', // 消防
    SECURITY_OTHER = 'security-other', // 安防其他
    PUBLIC_OPINION = 'public-opinion', // 舆情监控

    DRAINAGE = 'water-supply-and-drainage', // 给排水
    POWER_SUPPLY = 'power-supply-and-distribution', // 供配电
    ELEVATOR_MANAGE = 'elevator-manage', // 电梯管理
}

export enum EnergyType {
    // 能源-建筑
    BUILDING = 'building',
    // 能源-分项
    SUB_METERING = 'sub',
    // 能源-分项
    DEPARTMENT = 'depart'
}

// 服务台 - 服务类型
export enum ServiceType{
    // 事件管理
    EVENT_MANAGEMENT = 'EventManagement',
    // 服务请求
    SERVICE_REQUEST = 'ServiceRequest',
    // 投诉管理
    COMPLAINT_MANAGEMENT = 'ComplaintManagement'

}

export enum WorkOrderMenuType {
    ALL = 'All',
    CREATEDBY = 'CreatedBy',
    TOCLAIM = 'ToClaim',
    ASSIGNEDTO = 'AssignedTo',
    RELATED ='Related'
}

export enum TimePlanRuleType {
    DAY = 1,
    WEEK = 3,
    MONTH = 2,
    SEASON = 4
}

export enum WorkOrderProcessGroupType {
    WAIT = 'wait', // 待处理
    HANDLE = 'handle', // 处理中
    DONE = 'done' // 已完成
}

export enum SpaceType {
    BUILDING = 'BUILDING',
    FLOOR = 'FLOOR',
    SPACE = 'SPACE',
    UNIT = 'UNIT'
}

export enum FacilityBindType {
    STATS = 'STATS',
    STATS_ATTR = 'STATS_ATTR'
}

export enum FacilityLinkType {
    DEVICE = 'DEVICE',
    DEVICE_ATTR = 'DEVICE_ATTR',
    NONE = 'NONE'
}

export enum TemplateType {
    DEVICE = 'device',
    ATRIBUTE = 'attribute'
}

export enum FormControlSelectDeviceAttributeType {
    DEVICE_TYPE = 'DEVICE_TYPE',
    FACILITY_CONFIG = 'FACILITY_CONFIG',
    DEFAULT = 'DEFAULT'
}

export enum GraphSysName {
    CUSTOMER_FLOW_SYSTEM = 'CUSTOMER_FLOW_SYSTEM',
    CAR_PARK_SYSTEM = 'CAR_PARK_SYSTEM',
    VIDEO_SYSTEM = 'VIDEO_SYSTEM',
    DHC_SYSTEM = 'DHC_SYSTEM',
    HVAC_SYSTEM = 'HVAC_SYSTEM'
}

export enum GraphGroupName {
    CUSTOMER_FLOW_AREA = 'CUSTOMER_FLOW_AREA',
    CUSTOMER_FLOW_FLOOR = 'CUSTOMER_FLOW_FLOOR',
    CAR_PACKING_AREA = 'CAR_PACKING_AREA',
    CAR_PACKING_FLOOR = 'CAR_PACKING_FLOOR',
    VIDEO_AREA = 'VIDEO_AREA',
    VIDEO_FLOOR = 'VIDEO_FLOOR',
    DHC_PROFESSION = 'DHC_PROFESSION',
    HVAC_PROFESSION = 'HVAC_PROFESSION'
}

export enum PermissionType {
    PLATFORM = 'PLATFORM',
    CORP = 'CORP',
    PROJECT = 'PROJECT'
}

export enum ReviewResult {
    PASS = 'PASS',
    REJECT = 'REJECT',
    DISABLE = 'DISABLE'
}

export enum AlarmRuleType {
    EVENT = 'EVENT',
    THRESHOLD = 'THRESHOLD'
}

export enum AlarmRuleStatus {
    WORKING = 'true',
    NOT_START = 'false'
}

export enum ParkingGateType {
    ENTRANCE = 'ENTRANCE',
    EXIT= 'EXIT'
}

export enum PeriodTypeEnum {
    HOUR = 'HOUR',
    DAY = 'DAY',
    WEEK = 'WEEK',
    MONTH = 'MONTH',
    SEASON = 'SEASON',
    YEAR = 'YEAR',
    CUSTOM = 'CUSTOM'
}

export enum ParkingSpaceTypeEnum {
    USING = 'USING',
    FREE = 'FREE',
    RATE = 'RATE'
}

export enum SubSystemType {
    ALL = 'ALL',
    PARKING = 'PARKING',
    FIRE = 'FIRE',
    CAMERA = 'CAMERA',
    ENVIROMENT = 'ENVIROMENT',
    ENERGY = 'ENERGY',
    BA = 'BA',
    CUSTOMER_FLOW = 'CUSTOMER_FLOW',
    ALARM = 'ALARM',
    ALARM_HISTORY = 'ALARM_HISTORY',
    ELEVATOR = 'elevatorSystem',
    LIGHT = 'lightingSystem',
    ACCESS_CONTROL = 'accessControlSystem'
}
