














import { Component } from 'vue-property-decorator';
import TopoBaseView from './topo-base-view';

@Component
export default class ViewImage extends TopoBaseView {
    get ImageURL() {
        return _.get(this.component, 'data.componentData');
    }
}
