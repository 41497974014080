var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "time-pickers-component" },
    _vm._l(_vm.value, function(item, index) {
      return _c(
        "a-row",
        { key: index, attrs: { type: "flex", justify: "center" } },
        [
          _c(
            "a-col",
            { attrs: { span: 22 } },
            [
              _c("a-time-picker", {
                staticClass: "width100",
                attrs: { placeholder: _vm.placeholder, format: _vm.format },
                model: {
                  value: _vm.value[index],
                  callback: function($$v) {
                    _vm.$set(_vm.value, index, $$v)
                  },
                  expression: "value[index]"
                }
              })
            ],
            1
          ),
          _c(
            "a-col",
            { staticClass: "text-center", attrs: { span: 2 } },
            [
              index === 0
                ? _c("a-icon", {
                    attrs: { type: "plus-circle" },
                    on: { click: _vm.addClick }
                  })
                : _c("a-icon", {
                    attrs: { type: "minus-circle" },
                    on: {
                      click: function($event) {
                        return _vm.removeClick(index)
                      }
                    }
                  })
            ],
            1
          )
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }