import { FormControl, FormControlType, FormControlTextModel, FormControlOptionModel, FormControlUploadModel } from '@/model/form-control';
import { DeviceTypeEnum, GroupEntityType } from '@/model/enum';
import CommonService from '@/service/common';
import { ThingsTemplateQueryModel } from './things-template-entity';
import { ThingsFunctionGroupModel } from './things-function-entity';
import { deviceTypeList } from '@/filter/device-type';
import { NUMBER_LETTER_UNDERLINE_REGEXP } from '@/model/regexp';
import { protocolTypeList } from '@/filter/protocol-type';

export class DeviceTypeEntityModel extends ThingsFunctionGroupModel {
    constructor(groupId?: string) {
        super(groupId, GroupEntityType.DEVICE_TYPE);
    }

    @FormControl({
        label: '产品名称',
        type: FormControlType.TEXT,
        required: true,
        max: 255
    } as FormControlTextModel)
    name: string = undefined;

    @FormControl({
        label: '产品标识符',
        type: FormControlType.TEXT,
        required: true,
        max: 50,
        pattern: NUMBER_LETTER_UNDERLINE_REGEXP,
        readonly: true
    } as FormControlTextModel)
    identifier: string = undefined;

    @FormControl({
        label: '协议',
        type: FormControlType.SELECT,
        options: protocolTypeList
    }as FormControlTextModel)
    protocol: string = undefined;

    @FormControl({
        label: '物模板',
        type: FormControlType.SELECT,
        required: false,
        optionsPromise: CommonService.getTemplateOptions,
        readonly: true
    } as FormControlOptionModel)
    templateId: string = undefined;

    @FormControl({
        label: '设备类型',
        type: FormControlType.RADIO_GROUP,
        required: true,
        options: deviceTypeList,
        readonly: true
    } as FormControlOptionModel)
    type: DeviceTypeEnum = undefined;

    @FormControl({
        label: '关联产品类型',
        type: FormControlType.SELECT,
        optionsPromise: CommonService.getDeviceTypeOptions,
        // optionsPromiseParam: DeviceTypeEnum.DEVICE,
        mode: 'multiple',
        message: '若选择关联产品类型，则本产品类型的设备在关联子设备时，只可以选择属于关联产品类型的设备'
    } as FormControlOptionModel)
    supportedDeviceTypes: Array<string> = undefined;
    supportedDeviceTypeNames: string = undefined;

    /**
     * 获取是否网关显示文本
     */
    get DisplayGateway() {
        return this.type === DeviceTypeEnum.DEVICE ? '否' : '是';
    }
    get IsInnerGateway(): boolean {
        return this.type === DeviceTypeEnum.INNER_GATEWAY;
    }

    @FormControl({
        label: '描述',
        type: FormControlType.TEXT_AREA
    } as FormControlTextModel)
    remark: string = undefined;

    static getTableColumns() {
        return [
            {
                title: '产品名称',
                dataIndex: 'name',
                scopedSlots: { customRender: 'name' },
                width: 200
            },
            {
                title: '是否网关',
                dataIndex: 'DisplayGateway',
                width: 200
            },
            {
                title: '添加时间',
                dataIndex: 'createdTime',
                width: 200
            },
            {
                title: '描述',
                dataIndex: 'remark',
                width: 300
            },
            {
                title: '操作',
                dataIndex: 'action',
                scopedSlots: { customRender: 'action' },
                width: 220
            }
        ];
    }

    toModel(json): any {
        super.toModel(json);
        // if (this.type === DeviceTypeEnum.INNER_GATEWAY) {
        //     this.remark = `${this.remark || ''}(内置网关，不可删除，不可被下挂)`;
        // }
        // const supportedDeviceTypes = _.get(json, 'supportedDeviceTypes') || _.get(json, 'type.supportedDeviceTypes');
        // if (supportedDeviceTypes) {
        //     this.supportedDeviceTypes = (supportedDeviceTypes || '').split(',');
        // }
        this.supportedDeviceTypes = _.get(json, 'supportedDeviceTypes') || _.get(json, 'type.supportedDeviceTypes');
        return this;
    }

    toService() {
        let data: any = super.toService();
        data.templateId = this.templateId;
        data.groupId = this.groupId;
        // delete data.template.templateId;
        // delete data.template.groupId;
        // data.type = data.template;
        data = Object.assign({}, data, data.template);
        // data.type.type = this.isGateway ? DeviceTypeEnum.GATEWAY : DeviceTypeEnum.DEVICE;
        data.type = this.type;
        if (this.supportedDeviceTypes) {
            // data.type.supportedDeviceTypes = this.supportedDeviceTypes.join(',');
            data.supportedDeviceTypes = this.supportedDeviceTypes;
        }
        delete data.template;
        return data;
    }
}
export class ImportJsonEntityModel extends ThingsFunctionGroupModel {
    constructor(groupId?: string) {
        super(groupId, GroupEntityType.DEVICE_TYPE);
    }

    @FormControl({
        label: '上传',
        type: FormControlType.UPLOAD,
        accept: '.json',
        maxFileNumber: 1,
        notUploadOss: true,
        required: true
    } as FormControlUploadModel)
    jsonFile: Array<any> = undefined;

    toService() {
        const formData = new FormData();
        formData.append('file', _.get(this.jsonFile, '[0].file'));
        if (this.groupId) {
            formData.append('groupId', this.groupId);
        }
        return formData;
    }
}

export class DeviceTypeQueryModel extends ThingsTemplateQueryModel {

}
