import { AttributeType } from '@/model/enum';
const attributeTypeList: Array<{ name: string, value: AttributeType }> = _.filter(_.map(AttributeType, item => {
    let name: string = null;
    switch (item) {
        case AttributeType.CLIENT:
            name = '客户端';
            break;
        case AttributeType.SERVER:
            name = '服务端';
            break;
        case AttributeType.SHARE:
            name = '共享';
            break;
    }
    return { name, value: item };
}), item => item.name) as any;
const attributeTypeFilter = (val: AttributeType) => {
    return _.get(_.find(attributeTypeList, item => item.value === val), 'name') || '-';
};
export {
    attributeTypeFilter,
    attributeTypeList
};
