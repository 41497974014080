





















import { Component, Prop } from 'vue-property-decorator';
import { BaseComponent } from '@/mixins/base-component';

@Component
export default class JtlTreeComponent extends BaseComponent {
    @Prop()
    value: any;

    @Prop({ default: false })
    loading: any;

    @Prop({ default: () => { return []; } })
    treeData: Array<any>;

    @Prop({
        default: false
    })
    multiple: boolean;

    @Prop()
    expandedKeys: Array<string>;
    selectedKeys: Array<string> = null;

    get TreeValue() {
        return this.value;
    }

    set TreeValue(val: any) {
        // selectChange
    }

    selectChange(val, label, extra) {
        this.$emit('input', val);
        this.$emit('change', _.get(extra, 'triggerNode.dataRef'));
    }
}

