import { Component, Prop } from 'vue-property-decorator';
import { BaseComponent } from './base-component';
import { SimpleTreeModel } from '@/model/simple-tree-model';

@Component
export class SelectTreeComponent extends BaseComponent {
    @Prop()
    value: string | Array<string>;

    @Prop()
    disabled: boolean;

    @Prop({ default: () => { return []; } })
    treeData: Array<any>;

    @Prop({ default: false })
    hasChildId: boolean;

    loading: boolean = false;
    options: Array<any> = [];
    // selectId: string = null;

    get selectId() {
        return Array.isArray(this.value) ? _.get(this.value, '[0]') : this.value;
    }
    set selectId(val: any) {
        // this.value = val;
        this.$emit('input', val);
    }

    // mounted() {
    //     this.setSelectId();
    // }

    setSelectId() {
        this.selectId = Array.isArray(this.value) ? _.get(this.value, '[0]') : this.value;
    }

    selectChange(val, label, extra) {
        if (this.hasChildId) {
            if (val) {
                this.$emit('input', this.getSelectGroupIds(_.get(extra, 'triggerNode.dataRef')));
            } else {
                this.$emit('input', undefined);
            }
        } else {
            if (val) {
                this.$emit('input', val);
            } else {
                this.$emit('input', undefined);
            }
        }
        this.$emit('change', _.get(extra, 'triggerNode.dataRef'));
    }

    getSelectGroupIds(treeItem: SimpleTreeModel) {
        return _.flattenDeep(this.getChildGroupId(treeItem));
    }

    getChildGroupId(treeItem: SimpleTreeModel) {
        const ids = [];
        if (treeItem) {
            ids.push(treeItem.id);
            if (treeItem.children && treeItem.children.length > 0) {
                ids.push(..._.map(treeItem.children, item => {
                    return this.getChildGroupId(item);
                }));
            }
        }
        return ids;
    }
}
