

















import { Component } from 'vue-property-decorator';
import FormDialogComponent from '@/mixins/form-dialog-component';
import { DataParamModel } from '@/entity-model/data-param-entity';
import { ViewModeType } from '@/model/enum';

@Component
export default class DataParamEditComponent extends FormDialogComponent<DataParamModel> {
    newOrUpdate: ViewModeType;
    editIndex: number;
    /**
     * 打开弹窗
     * @param model 数据模型
     * @param isNew 新增/编辑
     */
    dialogOpenClick(model: DataParamModel, newOrUpdate: ViewModeType, index?: number): void {
        this.dialogVisible = true;
        this.newOrUpdate = newOrUpdate;
        this.editIndex = index;
        this.$nextTick(() => {
            this.jtlForm.initForm(model, newOrUpdate);
            this.dialogTitle = newOrUpdate === ViewModeType.NEW ? '添加参数' : '编辑参数';
        });
    }

    /**
     * 点击弹窗保存事件
     * 提交表单数据
     */
    async dialogOKClick() {
        if (!await this.jtlForm.validate()) {
            this.showMessageError('您提交的数据有问题');
            throw new Error('Form error');
        }
        this.$emit('dialogOK', {
            dataParam: this.jtlForm.formModel,
            newOrUpdate: this.newOrUpdate,
            index: this.editIndex
        });
        this.dialogVisible = false;
    }
}
