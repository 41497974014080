import { UserStoreModule } from '@/store/modules/user';
import CommonService from '@/service/common';

export function beforeEnter(to, from, next) {
    if (!UserStoreModule.IsAdmin) {
        // 无平台权限
        UserStoreModule.SET_HAS_ERROR(true);
        console.warn(`from ${from.fullPath} to ${to.fullPath} has no corp permission`);
        // 跳转到无权限访问
        next('/sign/deny');
        return;
    }
    next();
    // CommonService.getUserInfo(5000).then(() => {
    //     next();
    // }).catch(error => {
    //     UserStoreModule.SET_HAS_ERROR(true);
    //     UserStoreModule.SET_REDIRECT_PATH(to.fullPath);
    //     $log.error(error);
    //     next('/sign/error');
    // }).finally(() => {
    //     // $eventHub.$emit('STOP_FULL_SCREEN_LOADING');
    // });
}

export default [
    {
        path: '/admin',
        meta: { authGuard: true },
        beforeEnter,
        component: () => import('@/pages/dashboard/dashboard.vue'),
        children: [
            {
                path: 'corp-list',
                component: () => import('@/pages/admin-dashboard/corp-list.vue'),
                meta: { parentMenu: 'admin' }
            },
            {
                path: 'permission-list',
                component: () => import('@/pages/admin-dashboard/permission-list.vue'),
                meta: { parentMenu: 'admin' }
            }
        ]
    }
];
