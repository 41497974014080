
import { DeviceTypeEntityModel, DeviceTypeQueryModel, ImportJsonEntityModel } from '@/entity-model/device-type-entity';
import { SelectDeviceModel } from '@/entity-model/device-entity';
import { ICRUDQ } from '@/model/interface';
import { get, post, del, download } from './request';

const URL_PATH = `${BI_BASE_REQUEST_PATH}/deviceType`;

class DeviceTypeService implements ICRUDQ<DeviceTypeEntityModel, DeviceTypeQueryModel> {
    async create(model: DeviceTypeEntityModel):Promise<DeviceTypeEntityModel> {
        const url = `${URL_PATH}/save`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async retrieve(modelId: string):Promise<DeviceTypeEntityModel> {
        const url = `${URL_PATH}/detail/${modelId}`;
        const res = await get(url);
        return new DeviceTypeEntityModel().toModel(res);
    }

    async update(model: DeviceTypeEntityModel):Promise<DeviceTypeEntityModel> {
        const url = `${URL_PATH}/save`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async delete(model: DeviceTypeEntityModel):Promise<DeviceTypeEntityModel> {
        const url = `${URL_PATH}/delete/${model.id}`;
        const res = await del(url);
        return res;
    }

    async query(query: DeviceTypeQueryModel, page?: number, limit?: number):Promise<{ total: number, items: Array<DeviceTypeEntityModel> }> {
        const url = `${URL_PATH}/list`;
        const params = Object.assign({ page, limit }, query?.toService());
        const res = await post(url, params);
        res.items = _.map(res.items, item => item = new DeviceTypeEntityModel().toModel(item));
        return res;
    }

    async addFeature(params):Promise<any> {
        const url = `${URL_PATH}/addFeature`;
        const res = await post(url, params);
        return res;
    }

    // async deleteFeature(model: {id: string}, deviceTypeId: string):Promise<any> {
    //     const url = `${URL_PATH}/delFeature/${model.id}/${deviceTypeId}`;
    //     const res = await del(url);
    //     return res;
    // }

    async getDeviceTypeForLinkDevice(parentId: string):Promise<any> {
        const res = await get(`${URL_PATH}/getTypesForLinkDevice/${parentId}`);
        // let deviceTypeList = _.get(res, 'supportedTypeIdList');
        // if (!deviceTypeList || deviceTypeList.length === 0) {
        //     deviceTypeList = _.get(res, 'deviceTypeList');
        // }
        // return _.map(deviceTypeList, item => {
        //     return {
        //         name: item.name,
        //         value: item.id,
        //         type: item.type
        //     };
        // });
        return res;
    }

    async getDeviceTypeAttributeList():Promise<Array<any>> {
        const url = `${URL_PATH}/getDeviceTypeAttrList`;
        const res = await get(url);
        return _.map(_.filter(res, item => item.values && item.values.length > 0), item => new SelectDeviceModel().toModel(item));
    }
    async importDeviceTypesByJsonFile(importModel:any) {
        const url = `${URL_PATH}/importDeviceTypesByJsonFile`;
        const params = importModel.toService();
        const res = await post(url, params, { headers: { 'content-type': 'multipart/form-data' }, timeout: 600000 });
        return res;
    }

    async exportDeviceTypeJson(exportIds:any) {
        const url = `${URL_PATH}/exportDeviceTypeJson`;
        const res = await post(url, exportIds);
        return res;
    }

    async getDeviceTypeForFacilityType(query: DeviceTypeQueryModel, page?: number, limit?: number):Promise<{ total: number, items: Array<DeviceTypeEntityModel>}> {
        const url = `${URL_PATH}/facilityTypeRelationList`;
        const params = Object.assign({ page, limit }, query?.toService());
        const res = await post(url, params);
        res.items = _.map(res.items, item => item = new DeviceTypeEntityModel().toModel(item));
        return res;
    }

    async syncDeviceTypeAttribute(deviceTypeId: string):Promise<any> {
        const url = `${URL_PATH}/attrs/sync/${deviceTypeId}`;
        const res = await post(url);
        return res;
    }
}

export default new DeviceTypeService();
