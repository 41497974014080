import { Component } from 'vue-property-decorator';
import TableComponent from './table-component';
import { GroupEntityType } from '@/model/enum';

@Component
export default class TransformComponent extends TableComponent<any, any> {
    GroupEntityType = GroupEntityType;
    dialogVisible: boolean = false;
    rightDataList: Array<any> = null;

    get AllCheck() {
        if (!this.listData || this.listData.length === 0) {
            return false;
        }
        return _.filter(this.listData, item => item.check).length === this.listData.length;
    }

    set AllCheck(val: boolean) {
        _.forEach(this.listData, item => {
            item.check = val;
            if (val) {
                const index = _.findIndex(this.rightDataList, rightItem => rightItem.id === item.id);
                if (index === -1) {
                    this.rightDataList.push(item);
                }
            }
        });
        if (!val) {
            this.rightDataList = [];
        }
    }

    checkOnChange(model: any) {
        if (model.check) {
            this.rightDataList.push({ id: model.id, code: model.code, name: model.displayName || model.name, deviceTypeId: this.queryModel?.typeId });
        } else {
            const index = _.findIndex(this.rightDataList, item => item.id === model.id);
            if (index > -1) {
                this.rightDataList.splice(index, 1);
            }
        }
    }

    removeItemClick(model, index) {
        model.check = false;
        const listDataItem = _.find(this.listData, item => item.id === model.id);
        if (listDataItem) {
            listDataItem.check = false;
        }
        this.rightDataList.splice(index, 1);
    }

    removeAllClick() {
        this.rightDataList = [];
        _.forEach(this.listData, item => {
            item.check = false;
        });
    }
}
