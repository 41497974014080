


















import { Component, Prop } from 'vue-property-decorator';
import { BaseComponent } from '@/mixins/base-component';
import VueQr from 'vue-qr';
import html2canvas from 'html2canvas';
import { UserStoreModule } from '@/store/modules/user';
const SZZX_LOGO = require('../../../public/img/szzxlogo.png');

@Component({
    components: {
        'vue-qr': VueQr
    }
})
export default class JTLQrcodeComponent extends BaseComponent {
    get projectName(): string {
        return UserStoreModule.CorpName;
    }

    get qrLogo(): string {
    // return AppStoreModule.ProjectLogo;
        return SZZX_LOGO;
    }

    private qrCodeImgArr:any;
    private num:number=0;
    public success: boolean = false;
    @Prop()
    qrInfo: {
        id: string,
        name: string,
        code: string
    };

    @Prop()
    qrSize: 200;

    @Prop()
    qrLogoSize: 40;

    @Prop()
    qrTextSize: 14;

    texte() {
        // 为了解决下载半个二维码
        setTimeout(() => {
            this.handleQrcodeToDataUrl().then(res => {
                this.qrCodeImgArr = res;
                this.success = true;
                // this.download();
            });
        }, 0);
    }

    download() {
        // 创建一个a链接
        var aTag = document.createElement('a');
        // 给下载文件命名
        aTag.download = `${this.qrInfo.name}.png`;
        // 创建下载
        aTag.href = this.qrCodeImgArr.url;
        // 开始下载
        aTag.click();
    }

    getImgUrl() {
        return this.qrCodeImgArr;
    }

    handleQrcodeToDataUrl() {
        const width = 300;
        const height = 320;
        window.scroll(0, 0); // 首先先顶部
        const targetDom = this.$el; // 获取要截图的元素
        const copyDom: any = targetDom.cloneNode(true); // 克隆一个
        copyDom.style.width = width + 'px';
        copyDom.style.height = height + 'px';
        copyDom.style.position = 'absolute';
        copyDom.style.left = '-9999px';
        copyDom.style.top = '-9999px';
        document.body.appendChild(copyDom); // 添加
        let option = {
            width: width,
            height: height,
            backgroundColor: '#ffffff',
            scale: window.devicePixelRatio * 1,
            useCORS: true
        };
        return html2canvas(copyDom, option).then((canvas) => {
            document.body.removeChild(copyDom);
            let dataUrl = canvas.toDataURL();
            return {
                url: dataUrl,
                qrInfo: this.qrInfo
            };
        });
    }
}

