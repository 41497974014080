




import { Component } from 'vue-property-decorator';
import TopoBaseView from '../topo-base-view';
import CUSTOM_CHART_OPTIONS from '@topo/assets/data/chart-gauge-custom';

@Component
export default class ViewChart extends TopoBaseView {
    get ChartOptions() {
        return this.ComponentData || CUSTOM_CHART_OPTIONS;
    }

    // mounted() {
    //     if (this.editMode) {
    //         if (this.interval) {
    //             clearInterval(this.interval);
    //             this.interval = null;
    //         }
    //         this.interval = setInterval(() => {
    //             if (!_.get(this.component, 'data.componentData')) {
    //                 _.set(this.component.data, 'componentData', CUSTOM_CHART_OPTIONS);
    //             }
    //             var nextSource = [[1, Math.round(Math.random() * 200)]];
    //             // this.component.data.componentData.dataset.source = nextSource;
    //             this.$set(this.component.data.componentData.dataset, 'source', nextSource);
    //             // this.$forceUpdate();
    //         }, 3000);
    //     }
    // }
}
