















import { Component, Prop } from 'vue-property-decorator';
import { BaseComponent } from '@/mixins/base-component';

const HTTP_METHOD_LIST = ['GET', 'HEAD', 'POST', 'PUT', 'PATCH', 'DELETE', 'OPTIONS', 'TRACE'];

@Component
export default class ResourceUrlFormComponent extends BaseComponent {
    @Prop()
    value: Array<{ httpMethod: string, url: string }>;
    @Prop()
    disabled: boolean;
    get HttpMethodList() {
        return HTTP_METHOD_LIST;
    }
    addClick() {
        this.value.push({ httpMethod: 'GET', url: '' });
    }
    deleteClick(index: number) {
        this.value.splice(index, 1);
    }
}

