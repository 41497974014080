import { BaseModel } from '@/model/base-model';
import { ThingsModelType, ReadWriteEnum } from '../model/enum';
import { DataParamModel } from './data-param-entity';

export class ThingsModelTypeModel extends BaseModel {
    constructor() {
        super();
        this.thingsModelType = ThingsModelType.STRING;
        this.enumList = [{ name: '', value: '' }];
    }

    thingsModelType: ThingsModelType = undefined;
    dataMin: number = -2147483648;
    dataMax: number = 2147483648;
    dataTrueName: string = undefined;
    dataFalseName: string = undefined;
    dataString: number = 10240;
    step: number = 1;
    unit: string = undefined;
    enumList: Array<{ name: string, value: string }> = [];
    jsonParams: Array<DataParamModel> = undefined;
    value: any = undefined;

    toModel(json): any {
        this.thingsModelType = _.get(json, 'type');
        this.value = _.get(json, 'value');
        switch (this.thingsModelType) {
            case ThingsModelType.STRING:
            case ThingsModelType.PASSWORD:
            case ThingsModelType.TEXT:
                this.dataString = _.get(json, 'specs.length');
                break;
            case ThingsModelType.INT:
            case ThingsModelType.FLOAT:
            case ThingsModelType.DOUBLE:
                this.dataMin = parseFloat(_.get(json, 'specs.min'));
                this.dataMax = parseFloat(_.get(json, 'specs.max'));
                this.unit = _.get(json, 'unit');
                this.step = parseFloat(_.get(json, 'specs.step'));
                break;
            case ThingsModelType.BOOLEAN:
                this.dataFalseName = _.get(json, 'specs[0]');
                this.dataTrueName = _.get(json, 'specs[1]');
                break;
            case ThingsModelType.ENUM:
                {
                    const enumObj = _.get(json, 'specs');
                    this.enumList = _.map(_.keysIn(enumObj), key => {
                        return {
                            name: enumObj[key],
                            value: key
                        };
                    });
                }
                break;
            case ThingsModelType.JSON:
                this.jsonParams = _.map(_.get(json, 'specs'), item => new DataParamModel().toModel(item));
                break;
            default:
                break;
        }
        return this;
    }

    toService() {
        const data = {
            type: this.thingsModelType,
            specs: this.toSpecs(),
            unit: this.unit,
            value: null
        };
        switch (this.thingsModelType) {
            case ThingsModelType.INT:
            case ThingsModelType.FLOAT:
            case ThingsModelType.DOUBLE:
                data.value = parseFloat(this.value) || 0;
                break;
            case ThingsModelType.BOOLEAN:
                data.value = !!this.value;
                break;
            case ThingsModelType.JSON:
                data.value = this.value || '{}';
                break;
            case ThingsModelType.STRING:
            case ThingsModelType.PASSWORD:
            case ThingsModelType.TEXT:
            default:
                data.value = this.value || '';
                break;
        }
        return data;
    }

    toSpecs() {
        let data: any = {};

        switch (this.thingsModelType) {
            case ThingsModelType.STRING:
            case ThingsModelType.PASSWORD:
            case ThingsModelType.TEXT:
                data.length = this.dataString;
                break;
            case ThingsModelType.INT:
            case ThingsModelType.FLOAT:
            case ThingsModelType.DOUBLE:
                data.min = this.dataMin;
                data.max = this.dataMax;
                data.step = this.step;
                data.unit = this.unit;
                break;
            case ThingsModelType.BOOLEAN:
                data['0'] = this.dataFalseName;
                data['1'] = this.dataTrueName;
                break;
            case ThingsModelType.ENUM:
                data = {};
                _.forEach(this.enumList, item => {
                    data[item.value] = item.name;
                });
                break;
            case ThingsModelType.JSON:
                data = _.map(this.jsonParams, item => item.toService());
                break;
            default:
                break;
        }
        return data;
    }
}
