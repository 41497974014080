var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "v-charts-component-loading" }, [
    _c("div", { staticClass: "loader" }, [
      _c("div", { staticClass: "loading-spinner" }, [
        _c(
          "svg",
          { staticClass: "circular", attrs: { viewBox: "25 25 50 50" } },
          [
            _c("circle", {
              staticClass: "path",
              attrs: { cx: "50", cy: "50", r: "20", fill: "none" }
            })
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }