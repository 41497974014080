
import { FacilityTypeEntityModel, FacilityTypeQueryModel } from '@/entity-model/facility-type-entity';
import { ICRUDQ } from '@/model/interface';
import { get, post, del } from './request';
import { FacilityType } from '@/model/enum';

const URL_PATH = `${BI_BASE_REQUEST_PATH}/facilitytype`;

class FacilityTypeService implements ICRUDQ<FacilityTypeEntityModel, FacilityTypeQueryModel> {
    async create(model: FacilityTypeEntityModel):Promise<FacilityTypeEntityModel> {
        const url = `${URL_PATH}/save`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async retrieve(modelId: string):Promise<FacilityTypeEntityModel> {
        const url = `${URL_PATH}/detail/${modelId}`;
        const res = await get(url);
        return new FacilityTypeEntityModel().toModel(res);
    }

    async update(model: FacilityTypeEntityModel):Promise<FacilityTypeEntityModel> {
        const url = `${URL_PATH}/save`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async delete(model: FacilityTypeEntityModel):Promise<FacilityTypeEntityModel> {
        const url = `${URL_PATH}/delete/${model.id}`;
        const res = await del(url);
        return res;
    }

    async query(query?: FacilityTypeQueryModel, page?: number, limit?: number):Promise<any> {
        const url = `${URL_PATH}/list`;
        const params = Object.assign({ page, limit }, query?.toService());
        const res = await post(url, params);
        res.items = _.map(res.items, item => item = new FacilityTypeEntityModel().toModel(item));
        return res;
    }

    async links(facilityType: FacilityType, facilityTypeIds: Array<string>):Promise<any> {
        const url = `${URL_PATH}/saveModuleSupportTypes/${facilityType}`;
        return await post(url, facilityTypeIds);
    }

    async unlink(facilityType: FacilityType, id: string):Promise<any> {
        // const url = `${URL_PATH}/deleteModuleSupportType/${facilityType}`;
        // return await post(url, `"${id}"`);// api中参数仅支持这种写法
        const url = `${URL_PATH}/deleteModuleSupportType/${id}`;
        return await del(url);// api中参数仅支持这种写法
    }

    async getDeviceListByFacilityType(facilityTypeId: string):Promise<Array<any>> {
        if (!facilityTypeId) {
            return;
        }

        const url = `${URL_PATH}/getDeviceList/${facilityTypeId}`;
        const res = await get(url, null, { cache: 10000 });
        return _.map(res, item => {
            return {
                name: _.get(item, 'displayName'),
                value: _.get(item, 'id'),
                remark: _.get(item, 'remark')
            };
        });
    }

    // async batchAddLabel(params) {
    //     const url = '';
    //     const res = post(url, params);
    //     return res;
    // }

    // async batchDeleteLabel(ids: Array<string>) {
    //     const url = ` `;
    //     const params = {
    //         list: ids
    //     };
    //     const res = await post(url, params);
    //     return res;
    // }

    async batchSave(groupId: string, deviceTypeIds: Array<string>):Promise<any> {
        const url = `${URL_PATH}/batchSave`;
        return await post(url, { groupId, deviceTypeIds });
    }
}

export default new FacilityTypeService();
