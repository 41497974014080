
















import { Component, Vue, Prop, Watch, Ref } from 'vue-property-decorator';
import { BaseComponent } from '@/mixins/base-component';
import LinkageDeviceDialog from './linkage-device-dialog.vue';
import { AttributeEntityType } from '@/model/enum';

@Component({
    components: {
        'linkage-device-dialog': LinkageDeviceDialog
    }
})
export default class LinkageDeviceComponent extends BaseComponent {
    @Prop()
    value: Array<any>;
    @Prop()
    disabled: boolean;
    @Prop()
    groupId: string;
    @Prop()
    linkageType: AttributeEntityType;
    @Prop()
    linkageId: string;

    selectDeviceClick() {
        if (this.groupId) {
            (this.$refs.linkageDeviceDialog as LinkageDeviceDialog).dialogOpen(this.linkageId, this.linkageType, this.groupId, this.value);
        } else {
            this.showMessageWarning('请先选择分组');
        }
    }
    linkageDeviceChange(value) {
        this.$emit('input', value);
    }
    validate() {
        // for (let index = 0; index < this.value.length; index++) {
        //     if (!this.value[index]) {
        //         this.showMessageWarning('请选择计划时间');
        //         return false;
        //     }
        // }
        return true;
    }
}

