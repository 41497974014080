
































































import { Component, Prop } from 'vue-property-decorator';
import { BaseComponent } from '@/mixins/base-component';
import DeviceService from '@/service/device';
import { AttributeConfig } from '@/model/attribute-config';
import { SelectDeviceModel } from '@/entity-model/device-entity';
import { FormControlDeviceType } from '@/model/form-control';

@Component
export default class SelectDeviceAttributeTableComponent extends BaseComponent {
    tableColumns: Array<any> = null;
    allDeviceAttributeList: Array<SelectDeviceModel> = null;
    allDeviceTree: Array<any> = null;
    FormControlDeviceType = FormControlDeviceType;
    sourceLoading: boolean = false;

    @Prop()
    value: any;

    @Prop()
    deviceType: FormControlDeviceType;

    @Prop()
    hasAddOrDelete: boolean;

    @Prop()
    isPxh: boolean;

    created() {
        this.tableColumns = AttributeConfig.getTableColumns(_.get(this.value, 'length'), this.hasAddOrDelete);
        this.initSelectSource();
    }

    get TableData() {
        return this.value;
    }

    initSelectSource() {
        if (this.deviceType === FormControlDeviceType.DEVICE_ATTRIBUTE) {
            this.sourceLoading = true;
            DeviceService.getAllDeviceAttributes().then(res => {
                this.allDeviceAttributeList = res;
            }).catch(err => {
                $log.error(err);
            }).finally(() => {
                this.sourceLoading = false;
            });
        } else if (this.deviceType === FormControlDeviceType.DEVICE_TREE) {
            this.sourceLoading = true;
            DeviceService.getDeviceTree().then(res => {
                this.allDeviceTree = res;
            }).catch(err => {
                $log.error(err);
            }).finally(() => {
                this.sourceLoading = false;
            });
        }
    }

    validate() {
        for (let index = 0; index < this.value.length; index++) {
            const item = this.value[index];
            if (!item.name && this.hasAddOrDelete) {
                this.showMessageWarning('请输入属性名称');
                return false;
            }
            if (item.hasDevice && !item.deviceId) {
                this.showMessageWarning('请选择关联设备');
                return false;
            }
        }
        return true;
    }

    handleAttributeSearch(value) {
        const trimValue = _.toLower(_.trim(value));
        if (trimValue) {
            _.forEach(_.flatMap(this.allDeviceAttributeList, 'attributes'), item => {
                item.visible = _.toLower(item.key).indexOf(trimValue) > -1;
            });
        } else {
            _.forEach(_.flatMap(this.allDeviceAttributeList, 'attributes'), item => {
                item.visible = true;
            });
        }
    }

    handleDeviceSearch(value) {
        const trimValue = _.toLower(_.trim(value));
        if (trimValue) {
            _.forEach(_.flatMap(this.allDeviceAttributeList, 'attributes'), item => {
                item.visible = _.toLower(item.key).indexOf(trimValue) > -1;
            });
        } else {
            _.forEach(_.flatMap(this.allDeviceAttributeList, 'attributes'), item => {
                item.visible = true;
            });
        }
    }

    deleteClick(record) {
        this.$emit('delete', this.value.indexOf(record));
    }
}

