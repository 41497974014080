var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "form-enum-component" },
    [
      _c(
        "a-row",
        [
          _c("a-col", { attrs: { span: 11 } }, [_vm._v("参数值")]),
          _c("a-col", { attrs: { span: 2 } }),
          _c("a-col", { attrs: { span: 11 } }, [_vm._v("参数描述")])
        ],
        1
      ),
      _vm._l(_vm.value, function(item, index) {
        return _c(
          "a-row",
          { key: index },
          [
            _c(
              "a-col",
              { attrs: { span: 10 } },
              [
                _c("a-input", {
                  attrs: {
                    disabled: _vm.disabled,
                    placeholder: "编号如'0'",
                    "allow-clear": ""
                  },
                  model: {
                    value: item.value,
                    callback: function($$v) {
                      _vm.$set(item, "value", $$v)
                    },
                    expression: "item.value"
                  }
                })
              ],
              1
            ),
            _c("a-col", { staticClass: "text-center", attrs: { span: 2 } }, [
              _vm._v("~")
            ]),
            _c(
              "a-col",
              { attrs: { span: 10 } },
              [
                _c("a-input", {
                  attrs: {
                    disabled: _vm.disabled,
                    placeholder: "对该枚举项的描述",
                    "allow-clear": ""
                  },
                  model: {
                    value: item.name,
                    callback: function($$v) {
                      _vm.$set(item, "name", $$v)
                    },
                    expression: "item.name"
                  }
                })
              ],
              1
            ),
            _c("a-col", { staticClass: "text-center", attrs: { span: 2 } }, [
              index > 0
                ? _c(
                    "a",
                    {
                      staticClass: "jtl-link enum-remove-link",
                      on: {
                        click: function($event) {
                          return _vm.removeEnumClick(index)
                        }
                      }
                    },
                    [_vm._v(" 删除")]
                  )
                : _vm._e()
            ])
          ],
          1
        )
      }),
      _c(
        "a",
        {
          staticClass: "jtl-link",
          on: {
            click: function($event) {
              return _vm.addEnumClick()
            }
          }
        },
        [_vm._v("+增加枚举项")]
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }