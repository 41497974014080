





























import TopoStyleLineModel from '@/pages/topo/model/style-model/topo-style-line-model';
import { Component } from 'vue-property-decorator';
import ViewCanvas from './view-canvas';

@Component
export default class ViewBizierCurveArrow extends ViewCanvas {
    lineWidth = 2;
    flag = false;
    passItem: any = {};
    points = []; // 控制点（包含起始和终点）
    FACTOR_H = 5; // 箭头 水平高度倍数
    FACTOR_V = 4; // 箭头 垂直长度倍数
    get Style(): TopoStyleLineModel {
        return this.component.style as TopoStyleLineModel;
    }
    mounted() {
        var lineWidth = this.Style.lineWidth;
        if (lineWidth === undefined) {
            lineWidth = 2;
        } else if (typeof lineWidth === 'string') {
            lineWidth = parseInt(lineWidth);
        }
        this.points = this.Style.points;
        // 增加2个中间节点，应该可以动态控制，这里暂时写死
        this.onResize();
    }
    drawLine(ctx) {
        var lineWidth = this.lineWidth;
        var color = this.getForeColor();
        ctx.beginPath();
        ctx.moveTo(this.points[0].x, this.points[0].y);
        ctx.bezierCurveTo(
            this.points[1].x,
            this.points[1].y,
            this.points[2].x,
            this.points[2].y,
            this.points[3].x,
            this.points[3].y);
        ctx.lineWidth = lineWidth; // 设置线宽状态
        ctx.strokeStyle = color; // 设置线的颜色状态
        ctx.stroke(); // 进行绘制
        ctx.closePath();
    }
    reDraw() {
        var w = this.Style.position.w;
        var h = this.Style.position.h;
        var el: any = this.$refs.elCanvas;
        var ctx = el.getContext('2d');
        ctx.clearRect(0, 0, w, h);
        this.drawLine(ctx);
    }
    onResize() {
        this.$nextTick(() => {
            this.reDraw();
        });
    }
    aroowPassDown(pass, event, index) {
        this.flag = true;
        pass.startX = event.pageX;
        pass.startY = event.pageY;
        pass.temp = {};
        pass.temp.x = pass.x;
        pass.temp.y = pass.y;
        this.passItem = pass;
    }
    onMousemove(event) {
        if (!this.flag) { return; }
        event.cancelBubble = true;
        var dx = event.pageX - this.passItem.startX;
        var dy = event.pageY - this.passItem.startY;
        this.passItem.x = this.passItem.temp.x + dx;
        this.passItem.y = this.passItem.temp.y + dy;
        this.reDraw();
    }
    onMouseUp(event) {
        this.flag = false;
    }
}
