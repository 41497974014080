var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "jtl-tree-professional-component" },
    [
      _c(
        "a-spin",
        { attrs: { spinning: _vm.treeLoading } },
        [
          _c(
            "a-tree",
            {
              staticClass: "jtl-tree",
              attrs: {
                "expanded-keys": _vm.expandedKeys,
                "selected-keys": _vm.selectedKeys,
                "tree-data-simple-mode": "",
                "tree-data": _vm.treeData
              },
              on: {
                "update:expandedKeys": function($event) {
                  _vm.expandedKeys = $event
                },
                "update:expanded-keys": function($event) {
                  _vm.expandedKeys = $event
                },
                "update:selectedKeys": function($event) {
                  _vm.selectedKeys = $event
                },
                "update:selected-keys": function($event) {
                  _vm.selectedKeys = $event
                },
                select: _vm.treeSelect
              },
              scopedSlots: _vm._u([
                {
                  key: "custom",
                  fn: function(item) {
                    return [
                      _c(
                        "jtl-tooltip-component",
                        { attrs: { title: item.title, placement: "right" } },
                        [_c("span", [_vm._v(_vm._s(item.title))])]
                      )
                    ]
                  }
                }
              ])
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.item.isEdit,
                      expression: "!item.isEdit"
                    }
                  ],
                  staticClass: "tree-item-action-wrapper"
                },
                [
                  _c(
                    "a",
                    {
                      staticClass: "jtl-link",
                      on: {
                        click: function($event) {
                          return _vm.addClick(_vm.item)
                        }
                      }
                    },
                    [_vm._v("添加")]
                  ),
                  _c("a-divider", { attrs: { type: "vertical" } }),
                  _c(
                    "a",
                    {
                      staticClass: "jtl-edit-link",
                      on: {
                        click: function($event) {
                          return _vm.editClick(_vm.item)
                        }
                      }
                    },
                    [_vm._v("编辑")]
                  ),
                  _c("a-divider", { attrs: { type: "vertical" } }),
                  _c(
                    "a-popconfirm",
                    {
                      attrs: {
                        title: "确认删除?",
                        "ok-text": "确认",
                        "cancel-text": "取消"
                      },
                      on: {
                        confirm: function($event) {
                          return _vm.deleteClick(_vm.item)
                        }
                      }
                    },
                    [_c("a", { staticClass: "jtl-del-link" }, [_vm._v("删除")])]
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }