






import { Component } from 'vue-property-decorator';
import ViewCanvas from './view-canvas';

@Component
export default class ViewCircular extends ViewCanvas {
    drawCircular(x, y, r, start, end, color, type) {
        var el: any = this.$refs.elCanvas;
        var ctx = el.getContext('2d');
        var unit = Math.PI / 180;
        ctx.beginPath();
        ctx.arc(x, y, r, start * unit, end * unit);
        // ctx.arc(100, 100, 100, 0, 2 * Math.PI);
        ctx[type + 'Style'] = color;
        ctx.closePath();
        ctx[type]();
    }
    onResize() {
        var w = this.component.style.position.w as number;
        var h = this.component.style.position.h as number;
        var el: any = this.$refs.elCanvas;
        var ctx = el.getContext('2d');
        ctx.clearRect(0, 0, w, h);
        var x = w / 2;
        var y = h / 2;
        var r = ((w / 2) > (h / 2)) ? (h / 2) : (w / 2);
        var color = this.getForeColor();
        // this.drawCircular(0, 0, 0, 0, 360, color, 'fill');
        this.drawCircular(x, y, r - 2, 0, 360, color, 'fill');
    }
}
