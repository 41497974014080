import LinearGradient from 'zrender/esm/graphic/LinearGradient';
var panelBgImageURL = '/topo/szzx/circle-bg-1.png';

var animationDuration = 1000;
var animationDurationUpdate = 1000;
var animationEasingUpdate = 'quarticInOut';

var outerRadius = 57.5;
var ringRadius = 46;
var ringWidth = 4;
const CIRCLE_IMAGE_URLS = ['/topo/szzx/circle-bg-1.png', '/topo/szzx/circle-bg-2.png', '/topo/szzx/circle-bg-3.png'];
const RING_FILL_COLORS = [
    [
        { offset: 0, color: '#78C9B7' },
        { offset: 1, color: 'rgba(93, 211, 179, 0.1)' }
    ],
    [
        { offset: 0, color: '#4196E1' },
        { offset: 1, color: 'rgba(70, 136, 235, 0.1)' }
    ],
    [
        { offset: 0, color: '#E7636C' },
        { offset: 1, color: 'rgba(255, 76, 76, 0.1)' }
    ]
];

export function getParkingIndex(valOnRadian) {
    let index = 0;
    if (valOnRadian >= 90) {
        index = 2;
    } else if (valOnRadian > 60 && valOnRadian < 90) {
        index = 1;
    } else {
        index = 0;
    }
    return index;
}

// function convertToPolarPoint(renderItemParams, radiusX, radiusY, radian) {
//     return [
//         Math.cos(radian) * radiusX + renderItemParams.coordSys.cx,
//         -Math.sin(radian) * radiusY + renderItemParams.coordSys.cy
//     ];
// }

function renderItem(params, api) {
    var valOnRadian = api.value(1);
    var coords = api.coord([api.value(0), valOnRadian]);
    var polarEndRadian = coords[3];

    return {
        type: 'group',
        children: [{
            type: 'image',
            extra: {
                valOnRadian: valOnRadian,
                transition: 'valOnRadian',
                enterFrom: { valOnRadian: 0 }
            },
            style: {
                image: panelBgImageURL,
                x: params.coordSys.cx - outerRadius,
                y: params.coordSys.cy - outerRadius,
                width: outerRadius * 2,
                height: outerRadius * 2
            },
            during: function(apiDuring) {
                const valOnRadian = apiDuring.getExtra('valOnRadian');
                apiDuring.setStyle('image', CIRCLE_IMAGE_URLS[getParkingIndex(valOnRadian)]);
            }
        },
        {
            type: 'ring',
            style: {
                x: params.coordSys.cx - outerRadius,
                y: params.coordSys.cy - outerRadius,
                fill: '#1d2b3a'
            },
            shape: {
                cx: params.coordSys.cx,
                cy: params.coordSys.cy,
                r: ringRadius,
                r0: ringRadius - ringWidth
            }
        },
        {
            type: 'rect',
            shape: {
                x: outerRadius - ringWidth / 2,
                y: outerRadius - ringRadius,
                width: ringWidth / 2,
                height: ringWidth
            },
            style: {
                fill: '#0b1826'
            }
        },
        // {
        //     type: 'rect',
        //     shape: {
        //         x: outerRadius - ringWidth / 2,
        //         y: outerRadius - ringRadius,
        //         width: ringWidth / 2,
        //         height: ringWidth
        //     },
        //     // rotation: -Math.PI / 4,
        //     // origin: [outerRadius, outerRadius],
        //     style: {
        //         fill: '#ff0000'
        //     },
        //     extra: {
        //         polarEndRadian: polarEndRadian,
        //         transition: 'polarEndRadian',
        //         enterFrom: { polarEndRadian: -Math.PI / 2 }
        //     },
        //     during: function(apiDuring) {
        //         const polarEndRadian = apiDuring.getExtra('polarEndRadian') + Math.PI / 2;
        //         let rectPositionXRadius = null;
        //         let rectPositionYRadius = null;
        //         if (polarEndRadian < 0 && polarEndRadian > -Math.PI) {
        //             rectPositionXRadius = ringRadius - ringWidth;
        //             rectPositionYRadius = ringRadius - ringWidth;
        //         } else {
        //             rectPositionXRadius = ringRadius;
        //             rectPositionYRadius = ringRadius;
        //         }
        //         apiDuring.setShape(
        //             'x',
        //             convertToPolarPoint(params, rectPositionXRadius, rectPositionYRadius, polarEndRadian)[0]
        //         );
        //         apiDuring.setShape(
        //             'y',
        //             convertToPolarPoint(params, rectPositionXRadius, rectPositionYRadius, polarEndRadian)[1]
        //         );
        //         apiDuring.setTransform('originX', rectPositionXRadius - ringWidth / 4);
        //         apiDuring.setTransform('originY', rectPositionYRadius - ringWidth / 2);
        //         apiDuring.setTransform('rotation', apiDuring.getExtra('polarEndRadian'));
        //     }
        // },
        {
            type: 'sector',
            extra: {
                polarEndRadian: polarEndRadian,
                transition: 'polarEndRadian',
                enterFrom: { polarEndRadian: -Math.PI / 2 }
            },
            style: {
                x: params.coordSys.cx - outerRadius,
                y: params.coordSys.cy - outerRadius,
                fill: '#0b1826',
                align: 'center',
                verticalAlign: 'middle',
                enterFrom: { opacity: 1 }
            },
            shape: {
                cx: params.coordSys.cx,
                cy: params.coordSys.cy,
                r: ringRadius,
                r0: ringRadius - ringWidth,
                startAngle: -Math.PI / 2,
                endAngle: -Math.PI / 2 + 0.045, // 透明环形和灰色环境之间的间距
                transition: 'endAngle',
                enterFrom: { endAngle: 0 }
            },
            during: function(apiDuring) {
                const polarEndRadian = -apiDuring.getExtra('polarEndRadian') - Math.PI / 2;
                apiDuring.setShape('startAngle', polarEndRadian);
                apiDuring.setShape('endAngle', polarEndRadian + 0.045);
            }
        },
        {
            type: 'sector',
            extra: {
                valOnRadian: valOnRadian
            },
            style: {
                x: params.coordSys.cx - outerRadius,
                y: params.coordSys.cy - outerRadius,
                // fill: '#1edbff',
                fill: new LinearGradient(
                    1, 0, 0, 1, // 4个参数用于配置渐变色的起止位置, 这4个参数依次对应右/下/左/上四个方位. 而0 0 0 1则代表渐变色从正上方开始
                    RING_FILL_COLORS[0] // 数组, 用于配置颜色的渐变过程. 每一项为一个对象, 包含offset和color两个参数. offset的范围是0 ~ 1, 用于表示位置
                ),
                align: 'center',
                verticalAlign: 'middle',
                enterFrom: { opacity: 1 }
            },
            shape: {
                cx: params.coordSys.cx,
                cy: params.coordSys.cy,
                r: ringRadius,
                r0: ringRadius - ringWidth,
                startAngle: -Math.PI / 2,
                endAngle: -polarEndRadian - Math.PI / 2,
                transition: 'endAngle',
                enterFrom: { endAngle: -Math.PI / 2 }
            },
            during: function(apiDuring) {
                const valOnRadian = apiDuring.getExtra('valOnRadian');
                const linearGradient = new LinearGradient(
                    1, 0, 0, 1, // 4个参数用于配置渐变色的起止位置, 这4个参数依次对应右/下/左/上四个方位. 而0 0 0 1则代表渐变色从正上方开始
                    RING_FILL_COLORS[getParkingIndex(valOnRadian)] // 数组, 用于配置颜色的渐变过程. 每一项为一个对象, 包含offset和color两个参数. offset的范围是0 ~ 1, 用于表示位置
                );
                apiDuring.setStyle('fill', linearGradient);
            }
        },
        {
            type: 'text',
            extra: {
                valOnRadian: valOnRadian,
                transition: 'valOnRadian',
                enterFrom: { valOnRadian: 0 }
            },
            style: {
                text: `${valOnRadian}%`,
                fontSize: 18,
                fontWeight: 700,
                x: params.coordSys.cx,
                y: params.coordSys.cy - 4,
                fill: '#fff',
                align: 'center',
                verticalAlign: 'middle',
                enterFrom: { opacity: 0 }
            }
        },
        {
            type: 'text',
            style: {
                text: '占有率',
                fontSize: 9,
                x: params.coordSys.cx,
                y: params.coordSys.cy + 12,
                fill: '#fff',
                align: 'center',
                verticalAlign: 'middle',
                enterFrom: { opacity: 0 }
            }
        }]
    };
}

export default {
    animationEasing: animationEasingUpdate,
    animationDuration: animationDuration,
    animationDurationUpdate: animationDurationUpdate,
    animationEasingUpdate: animationEasingUpdate,
    dataset: {
        source: [[1, 0]]
    },
    tooltip: null,
    angleAxis: {
        type: 'value',
        startAngle: 0,
        axisLine: { show: false },
        axisTick: { show: false },
        axisLabel: { show: false },
        splitLine: { show: false },
        min: 0,
        max: 100
    },
    radiusAxis: {
        type: 'value',
        axisLine: { show: false },
        axisTick: { show: false },
        axisLabel: { show: false },
        splitLine: { show: false }
    },
    polar: {},
    series: [{
        type: 'custom',
        coordinateSystem: 'polar',
        renderItem: renderItem,
        silent: true
    }]
};
