import { VuexModule, Module, Mutation, getModule } from 'vuex-module-decorators';
import store from '@/store';
import TopoEditorModel from '@/pages/topo/model/topo-editor-model';
import { SimpleTreeModel } from '@/model/simple-tree-model';

@Module({ dynamic: true, store, name: 'topo' })
class Topo extends VuexModule {
    private topoEditorModel: TopoEditorModel = null;

    get TopoComponentTreeData() {
        return [_.get(this.topoEditorModel, 'component')];
    }

    get TopoComponentTreeSimpleData() {
        return [new SimpleTreeModel().toModel(_.get(this.topoEditorModel, 'component'))];
    }

    get TopoEditorModel() {
        return this.topoEditorModel;
    }

    @Mutation
    public SET_TOPO_EDITOR_MODEL(model:TopoEditorModel): void {
        this.topoEditorModel = model;
    }
}

export const TopoStoreModule = getModule(Topo);
