































import { Component, Prop } from 'vue-property-decorator';
import { BaseComponent } from '@/mixins/base-component';
import { uploadFiles } from '@/service/uploadOSS';
import { generateUUID } from '@/utils/base_util';

class FileModel {
    constructor(file: File, url?: string, type?:string) {
        this.file = file;
        this.url = url;
        this.status = 'done';
        this.uid = _.get(file, 'uid') || url;
        this.name = _.get(file, 'name');
        this.type = type || 'file';
    }

    uid: string;
    name: string;
    status: string;
    url: string;
    file: File;
    type: string;

    toPlainObject() {
        return _.toPlainObject(this);
    }
}

@Component
export default class JTLUploadComponent extends BaseComponent {
    @Prop({ default: function() { return []; } })
    value: Array<any>;

    @Prop()
    accept: string;

    @Prop()
    listType: string;

    @Prop({ type: Boolean, default: true })
    showUploadList: boolean;

    @Prop()
    disabled: boolean;

    @Prop()
    maxFileNumber: number;

    @Prop()
    maxFileSize: number; // 单个文件大小（MB）

    @Prop()
    notUploadOss: boolean;

    previewVisible: boolean = false;
    previewImage: string = '';
    previewType: string = '';

    // get IsSingleFile() {
    //     return this.maxFileNumber === 1;
    // }

    get FileList() {
        return _.map(this.value, item => {
            let fileModel = null;
            switch (item.type) {
                case 'video':
                    fileModel = {
                        name: item.name,
                        url: item.url + '?x-oss-process=video/snapshot,t_10000,m_fast',
                        status: item.status,
                        uid: item.uid || generateUUID(),
                        type: item.type
                    };
                    break;
                case 'image':
                    fileModel = {
                        name: item.name || 'default-name',
                        url: item.url + '?x-oss-process=image/resize,m_fill,h_150,w_150',
                        status: item.status || 'done',
                        uid: item.uid || generateUUID(),
                        type: 'image'
                    };
                    break;
                default:
                    fileModel = {
                        name: item.name,
                        url: item.url,
                        status: item.status,
                        uid: item.uid || generateUUID(),
                        type: item.type
                    };
                    break;
            }
            return fileModel;
            // if (item.type === 'image') {
            //     return {
            //         name: item.name,
            //         url: item.url + '?x-oss-process=image/resize,m_fill,h_150,w_150',
            //         status: item.status,
            //         uid: item.uid || generateUUID(),
            //         type: item.type
            //     };
            // } else if (item.type === 'video') {
            //     return {
            //         name: item.name,
            //         url: item.url + '?x-oss-process=video/snapshot,t_10000,m_fast',
            //         status: item.status,
            //         uid: item.uid || generateUUID(),
            //         type: item.type
            //     };
            // } else {
            //     return {
            //         name: item.name,
            //         url: item.url,
            //         status: item.status,
            //         uid: item.uid || generateUUID(),
            //         type: item.type
            //     };
            // }
        });
    }

    doImgUpload(options) {
        if (this.notUploadOss) {
            this.$emit('input', [new FileModel(options.file).toPlainObject()]);
            this.$emit('change');
            return;
        }

        uploadFiles([options.file]).then((result:any) => {
            result.map(res => {
                // 识别上传文件类型
                let fileExt = res.url.split('.').pop();
                if (['jpg', 'jpeg', 'png'].indexOf(fileExt) > -1) {
                    res['type'] = 'image';
                } else if (['mp4'].indexOf(fileExt) > -1) {
                    res['type'] = 'video';
                } else {
                    res['type'] = 'file';
                }

                this.value.push(new FileModel(options.file, res.url, res.type).toPlainObject());
                this.$emit('input', this.value.map(val => {
                    if (val.file) {
                        delete val.file;
                    }
                    return val;
                }));

                this.$emit('change');
            });
        });
    }

    handleBeforeUpload(file) {
        let isLtMaxFileSize = false;
        if (file.type === 'video/mp4') {
            // 视频小于10M
            isLtMaxFileSize = file.size / 1024 / 1024 < 10;
            if (!isLtMaxFileSize) {
                this.showMessageError('文件超过10M限制，不允许上传~');
            }
        } else if (file.type && file.type.indexOf('image') > -1) {
            // 图片小于2M
            isLtMaxFileSize = file.size / 1024 / 1024 < 2;
            if (!isLtMaxFileSize) {
                this.showMessageError('文件超过2M限制，不允许上传~');
            }
        }

        isLtMaxFileSize = true;
        return isLtMaxFileSize;
    }

    handleChange(info) {
        if (info.file.status === 'removed') {
            const removeIndex = _.findIndex(this.value, item => item.uid === info.file.uid);
            if (removeIndex > -1) {
                this.value.splice(removeIndex, 1);
            }

            this.$emit('input', this.value);
            this.$emit('change');
        }
    }

    getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    async handlePreview(file) {
        if (file.type === 'file') {
            this.downloadFile(file);
            return;
        }

        if (!file.url && !file.preview) {
            file.preview = await this.getBase64(file.originFileObj);
        }
        this.previewImage = file.url.split('?x-oss-process')[0] || file.preview;
        this.previewType = file.type;
        this.previewVisible = true;
    }

    downloadFile(file) {
        const eleLink = document.createElement('a');
        eleLink.download = file.name;
        eleLink.style.display = 'none';
        eleLink.href = file.url;
        // 触发点击
        document.body.appendChild(eleLink);
        eleLink.click();
        // 然后移除
        document.body.removeChild(eleLink);
    }

    handleCancel() {
        this.previewVisible = false;
    }
}

