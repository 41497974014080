import './register-service-worker.js';
import Vue from 'vue';
import '@/global-init';
import App from '@/app.vue';
import store from '@/store';
import router from '@/route';

function initVueApp() {
    new Vue({
        router,
        store,
        render: (h) => h(App)
    }).$mount('#app');
}

// get web config first
window.fetch('/config.json').then(async(res) => {
    JTL.CONFIG = await res.json();
    initVueApp();
}).catch(err => {
    console.error(err);
    initVueApp();
});
