










import { Component, Prop } from 'vue-property-decorator';
import TopoControl from './topo-control';
import TopoService from '@topo/service/topo-service';
import { TopoPageEntityModel } from '@topo/model/topo-page-entity-model';
import TopoRenderComponent from './topo-render-component.vue';
import { UserStoreModule } from '@/store/modules/user';
import { CacheUtil } from '@/model/cache-model';

@Component({
    components: { TopoRenderComponent }
})
export default class TopoRender extends TopoControl {
    @Prop()
    editMode: boolean;
    topoPageEntityModel: TopoPageEntityModel = null;
    pageLoading: boolean = false;

    created() {
        $topoEventHub.$on('TOPO_RENDER_EVENT_HUB', param => {
            this.topoPageEntityModel.handleComponentAction(param);
        });
        $topoEventHub.$on('TOPO_LOGOUT_EVENT_HUB', param => {
            CacheUtil.cleanCache();
            UserStoreModule.SET_USERINFO();
            // this.$router.push({ path: '/sign/login', query: { redirectPath: '/parking' } });
            this.$router.push('/sign/login?redirectPath=/parking');
        });
    }

    /**
     * 初始化页面
     */
    initRender(id: string) {
        this.startFullScreenLoading();
        this.pageLoading = true;
        TopoService.retrieve(id).then(async(res) => {
            this.topoPageEntityModel = res;
            // await this.initLayerData();
        }).finally(() => {
            this.pageLoading = false;
            this.stopFullScreenLoading();
        });
    }
}
