import { Component } from 'vue-property-decorator';
import { BaseCRUDComponent } from './base-component';
import CommonService from '@/service/common';
import { ViewModeType } from '@/model/enum';
import { NO_FORM_FIELD } from '@/model/base-model';
import {
    FormControlModel,
    FormControlType,
    FormControlTextModel,
    FormControlOptionModel,
    FormControlSwitchModel,
    FormControlDateModel,
    FormControlThingsTypeModel,
    FormControlThingsFunctionAutoCompleteModel,
    FormControlUploadModel,
    FormControlSelectGroupModel,
    FormControlSelectDeviceAttributeModel,
    FormControlTimePickerModel,
    FormControlTableModel,
    FormControlMonthSeasonModel,
    FormControlInputGroupModel,
    FormControlLinkDeviceModel
} from '@/model/form-control';
import { NUMBER_LETTER_UNDERLINE_REGEXP, NUMBER_FLOAT_REGEXP, NUMBER_INT_REGEXP, LETTER_REGEXP } from '@/model/regexp';

// 参数	说明	类型	默认值
// enum	枚举类型	string	-
// len	字段长度	number	-
// max	最大长度	number	-
// message	校验文案	string	-
// min	最小长度	number	-
// pattern	正则表达式校验	RegExp	-
// required	是否必选	boolean	false
// transform	校验前转换字段值	function(value) => transformedValue:any	-
// type	内建校验类型，可选项	string	'string'
//         string: Must be of type string. This is the default type.
//         number: Must be of type number.
//         boolean: Must be of type boolean.
//         method: Must be of type function.
//         regexp: Must be an instance of RegExp or a string that does not generate an exception when creating a new RegExp.
//         integer: Must be of type number and an integer.
//         float: Must be of type number and a floating point number.
//         array: Must be an array as determined by Array.isArray.
//         object: Must be of type object and not Array.isArray.
//         enum: Value must exist in the enum.
//         date: Value must be valid as determined by Date
//         url: Must be of type url.
//         hex: Must be of type hex.
//         email: Must be of type email.
//         any: Can be any type
// validator	自定义校验（注意，callback 必须被调用）	function(rule, value, callback)	-
// whitespace	必选时，空格是否会被视为错误	boolean	false
const DEFAULT_VALIDATOR_KEYS = ['min', 'max', 'required', 'pattern', 'validator', 'validators', 'asyncValidator'];

@Component
export default class FormComponent<T> extends BaseCRUDComponent<T> {
    ViewModeType = ViewModeType;
    /**
     * 表单验证规则
     */
    public formRules: object = null;

    /**
     * 表单验证模型
     */
    public formModel: T = null;

    /**
     * 表单项类型列表
     */
    public formControlList: Array<any> = null;

    /**
     * 表单项类型列表
     */
    protected viewMode: ViewModeType = ViewModeType.VIEW;

    get IsView() {
        return this.viewMode === ViewModeType.VIEW;
    }

    getDisabled(formControl: FormControlOptionModel) {
        if (_.isBoolean(formControl.disabled)) {
            return formControl.disabled;
        }
        return (formControl.disabled || (formControl.readonly && this.viewMode === ViewModeType.UPDATE) || this.IsView);
        // return ((formControl.readonly && this.viewMode === ViewModeType.UPDATE) || this.IsView) && !!this.formModel[formControl.key];
    }

    /**
     * 根据模型中是否有id判断新增/更新
     */
    // get NewOrUpdate(): ViewModeType {
    //     return _.get(this.formModel, 'id') ? ViewModeType.UPDATE : ViewModeType.NEW;
    // }

    /**
     * 获取模型详细信息
     * @param id 模型id
     */
    getDetail(id: string, viewMode: ViewModeType) {
        return this.service.retrieve(id).then((ret) => {
            this.formModel = ret;
            this.initForm(this.formModel, viewMode);
        }).catch(err => {
            this.$log.error(err);
        }).finally(() => { });
    }

    initFormRules(formControlOption: FormControlModel<any>) {
        const validators: Array<any> = [];
        const validatorKeys = _.filter(_.keys(formControlOption), key => DEFAULT_VALIDATOR_KEYS.indexOf(key) > -1);
        _.forEach(validatorKeys, (optionKey: string) => {
            let validator: object = null;
            let errorMessage = _.get(formControlOption.errorMessage, optionKey);
            const ruleValue: any = formControlOption[optionKey];
            switch (optionKey) {
                case 'min':
                    if (formControlOption.type !== FormControlType.NUMBER) {
                        validator = { min: ruleValue, message: errorMessage || `${formControlOption.label}长度最小值是${ruleValue}` };
                    }
                    break;
                case 'max':
                    if (formControlOption.type !== FormControlType.NUMBER) {
                        validator = { max: ruleValue, message: errorMessage || `${formControlOption.label}长度最大值是${ruleValue}` };
                    }
                    break;
                case 'required':
                    validator = { required: ruleValue, message: errorMessage || `${formControlOption.label}是必填项` };
                    break;
                case 'pattern':
                    if (!errorMessage) {
                        switch (ruleValue) {
                            case NUMBER_LETTER_UNDERLINE_REGEXP:
                                errorMessage = '支持大小写字母、数字和下划线，且首字母不能是数字';
                                break;
                            case NUMBER_FLOAT_REGEXP:
                                errorMessage = '支持浮点数';
                                break;
                            case NUMBER_INT_REGEXP:
                                errorMessage = '支持整数';
                                break;
                            case LETTER_REGEXP:
                                errorMessage = '仅支持字母';
                                break;
                        }
                    }
                    validator = { pattern: ruleValue, message: errorMessage || `${formControlOption.label}数据格式不正确` };
                    break;
                case 'validator':
                    validator = { validator: ruleValue, trigger: 'blur', formModel: this.formModel, cloneFormModel: _.cloneDeep(this.formModel) };
                    break;
                case 'validators':
                    validators.push(..._.map(ruleValue, item => {
                        if (!item.trigger) {
                            item.trigger = 'blur';
                        }
                        item['formModel'] = this.formModel;
                        item['cloneFormModel'] = _.cloneDeep(this.formModel);
                        return item;
                    }));
                    break;
            }
            if (validator) {
                validators.push(Object.assign({ trigger: 'blur' }, validator));
            }
        });
        this.formRules[formControlOption.key] = validators;
    }

    /**
     * 利用Reflect技术
     * 初始化表单模型、表单验证规则和表单项类型列表
     * @param model 模型实例
     * @param viewMode 模式-查看/编辑/新增
     * @param needClone 是否需要深拷贝
     */
    initForm(model: T, viewMode: ViewModeType, needClone: boolean = true): void {
        this.viewMode = viewMode;
        if (needClone) {
            this.formModel = _.cloneDeep(model);
        } else {
            this.formModel = model;
        }
        this.formRules = {};
        const controlList = [];
        _.forEach(_.keys(this.formModel), key => {
            const formControlOption: any = Reflect.getMetadata('FormControl', this.formModel, key);
            if (formControlOption && NO_FORM_FIELD.indexOf(key) === -1 && !(this.viewMode === ViewModeType.UPDATE && formControlOption.updateHidden) && !(this.viewMode !== ViewModeType.UPDATE && formControlOption.addHidden)) {
                formControlOption.value = this.formModel[key];
                if (!formControlOption.key) {
                    formControlOption.key = key;
                }
                this.initFormRules(formControlOption);
                controlList.push(this.initFormControlByType(formControlOption));
            }
        });
        this.formControlList = _.orderBy(controlList, 'index', 'asc');
        _.forEach(this.formControlList, item => {
            this.initFormRelationKeys(item, false);
        });
        this.clearValidate();
    }

    /**
     * 初始化option表单项
     * @param formControl
     */
    initFormControlOption(formControl: FormControlOptionModel) {
        if (formControl.optionsPromise) {
            let params = null;
            // 如果参数是string类型，且是formModel的字段，优先使用formModel的字段值
            if (typeof (formControl.optionsPromiseParam) === 'string') {
                params = _.keysIn(this.formModel).indexOf(formControl.optionsPromiseParam) > -1
                    ? this.formModel[formControl.optionsPromiseParam]
                    : formControl.optionsPromiseParam;
            } else {
                params = formControl.optionsPromiseParam;
            }
            formControl.loading = true;
            formControl.optionsPromise(params).then(res => {
                formControl.options = res;
            }).catch(err => $log.error(err)).finally(() => { formControl.loading = false; });
        }
    }

    /**
     * 根据type初始化表单项
     * @param formControlOption
     * @returns
     */
    initFormControlByType(formControlOption) {
        let formControl = null;
        switch (formControlOption.type) {
            case FormControlType.ATTRIBUTE_DATA_TYPE_VALUE:
            case FormControlType.DATA_PARAM:
            case FormControlType.SELECT_SPACE:
            case FormControlType.SELECT_DEVICE:
                formControl = new FormControlModel(formControlOption);
                break;
            case FormControlType.TEXT:
            case FormControlType.TEXT_AREA:
            case FormControlType.NUMBER:
            case FormControlType.TEXT_RICH:
                formControl = new FormControlTextModel(formControlOption);
                break;
            case FormControlType.SELECT:
            case FormControlType.RADIO_GROUP:
            case FormControlType.CHECKBOX_GROUP:
            case FormControlType.AUTO_COMPLETE:
            case FormControlType.TREE_SELECT:
            case FormControlType.TREE:
            case FormControlType.TOPO_TARGET_SELECT:
            case FormControlType.CASCADER_CITYS:
                formControl = new FormControlOptionModel(formControlOption);
                this.initFormControlOption(formControl);
                break;
            case FormControlType.AUTO_COMPLETE_THINGS_FUNCTION:
                formControl = new FormControlThingsFunctionAutoCompleteModel(formControlOption);
                break;
            case FormControlType.SWITCH:
                formControl = new FormControlSwitchModel(formControlOption);
                break;
            case FormControlType.SELECT_DATE:
            case FormControlType.SELECT_DATERANGE:
                formControl = new FormControlDateModel(formControlOption);
                break;
            case FormControlType.THINGS_MODEL_TYPE:
                formControl = new FormControlThingsTypeModel(formControlOption);
                break;
            case FormControlType.UPLOAD:
                formControl = new FormControlUploadModel(formControlOption);
                break;
            case FormControlType.INPUT_GROUP:
                formControl = new FormControlInputGroupModel(formControlOption);
                break;
            case FormControlType.SELECT_GROUP:
                if (this.formModel) {
                    formControlOption.groupType = this.formModel['groupType'];
                }
                formControl = new FormControlSelectGroupModel(formControlOption);
                break;
            case FormControlType.SELECT_DEVICE_ATTRIBUTE:
                formControl = new FormControlSelectDeviceAttributeModel(formControlOption);
                break;
            case FormControlType.TIME_PICKER:
            case FormControlType.TIME_PICKERS:
                formControl = new FormControlTimePickerModel(formControlOption);
                break;
            case FormControlType.TABLE:
                formControl = new FormControlTableModel(formControlOption);
                break;
            case FormControlType.MONTH_SEASON_RULE:
                formControl = new FormControlMonthSeasonModel(formControlOption);
                break;
            case FormControlType.LINKAGE_DEVICE:
                formControl = new FormControlLinkDeviceModel(formControlOption);
                break;
            default:
                formControl = new FormControlModel(formControlOption);
                break;
        }
        if (_.isFunction(formControl.invisibleFunction)) {
            formControl.invisible = formControl.invisibleFunction(this.formModel);
        }
        if (_.isFunction(formControl.labelFunction)) {
            formControl.label = formControl.labelFunction(this.formModel);
        }
        return formControl;
    }

    /**
     * 根据controls初始化表单项
     * @param controls
     * @param viewMode
     */
    initFormByControls(controls: Array<FormControlModel<T>>, viewMode: ViewModeType = ViewModeType.NEW) {
        this.viewMode = viewMode;
        const fModel: any = {};
        this.formRules = {};
        const controlList = [];
        _.forEach(controls, item => {
            fModel[item.key] = item.value;
            this.initFormRules(item);
            const controlItem = this.initFormControlByType(item);
            if (controlItem) {
                controlList.push(controlItem);
            }
        });
        this.formModel = fModel;
        this.formControlList = controlList;
        this.clearValidate();
    }

    /**
     * 初始化有级联关系的表单项
     * @param formControl
     * @param needClearRelationValue
     */
    initFormRelationKeys(formControl: FormControlOptionModel, needClearRelationValue: boolean) {
        if (this.formModel[formControl.key] && formControl.relationKeys && formControl.relationKeys.length > 0) {
            _.forEach(formControl.relationKeys, item => {
                const relationFirstKey = item;
                const relationControl = _.find(this.formControlList, item => item.key === relationFirstKey);
                if (relationControl) {
                    if (needClearRelationValue) {
                        this.formModel[relationFirstKey] = undefined;
                    }
                    const relationPath = relationControl.relationPath;
                    CommonService.getSubOptions(this.formModel[formControl.key], relationPath).then(res => {
                        relationControl.options = res;
                    });
                }
            });
        }
    }

    /**
     * 验证所有表单项
     */
    async validate(): Promise<boolean> {
        try {
            let customerValidateResult = true;
            if (this.$refs[JTL.CONSTANT.DEFAULT_CUSTOMER_FORM_NAME]) {
                const customerValidateResultList = [];
                for (let index = 0; index < (this.$refs[JTL.CONSTANT.DEFAULT_CUSTOMER_FORM_NAME] as any).length; index++) {
                    const currentCustomerForm = this.$refs[JTL.CONSTANT.DEFAULT_CUSTOMER_FORM_NAME][index];
                    if (currentCustomerForm) {
                        if (currentCustomerForm.validate) {
                            const validResult = await currentCustomerForm.validate();
                            customerValidateResultList.push(validResult);
                        } else if (currentCustomerForm.$refs[JTL.CONSTANT.DEFAULT_FORM_NAME]) {
                            const validResult = await currentCustomerForm.$refs[JTL.CONSTANT.DEFAULT_FORM_NAME].validate();
                            customerValidateResultList.push(validResult);
                        }
                    }
                }
                customerValidateResult = _.filter(customerValidateResultList, item => !item).length === 0;
            }
            const defaultValidResult = await this.validateDefaultForm();
            return defaultValidResult && customerValidateResult;
        } catch (e) {
            this.validateDefaultForm();
            return false;
        }
    }

    /**
     * 验证默认的表单
     * @returns
     */
    async validateDefaultForm() {
        try {
            if (this.$refs[JTL.CONSTANT.DEFAULT_FORM_NAME]) {
                const validateResult = await (this.$refs[JTL.CONSTANT.DEFAULT_FORM_NAME] as any).validate();
                return validateResult;
            }
            return true;
        } catch (e) {
            return false;
        }
    }

    /**
     * 验证指定表单项
     * @param field 表单项
     */
    validateField(field: string): void {
        if (this.$refs[JTL.CONSTANT.DEFAULT_FORM_NAME]) {
            (this.$refs[JTL.CONSTANT.DEFAULT_FORM_NAME] as any).validateField(field);
        }
    }

    /**
     * 清除所有表单项验证结果
     */
    clearValidate(): void {
        this.$nextTick(() => {
            if (this.$refs[JTL.CONSTANT.DEFAULT_FORM_NAME]) {
                (this.$refs[JTL.CONSTANT.DEFAULT_FORM_NAME] as any).clearValidate();
            }
        });
    }

    /**
     * 初始化所有表单项
     */
    resetFields(): void {
        // this.$nextTick(() => {
        //     if (this.$refs[JTL.CONSTANT.DEFAULT_FORM_NAME]) {
        //         (this.$refs[JTL.CONSTANT.DEFAULT_FORM_NAME] as any).resetFields();
        //     }
        // });
    }

    /**
     * 提交表单
     */
    async submitForm(): Promise<T> {
        if (!await this.validate()) {
            this.showMessageError('您提交的数据有问题');
            return Promise.reject(new Error('Form error'));
        }
        if (!this.service) {
            return new Promise((resolve, reject) => {
                resolve(null);
            });
        }

        this.startFullScreenLoading('正在保存...');
        let createOrUpdatePromise: Promise<T> = null;
        if (this.viewMode === ViewModeType.NEW) {
            createOrUpdatePromise = this.service.create(this.formModel);
        } else {
            createOrUpdatePromise = this.service.update(this.formModel);
        }
        return createOrUpdatePromise.then((ret) => {
            return ret;
        }).catch((err) => {
            throw err;
        }).finally(() => {
            this.stopFullScreenLoading();
        });
    }
}
