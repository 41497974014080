import { TopoComponentType, TopoDataType } from './enum';
import TopoStyleLineModel, { TopoStyleLineDashedModel } from './style-model/topo-style-line-model';
import TopoStyleTextModel from './style-model/topo-style-text-model';
import TopoStyleBaseModel from './style-model/topo-style-base-model';
import { generateUUID } from '@/utils/base_util';
import TopoBaseComponentModel from './topo-base-component-model';
import TopoStyleButtonModel from './style-model/topo-style-button-model';

export default class TopoComponentEntityModel extends TopoBaseComponentModel {
    constructor() {
        super();
        this.id = generateUUID();
    }

    /**
     * 组件显示名称
     */
    name: string = undefined;

    /**
     * 组件类型
     */
    type: TopoComponentType = undefined;

    /**
     * Vue的动态组件名
     */
    componentName: string = undefined;

    /**
     * 组件样式
     */
    style: TopoStyleBaseModel = undefined;

    /**
     * 子组件
     */
    children: Array<TopoComponentEntityModel> = undefined;

    /**
     * 父组件
     */
    parent: TopoComponentEntityModel = undefined;

    /**
     * 是否展开
     */
    expand: boolean = true;

    /**
     * 树形的节点级数
     */
    level: number = undefined;

    /**
     * 获取组件名称
     */
    get ComponentName() {
        return this.componentName || `view-${this.type}`;
    }

    /**
     * 是否有子项
     */
    get HasChildren() {
        return this.children && this.children.length > 0;
    }

    /**
     * 获取组件
     * @param editMode 是否编辑模式
     */
    async getData(editMode: boolean) {
        let data = null;
        if (this.data) {
            if (editMode && [TopoDataType.HTTP, TopoDataType.INNER_HTTP, TopoDataType.PARENT].indexOf(this.data.type) > -1) {
                return null;
            }
            this.loading = true;
            try {
                data = await this.data.getData(_.get(this.parent, 'data.componentData'));
                this.loading = false;
            } catch (e) {
                this.loading = false;
            }
        }
        return data;
    }

    /**
     * 取消请求数据
     */
    cancelGetData() {
        this.data.cancelGetData();
    }

    /**
     * 备份组件位置信息
     */
    clonePosition() {
        this.style.tempPosition = {
            left: this.style.position.left,
            top: this.style.position.top
        };
    }

    removeSelf() {
        if (this.parent && this.parent.children) {
            const removeIndex = this.parent.children.indexOf(this);
            if (removeIndex > -1) {
                this.parent.children.splice(removeIndex, 1);
            }
        }
    }

    getElementRect() {
        const componentElement = document.getElementById(this.id);
        if (componentElement) {
            const clientRect = componentElement.getBoundingClientRect();
            return {
                x: clientRect.x,
                y: clientRect.y,
                width: clientRect.width,
                height: clientRect.height
            };
        }
    }

    toModel(options) {
        super.toModel(options);
        this.initDataModel(_.get(options, 'data'));
        switch (this.type) {
            case TopoComponentType.RECT:
                this.style = new TopoStyleTextModel().toModel(_.get(options, 'style'));
                break;
            case TopoComponentType.TEXT:
                this.style = new TopoStyleTextModel().toModel(_.get(options, 'style'));
                break;
            case TopoComponentType.LINE:
            case TopoComponentType.LINE_ARROW:
            case TopoComponentType.BIZIER_CURVE_ARROW:
                this.style = new TopoStyleLineModel().toModel(_.get(options, 'style'));
                break;
            case TopoComponentType.DASHED:
                this.style = new TopoStyleLineDashedModel().toModel(_.get(options, 'style'));
                break;
            case TopoComponentType.BUTTON:
                this.style = new TopoStyleButtonModel().toModel(_.get(options, 'style'));
                break;
            default:
                this.style = new TopoStyleBaseModel().toModel(_.get(options, 'style'));
                break;
        }
        if (_.get(options, 'children')) {
            this.children = _.map(_.get(options, 'children'), item => {
                const child = new TopoComponentEntityModel().toModel(item);
                child.parent = this;
                child.level = this.getChildLevel();
                if (child.level > 1 || !child.children || child.children.length === 0) {
                    // 默认只展开到第2级树
                    child.expand = false;
                }
                return child;
            });
        }
        return this;
    }

    toService() {
        return this.JsonViewData;
    }

    getChildLevel() {
        if (!this.level) {
            this.level = 1;
        }
        return this.level + 1;
    }

    get JsonViewData() {
        const data = super.toService();
        data.style = this.style.toService();
        data.behaves = _.map(this.behaves, item => item.toService());
        data.children = _.map(this.children, item => item.toService());
        delete data.style.tempPosition;
        delete data.style.position.uuid;
        delete data.loading;
        delete data.parent;
        return data;
    }
}
