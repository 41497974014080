import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import { UserModel } from '@/model/user-model';

const packageVersion = require('../package.json').version;

export interface ILog {
  info(message?: any, ...optionalParams: any[]):void;
  debug(message?: any, ...optionalParams: any[]):void;
  warn(message?: any, ...optionalParams: any[]):void;
  error(message?: any, ...optionalParams: any[]):void;
  jtlDebug(message?: any, ...optionalParams: any[]):void;
}

/**
 * 初始化sentry的用户信息
 * @param userInfo 用户信息
 */
export function initSentryUserInfo(userInfo?: UserModel) {
    try {
        Sentry.configureScope((scope) => {
            scope.setUser({
                id: _.get(userInfo, 'id', '-'),
                username: _.get(userInfo, 'username', '-'),
                email: _.get(userInfo, 'email', '-')
            });
            scope.setTag('env', process.env.NODE_ENV);
        });
    } catch (e) {
        console.error(e);
    }
}

/**
 * 初始化Sentry SDK
 * refere https://docs.sentry.io/error-reporting/configuration/?platform=javascript
 */
Sentry.init({
    dsn: process.env.VUE_APP_SENTRY_DSN, // 项目的地址,用于收集错误信息的 sentry 分配的地址
    maxBreadcrumbs: 20, // 控制应捕获的面包屑的总量.默认为100.
    debug: false, // 是否开启 debug 模式,开启debug,就会把信息打印到控制台上面,需要初始化sentry_log_sdk
    release: packageVersion, // 版本号,可以确定当前的错误/异常属于哪一个发布的版本,可以应用到sourcemaps来映射源码
    environment: process.env.NODE_ENV, // 环境名称
    integrations: [new Integrations.Vue({ Vue, attachProps: true })]
});

const SentryConfigUser = function() {
    // 将 scope 配置到 context 上面
    // Sentry.configureScope((scope) => {
    //     scope.setUser({
    //         id: '-',
    //         username: 'No User',
    //         email: ''
    //     });
    // });
    initSentryUserInfo();
};

SentryConfigUser();

const Log: ILog = {
    info: function(message?: any, ...optionalParams: any[]): void {
        console.log(message, ...optionalParams);
        // Sentry.captureMessage(message, Sentry.Severity.Info);
    },
    debug: function(message?: any, ...optionalParams: any[]): void {
        console.debug(message, ...optionalParams);
        // Sentry.captureMessage(message, Sentry.Severity.Debug);
    },
    warn: function(message?: any, ...optionalParams: any[]): void {
        console.warn(message, ...optionalParams);
        // Sentry.captureMessage(message, Sentry.Severity.Warning);
    },
    error: function(message?: any, ...optionalParams: any[]): void {
        console.error(message, ...optionalParams);
        Sentry.captureException(message);
    },
    jtlDebug: function(type: string, message?: any, ...optionalParams: any[]): void {
        if (JTL.debug && console[type]) {
            console[type](message, ...optionalParams);
        }
    }
};

window['$log'] = Vue.prototype.$log = Log;
