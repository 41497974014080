import { FormControl, FormControlType, FormControlTextModel } from '@/model/form-control';
import { SimpleTreeModel } from '@/model/simple-tree-model';
import { GroupEntityType } from '@/model/enum';

export class ThingsGroupEntityModel extends SimpleTreeModel {
    constructor(type?: GroupEntityType) {
        super();
        this.type = type;
    }

    groupNameValidateList: Array<any> = undefined;

    @FormControl({
        label: '名称',
        type: FormControlType.TEXT,
        required: true,
        max: 50,
        index: 1,
        validator: (rule, value, callback) => {
            if (_.filter(rule.formModel.groupNameValidateList, item => (value && item.name === value && rule.formModel.id !== item.id)).length > 0) {
                callback(new Error('分组名称重复，请调整后保存'));
            } else {
                callback();
            }
        }
    } as FormControlTextModel)
    name: string = undefined;

    @FormControl({
        label: '代码',
        type: FormControlType.TEXT,
        required: true,
        max: 50,
        index: 2,
        invisibleFunction: (model: ThingsGroupEntityModel) => { return model.type !== GroupEntityType.ASSET; }
    } as FormControlTextModel)
    code: string = undefined;

    type: GroupEntityType = undefined;

    toModel(json): any {
        this.code = _.get(json, 'code');
        this.name = this.title = _.get(json, 'name');
        this.type = _.get(json, 'type');
        this.key = this.value = this.id = _.get(json, 'id');
        this.pId = _.get(json, 'parentId');
        if (json && json.children && json.children.length > 0) {
            this.children = _.map(json.children, item => {
                item.type = this.type;
                const child: ThingsGroupEntityModel = new ThingsGroupEntityModel().toModel(item);
                child.parent = this;
                return child;
            });
        } else {
            this.children = [];
        }
        return this;
    }

    toService() {
        return {
            id: this.id,
            code: this.code,
            name: this.name,
            parentId: this.pId,
            type: this.type
        };
    }
}
