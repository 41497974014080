import { BaseEntityModel } from '@/model/base-model';
import { FormControl, FormControlType, FormControlTextModel } from '@/model/form-control';
import { ThingsEntityType, ThingsFunctionType } from '@/model/enum';
import { NUMBER_LETTER_UNDERLINE_REGEXP } from '@/model/regexp';
export class ThingsEntityModel extends BaseEntityModel {
    constructor(entityType: ThingsEntityType = ThingsEntityType.FUNCTION, entityId?: string) {
        super();
        this.entityId = entityId;
        this.entityType = entityType;
    }

    // async checkAttr(model: AttributeEntityModel):Promise<boolean> {
    //     const url = `${this.getUrlPath(model.entityType)}/checkAttr`;
    //     const params = model.toService();
    //     // 新建的属性都是服务端属性
    //     params.scope = AttributeType.SERVER;
    //     const res = await post(url, params);
    //     return res;
    // }
    // @FormControl({
    //     label: '属性名称',
    //     type: FormControlType.TEXT,
    //     required: true,
    //     asyncValidator: (rule, value) => {
    //         const model = rule.formModel as AttributeEntityModel;
    //         const cloneFormModel = rule.cloneFormModel as AttributeEntityModel;
    //         if (model.entityId && model.entityId === cloneFormModel.entityId && model.key === cloneFormModel.key) {
    //             // 编辑状态，没有改key，无需验证
    //             return new Promise((resolve, reject) => {
    //                 resolve(null);
    //             });
    //         }
    //         return AttributeService.checkAttr(model).then(res => {
    //             if (res) {
    //                 // validate success
    //                 return null;
    //             }
    //             throw new Error('属性名称重复了，请换一个');
    //         }).catch(error => {
    //             throw error;
    //         });
    //     },
    //     errorMessage: { min: 'min...' }
    // } as FormControlTextModel)
    // name: string = null;
    @FormControl({
        label: '名称',
        // type: FormControlType.TEXT,
        type: FormControlType.AUTO_COMPLETE_THINGS_FUNCTION,
        required: true,
        max: 50,
        index: 1
    })
    name: string = undefined;

    @FormControl({
        label: '标识符',
        type: FormControlType.TEXT,
        required: true,
        max: 50,
        pattern: NUMBER_LETTER_UNDERLINE_REGEXP,
        readonly: true,
        index: 2
    } as FormControlTextModel)
    identifier: string = undefined;

    @FormControl({
        label: '描述',
        type: FormControlType.TEXT_AREA,
        max: 500,
        index: 7
    } as FormControlTextModel)
    remark: string = undefined;

    /**
     * 功能类型
     */
    entityType: ThingsEntityType = undefined;

    /**
     * 实体id
     */
    entityId: string = undefined;
    functionType: ThingsFunctionType;

    /**
     * 比较物模型是否相同
     * @param compareModel 比较的物模型
     */
    isSame(compareModel: { name: string, identifier: string }): boolean {
        return this.name === compareModel.name || this.identifier === compareModel.identifier;
    }

    toModel(json): any {
        super.toModel(json);
        this.entityType = _.get(json, 'type');
        this.name = _.get(json, 'name') || _.get(json, 'jsonValue.name');
        this.identifier = _.get(json, 'identifier') || _.get(json, 'attributeKey');
        this.remark = _.get(json, 'remark') || _.get(json, 'jsonValue.remark');
        return this;
    }
}
