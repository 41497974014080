var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "jtl-tooltip-component" },
    [
      _vm.isEllipsis
        ? _c(
            "a-tooltip",
            {
              class: {
                "jtl-tooltip text-ellipsis inline-block": _vm.hasEllipsis
              },
              attrs: { title: _vm.title, placement: _vm.placement }
            },
            [_vm._t("default")],
            2
          )
        : _c("span", [_vm._t("default")], 2)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }