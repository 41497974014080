










import { Component, Vue, Prop, Watch, Ref } from 'vue-property-decorator';
import { BaseComponent } from '@/mixins/base-component';
import { TopoStoreModule } from '@/store/modules/topo';

@Component
export default class TopoTargetComponent extends BaseComponent {
    @Prop()
    value: any;

    get TreeData() {
        return TopoStoreModule.TopoComponentTreeSimpleData;
    }

    get ExpandedKeys() {
        return [_.get(TopoStoreModule.TopoEditorModel, 'component.id')];
    }

    get SelectValue() {
        return this.value;
    }

    set SelectValue(val: any) {
        this.$emit('input', val);
        this.$emit('change', val);
    }
}

