import { BaseModel } from '@/model/base-model';
import { FormControl, FormControlOptionModel, FormControlTextModel, FormControlType } from '@/model/form-control';
import { TopoActionType } from '../enum';
import { topoActionTypeList } from '@topo/filter/topo-action-type';

export default class TopoActionBaseModel extends BaseModel {
    @FormControl({
        label: '动作',
        type: FormControlType.SELECT,
        options: topoActionTypeList,
        layout: { labelCol: 6, wrapperCol: 16, width: '100%' },
        index: 1
    } as FormControlOptionModel)
    type: TopoActionType = undefined;
    actionParam: any = undefined;
}

class TopoActionTargetBaseModel extends TopoActionBaseModel {
    @FormControl({
        label: '目标组件',
        type: FormControlType.TOPO_TARGET_SELECT,
        mode: 'multiple',
        layout: { labelCol: 6, wrapperCol: 16, width: '100%' },
        index: 1
    } as FormControlOptionModel)
    targets: Array<string> = undefined;
}

export class TopoLinkActionModel extends TopoActionBaseModel {
    @FormControl({
        label: '跳转链接',
        type: FormControlType.TEXT,
        rows: 3,
        layout: { labelCol: 6, wrapperCol: 16, width: '100%' },
        index: 10
    } as FormControlTextModel)
    url: string = undefined;
    target: string = undefined;
}

export class TopoVisibleActionModel extends TopoActionTargetBaseModel {
    visible: boolean = true;
}

export class TopoReloadDataActionModel extends TopoActionTargetBaseModel {

}

export class TopoResetDataActionModel extends TopoActionTargetBaseModel {

}

export class TopoStyleActionModel extends TopoActionTargetBaseModel {

}

export class TopoBroadcastActionModel extends TopoActionBaseModel {
    @FormControl({
        label: '事件名称',
        type: FormControlType.TEXT,
        rows: 3,
        layout: { labelCol: 6, wrapperCol: 16, width: '100%' },
        index: 10
    } as FormControlTextModel)
    eventName: string = undefined;
    eventParam: any = undefined;
}
