

























































































import { Component, Prop } from 'vue-property-decorator';
import { BaseComponent } from '@/mixins/base-component';
import { QueryControlModel, QueryControlOptionModel, QueryControlType, QueryControlDateModel } from '@/model/query-control';
import CommonService from '@/service/common';

@Component
export default class JTLTableQueryComponent extends BaseComponent {
    @Prop()
    theme: string;
    queryModel: any = null;
    queryModelControls: Array<any> = null;
    QueryControlType = QueryControlType;
    totalLoadedCount: number = 1;
    loadedCount: number = 1; // 从1开始，因为已经默认有了uuid

    initQuery(queryModel: any) {
        if (!queryModel) {
            this.$emit('loaded');
            return;
        }
        this.queryModel = queryModel;
        this.loadedCount = 1;
        this.totalLoadedCount = _.filter(_.keys(this.queryModel), key => Reflect.getMetadata('QueryControl', this.queryModel, key)).length;
        const controlList = [];
        _.forEach(_.keys(this.queryModel), key => {
            const queryControlOption: any = Reflect.getMetadata('QueryControl', this.queryModel, key);
            if (queryControlOption) {
                queryControlOption.key = key;
                controlList.push(this.initQueryControlByType(queryControlOption));
            }
        });
        // this.queryModelControls = _.orderBy(_.filter(controlList, (item) => { return !item.invisible; }), 'index', 'asc');
        this.queryModelControls = _.orderBy(controlList, 'index', 'asc');
    }

    private initDefaultValue(queryControlOption, queryControl) {
        if (queryControlOption.defaultValue) {
            if (queryControlOption.mode === 'multiple') {
                if (queryControlOption.defaultValue === 'ALL') {
                    this.queryModel[queryControlOption.key] = _.map(queryControl.options, item => item.value);
                } else {
                    this.queryModel[queryControlOption.key] = _.map(_.get(queryControl.options, queryControlOption.defaultValue), item => item.value);
                }
            } else {
                this.queryModel[queryControlOption.key] = _.get(queryControl.options, queryControlOption.defaultValue);
            }
            if (queryControlOption.key === 'floor') {
                // 特殊逻辑
                this.queryModel['floorObj'] = _.get(queryControl.options, '[0].children[0]');
            }
        }
    }

    private initQueryControlByType(queryControlOption) {
        let queryControl = null;
        switch (queryControlOption.type) {
            case QueryControlType.SELECT:
            case QueryControlType.TREE_SELECT:
                queryControl = new QueryControlOptionModel(queryControlOption);
                if (queryControlOption.optionsPromise) {
                    let params = null;
                    // 如果参数是string类型，且是queryModel的字段，优先使用queryModel的字段值
                    if (typeof (queryControlOption.optionsPromiseParam) === 'string') {
                        params = _.keysIn(this.queryModel).indexOf(queryControlOption.optionsPromiseParam) > -1
                            ? this.queryModel[queryControlOption.optionsPromiseParam]
                            : queryControlOption.optionsPromiseParam;
                    } else {
                        params = queryControlOption.optionsPromiseParam;
                    }
                    queryControl.loading = true;
                    queryControlOption.optionsPromise(params).then(res => {
                        if (queryControlOption.hasAllOption) {
                            queryControl.options = this.getAllOptions(res);
                        } else {
                            queryControl.options = res;
                        }
                        this.initDefaultValue(queryControlOption, queryControl);
                        this.loadedCount++;
                        this.checkLoaded();
                    }).catch(err => $log.error(err)).finally(() => { queryControl.loading = false; });
                } else {
                    if (queryControlOption.hasAllOption) {
                        queryControl.options = this.getAllOptions(queryControlOption.options);
                    } else {
                        this.initDefaultValue(queryControlOption, queryControl);
                    }
                    this.loadedCount++;
                    this.checkLoaded();
                }
                break;
            case QueryControlType.SELECT_DATE:
                queryControl = new QueryControlDateModel(queryControlOption);
                this.loadedCount++;
                this.checkLoaded();
                break;
            case QueryControlType.RADIO_GROUP:
                queryControl = new QueryControlOptionModel(queryControlOption);
                this.loadedCount++;
                this.checkLoaded();
                break;
            case QueryControlType.TEXT:
            default:
                queryControl = new QueryControlModel(queryControlOption);
                this.loadedCount++;
                this.checkLoaded();
                break;
        }

        if (_.isFunction(queryControl.invisibleFunction)) {
            queryControl.invisible = queryControl.invisibleFunction(this.queryModel);
        }

        return queryControl;
    }

    checkLoaded() {
        if (this.loadedCount > this.totalLoadedCount) {
            // this.loadedCount = 1;
            setTimeout(() => {
                this.$emit('loaded');
            }, 100);
        }
    }

    onSelectChange(formControl) {
        _.forEach(this.queryModelControls, item => {
            if (item.invisibleFunction) {
                item.invisible = item.invisibleFunction(this.queryModel, this.queryModelControls);
            }
        });
        if (formControl.relationKeys && formControl.relationKeys.length > 0) {
            const relationFirstKey = formControl.relationKeys[0];
            const relationControl = _.find(this.queryModelControls, item => item.key === relationFirstKey);
            if (relationControl) {
                this.queryModel[relationFirstKey] = undefined;
                const relationPath = relationControl.relationPath;
                CommonService.getSubOptions(this.queryModel[formControl.key], relationPath).then(res => {
                    relationControl.options = res;
                });
            }
        }

        this.$emit('change', formControl);
    }

    validate() {
        if (!this.queryModelControls || this.queryModelControls.length === 0) {
            return true;
        }
        for (let i = 0; i < this.queryModelControls.length; i++) {
            if (this.queryModelControls[i].required && !this.queryModel[this.queryModelControls[i].key]) {
                this.showMessageWarning(`${this.queryModelControls[i].label}不能为空`);
                return false;
            }
        }

        return true;
    }
}

