<template>
    <canvas
        ref="elCanvas"
        :width="component.style.position.w"
        :height="component.style.position.h"
    >Your browser does not support the HTML5 canvas tag.</canvas>
</template>

<script>
import ViewCanvas from './view-canvas';

export default {
    name: 'ViewLine',
    extends: ViewCanvas,
    mounted() {
        this.onResize();
    },
    methods: {
        drawLine(x1, y1, x2, y2, lineWidth, color) {
            var el = this.$refs.elCanvas;
            var ctx = el.getContext('2d');
            ctx.beginPath();
            ctx.moveTo(x1, y1); // 设置起点状态
            ctx.lineTo(x2, y2); // 设置末端状态
            ctx.lineWidth = lineWidth; // 设置线宽状态
            ctx.strokeStyle = color; // 设置线的颜色状态
            ctx.stroke(); // 进行绘制
            ctx.closePath();
        },
        onResize() {
            var w = this.component.style.position.w;
            var h = this.component.style.position.h;
            var el = this.$refs.elCanvas;
            var ctx = el.getContext('2d');
            ctx.clearRect(0, 0, w, h);
            var x1 = 0;
            var y1 = h / 2;
            var x2 = w;
            var y2 = h / 2;
            var color = this.getForeColor();
            var lineWidth = this.component.style.lineWidth;
            if (lineWidth === undefined || typeof lineWidth !== 'number') {
                lineWidth = 2;
            }
            if (this.component.direction && this.component.direction === 'vertical') {
                // 竖线
                x1 = w / 2;
                x2 = w / 2;
                y1 = 0;
                y2 = h;
            }
            this.drawLine(x1, y1, x2, y2, lineWidth, color);
        }
    }
};
</script>
