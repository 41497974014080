






























import { Component, Prop } from 'vue-property-decorator';
import { BaseComponent } from '@/mixins/base-component';
import { ThingsFunctionType, ViewModeType } from '@/model/enum';
import CommonService from '@/service/common';

@Component
export default class ThingsAutoCompleteComponent extends BaseComponent {
    ViewModeType = ViewModeType;
    @Prop()
    value: any;
    @Prop()
    formModel: any;
    @Prop()
    placeholder: string;
    @Prop()
    disabled: boolean;
    @Prop()
    viewMode: ViewModeType;
    autoText: string = null;
    dataSource: Array<any> = null;
    loading: boolean = false;

    created() {
        this.autoText = this.formModel.name;
    }

    onSearch(searchText) {
        const trimSearchText = _.trim(searchText);
        if (trimSearchText) {
            this.dataSource = null;
            this.loading = true;
            CommonService.getThingsFunctionByType(this.formModel.functionType, searchText).then(res => {
                this.dataSource = res;
            }).finally(() => {
                this.loading = false;
            });
        }
    }

    onSelect(selectValueName: string) {
        const selectModel: any = _.find(this.dataSource, item => item.name === selectValueName);
        if (selectModel) {
            this.formModel.identifier = selectModel.identifier;
            this.formModel.attributeType = selectModel.attributeType;
            switch (this.formModel.functionType) {
                case ThingsFunctionType.ATTRIBUTE:
                    this.formModel.thingsModelTypeModel = selectModel.thingsModelTypeModel;
                    this.formModel.accessMode = selectModel.accessMode;
                    break;
                case ThingsFunctionType.EVENT:
                    this.formModel.outputData = selectModel.outputData;
                    this.formModel.level = selectModel.level;
                    break;
                case ThingsFunctionType.METHOD:
                    this.formModel.inputData = selectModel.inputData;
                    this.formModel.outputData = selectModel.outputData;
                    break;
            }
            this.formModel.remark = selectModel.remark;
        }
    }

    onChange() {
        this.$emit('input', this.autoText);
    }
}

