import { FormControl, FormControlOptionModel, FormControlTextModel, FormControlType } from '@/model/form-control';
import TopoStyleBaseModel from './topo-style-base-model';

export default class TopoStyleButtonModel extends TopoStyleBaseModel {
    @FormControl({
        label: 'Text',
        type: FormControlType.TEXT,
        layout: { labelCol: 8, wrapperCol: 12, width: '100%' },
        index: 91
    } as FormControlTextModel)
    text: string = undefined;

    @FormControl({
        label: 'Type',
        type: FormControlType.SELECT,
        options: [
            { value: 'primary', name: 'primary' },
            { value: 'dashed', name: 'dashed' },
            { value: 'danger', name: 'danger' },
            { value: 'link', name: 'link' }
        ],
        layout: { labelCol: 8, wrapperCol: 12, width: '100%' },
        index: 92
    } as FormControlOptionModel)
    type: string = undefined;

    @FormControl({
        label: 'Size',
        type: FormControlType.SELECT,
        options: [
            { value: 'small', name: 'small' },
            { value: 'default', name: 'default' },
            { value: 'large', name: 'large' }
        ],
        layout: { labelCol: 8, wrapperCol: 12, width: '100%' },
        index: 93
    } as FormControlOptionModel)
    size: string = undefined;

    toModel(options) {
        super.toModel(options);
        return this;
    }
}
