






















import { Component, Prop } from 'vue-property-decorator';
import { BaseComponent } from '@/mixins/base-component';
import { TimePlanRuleType } from '@/model/enum';

@Component
export default class MonthSeasonRuleComponent extends BaseComponent {
    TimePlanRuleType = TimePlanRuleType;
    @Prop()
    value: { type: string, month?: string, day?: string };
    @Prop()
    ruleType: TimePlanRuleType;
    // selectDay: string = null;
    // selectMonth: string = null;

    // get SelectDay() {
    //     if (this.ruleType === TimePlanRuleType.SEASON) {
    //         return _.get(this.value, 'day');
    //     }
    //     return this.value;
    // }

    // get SelectMonthRule() {
    //     if (this.SelectDay !== JTL.CONSTANT.LAST_DAY_OF_MONTH_FLAG) {
    //         this.selectDay = this.SelectDay;
    //         return JTL.CONSTANT.CUSTOM_DAY_OF_MONTH_FLAG;
    //     }
    //     return JTL.CONSTANT.LAST_DAY_OF_MONTH_FLAG;
    // }
    // set SelectMonthRule(val: string) {
    //     if (this.SelectDay === JTL.CONSTANT.CUSTOM_DAY_OF_MONTH_FLAG) {
    //         this.$emit('input', this.initSelectValue());
    //     } else {
    //         this.$emit('input', this.initSelectValue(val));
    //     }
    //     this.$emit('change');
    // }

    dayOnChange() {
        // this.$emit('input', this.initSelectValue());
        this.value.type = JTL.CONSTANT.CUSTOM_DAY_OF_MONTH_FLAG;
        this.$emit('change');
    }

    monthOnChange() {
        // this.$emit('input', this.initSelectValue());
        this.$emit('change');
    }

    // initSelectValue(val?: string) {
    //     if (this.ruleType === TimePlanRuleType.SEASON) {
    //         return {
    //             month: this.selectMonth,
    //             day: val || this.selectDay || JTL.CONSTANT.LAST_DAY_OF_MONTH_FLAG
    //         };
    //     }
    //     return val || this.selectDay || JTL.CONSTANT.LAST_DAY_OF_MONTH_FLAG;
    // }

    validate() {
        if (this.ruleType === TimePlanRuleType.SEASON && !this.value.month) {
            this.showMessageWarning('请选择每季度月份');
            return false;
        }

        if (this.value.type === JTL.CONSTANT.CUSTOM_DAY_OF_MONTH_FLAG && !this.value.day) {
            this.showMessageWarning('请选择月规则自定义日期');
            return false;
        }
        return true;
    }
}

