var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "jtl-upload-component" },
    [
      _c(
        "a-upload",
        {
          staticClass: "avatar-uploader",
          attrs: {
            accept: _vm.accept,
            "list-type": _vm.listType,
            "show-upload-list": _vm.showUploadList,
            "file-list": _vm.FileList,
            "before-upload": _vm.handleBeforeUpload,
            disabled: _vm.disabled,
            "custom-request": _vm.doImgUpload
          },
          on: { change: _vm.handleChange, preview: _vm.handlePreview }
        },
        [
          !_vm.disabled && _vm.value.length < _vm.maxFileNumber
            ? _c("div", [
                _vm.listType === "picture-card"
                  ? _c(
                      "div",
                      [
                        _c("a-icon", { attrs: { type: "plus" } }),
                        _c("div", { staticClass: "ant-upload-text" }, [
                          _vm._v("上传")
                        ])
                      ],
                      1
                    )
                  : _c(
                      "div",
                      [
                        _c(
                          "a-button",
                          { attrs: { disabled: _vm.disabled } },
                          [
                            _c("a-icon", { attrs: { type: "upload" } }),
                            _vm._v(" Upload ")
                          ],
                          1
                        )
                      ],
                      1
                    )
              ])
            : _vm._e()
        ]
      ),
      _c(
        "a-modal",
        {
          staticClass: "jtl-upload-preview",
          attrs: {
            visible: _vm.previewVisible,
            footer: null,
            "mask-closable": ""
          },
          on: { cancel: _vm.handleCancel }
        },
        [
          _vm.previewType === "video"
            ? _c("video", {
                staticStyle: { width: "100%" },
                attrs: {
                  alt: "Image",
                  src: _vm.previewImage,
                  controls: "controls"
                }
              })
            : _vm.previewType === "image"
            ? _c("img", {
                staticStyle: { width: "100%" },
                attrs: { alt: "Image", src: _vm.previewImage }
              })
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }