import { generateUUID } from './base_util';

export default function convertRes2Blob(response) {
    // debugger;
    const contentDisposition = response.headers['content-disposition'];
    let fileName = null;

    // 提取文件名
    if (contentDisposition) {
        fileName = contentDisposition.match(/filename=(.*)/)[1];
    } else {
        fileName = `${generateUUID()}.xlsx`;
    }

    // 将二进制流转为blob
    let blob = null;
    if (response.headers['content-type'] === 'application/json') {
        blob = new Blob([JSON.stringify(response.data)], { type: 'application/json' });
    } else {
        blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    }
    // if (response.headers.filetype === 'xlsx') {
    //     blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    // } else {
    //     blob = new Blob([JSON.stringify(response.data)], { type: 'application/octet-stream' });
    // }

    // const blob = new Blob([JSON.stringify(response.data)], { type: 'application/octet-stream' });
    // const blob = new Blob([response.data], { type: 'application/vnd.ms-excel' });
    // const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    if (typeof window.navigator.msSaveBlob !== 'undefined') {
        // 兼容IE，window.navigator.msSaveBlob：以本地方式保存文件
        window.navigator.msSaveBlob(blob, decodeURI(fileName));
    } else {
        // 创建新的URL并指向File对象或者Blob对象的地址
        const blobURL = window.URL.createObjectURL(blob);
        // 创建a标签，用于跳转至下载链接
        const tempLink = document.createElement('a');
        tempLink.style.display = 'none';
        tempLink.href = blobURL;
        tempLink.setAttribute('download', decodeURI(fileName));
        // 兼容：某些浏览器不支持HTML5的download属性
        if (typeof tempLink.download === 'undefined') {
            tempLink.setAttribute('target', '_blank');
        }
        // 挂载a标签
        document.body.appendChild(tempLink);
        tempLink.click();
        document.body.removeChild(tempLink);
        // 释放blob URL地址
        window.URL.revokeObjectURL(blobURL);
    }
}
