











import { Component } from 'vue-property-decorator';
import TopoBaseView from '../topo-base-view';

@Component
export default class ViewDropdown extends TopoBaseView {
    get DropdownText() {
        return this.ComponentData;
    }
    get Size() {
        return _.get(this.component, 'style.size');
    }
    loginOutClick() {
        $topoEventHub.$emit('TOPO_LOGOUT_EVENT_HUB');
    }
}
