import { BaseModel } from '@/model/base-model';
import { FormControl, FormControlModel, FormControlOptionModel, FormControlTextModel, FormControlType } from '@/model/form-control';

export default class TopoStyleBaseModel extends BaseModel {
    position: TopoPositionModel = undefined;

    @FormControl({
        label: 'Border Width',
        type: FormControlType.NUMBER,
        layout: { labelCol: 8, wrapperCol: 12, width: '100%' },
        index: 100
    } as FormControlTextModel)
    borderWidth: number = undefined;

    @FormControl({
        label: 'Border Style',
        type: FormControlType.SELECT,
        options: [
            { value: 'solid', name: 'solid' },
            { value: 'dashed', name: 'dashed' },
            { value: 'dotted', name: 'dotted' }
        ],
        layout: { labelCol: 8, wrapperCol: 12, width: '100%' },
        index: 101
    } as FormControlOptionModel)
    borderStyle: string = undefined;

    @FormControl({
        label: 'Border Color',
        type: FormControlType.TEXT,
        inputType: 'color',
        layout: { labelCol: 8, wrapperCol: 12, width: '100%' },
        index: 102
    } as FormControlTextModel)
    borderColor: string = undefined;

    @FormControl({
        label: 'Visible',
        type: FormControlType.SELECT,
        options: [
            { name: 'visible', value: 'visible' },
            { name: 'invisible', value: 'invisible' }
        ],
        layout: { labelCol: 8, wrapperCol: 12, width: '100%' },
        index: 103
    } as FormControlOptionModel)
    visible: string = undefined;

    @FormControl({
        label: 'z-index',
        type: FormControlType.NUMBER,
        layout: { labelCol: 8, wrapperCol: 12, width: '100%' },
        index: 104
    } as FormControlTextModel)
    zIndex: number = undefined;

    @FormControl({
        label: 'transform',
        type: FormControlType.NUMBER,
        layout: { labelCol: 8, wrapperCol: 12, width: '100%' },
        index: 105
    } as FormControlTextModel)
    transform: number = undefined;

    @FormControl({
        label: 'Back Color',
        type: FormControlType.TEXT,
        inputType: 'color',
        layout: { labelCol: 8, wrapperCol: 12, width: '100%' },
        index: 106
    } as FormControlTextModel)
    backColor: string = undefined;

    // backGround: string = undefined;
    // overflow: string = undefined;

    @FormControl({
        label: 'ClassName',
        type: FormControlType.TEXT,
        layout: { labelCol: 8, wrapperCol: 12, width: '100%' },
        index: 106.1
    } as FormControlTextModel)
    className: string = undefined;

    @FormControl({
        label: 'Fore Color',
        type: FormControlType.TEXT,
        inputType: 'color',
        layout: { labelCol: 8, wrapperCol: 12, width: '100%' },
        index: 107
    } as FormControlTextModel)
    foreColor: string = undefined;

    @FormControl({
        label: 'Text Align',
        type: FormControlType.SELECT,
        options: [
            { value: 'left', name: 'left' },
            { value: 'right', name: 'right' },
            { value: 'center', name: 'center' },
            { value: 'justify', name: 'justify' }
        ],
        layout: { labelCol: 8, wrapperCol: 12, width: '100%' },
        index: 108
    } as FormControlOptionModel)
    textAlign: string = undefined;

    @FormControl({
        label: 'Font Family',
        type: FormControlType.SELECT,
        options: [
            { value: 'Arial', name: 'Arial' },
            { value: 'Helvetica', name: 'Helvetica' },
            { value: 'sans-serif', name: 'sans-serif' },
            { value: '宋体', name: '宋体' },
            { value: '黑体', name: '黑体' },
            { value: '微软雅黑', name: '微软雅黑' }
        ],
        layout: { labelCol: 8, wrapperCol: 12, width: '100%' },
        index: 109
    } as FormControlOptionModel)
    fontFamily: string = undefined;

    fontWeight: string = undefined;

    @FormControl({
        label: 'Font Size',
        type: FormControlType.NUMBER,
        layout: { labelCol: 8, wrapperCol: 12, width: '100%' },
        index: 110
    } as FormControlTextModel)
    fontSize: number = undefined;

    @FormControl({
        label: 'Line Height',
        type: FormControlType.NUMBER,
        layout: { labelCol: 8, wrapperCol: 12, width: '100%' },
        index: 111
    } as FormControlTextModel)
    lineHeight: number = undefined;

    @FormControl({
        label: 'Cursor',
        type: FormControlType.TEXT,
        layout: { labelCol: 8, wrapperCol: 12, width: '100%' },
        index: 112
    } as FormControlTextModel)
    cursor: string = undefined;

    @FormControl({
        label: '移动',
        type: FormControlType.CHECKBOX,
        layout: { labelCol: 8, wrapperCol: 12, width: '100%' }
    } as FormControlModel<boolean>)
    move: boolean = false;

    @FormControl({
        label: '缩放',
        type: FormControlType.CHECKBOX,
        layout: { labelCol: 8, wrapperCol: 12, width: '100%' }
    } as FormControlModel<boolean>)
    zoom: boolean = false;

    get Visible(): boolean {
        if (this.visible === undefined || this.visible === 'visible') {
            return true;
        }
        return false;
    }
    set Visible(val: boolean) {
        this.visible = val ? 'visible' : 'invisible';
    }

    toModel(options) {
        super.toModel(options);
        this.position = new TopoPositionModel().toModel(_.get(options, 'position'));
        if (_.get(options, 'visible')) {
            this.Visible = !(_.get(options, 'visible') === 'invisible');
        }
        return this;
    }

    get ComponentStyle() {
        return Object.assign({
            'background-color': this.backColor,
            // background: this.backGround,
            zIndex: this.zIndex,
            borderWidth: this.borderWidth ? `${this.borderWidth}px` : null,
            borderStyle: this.borderStyle,
            borderColor: this.borderColor,
            transform: this.transform ? `rotate(${this.transform}deg)` : 'none',
            cursor: this.cursor,
            color: this.foreColor,
            textAlign: this.textAlign,
            fontSize: `${this.fontSize}px`,
            fontFamily: this.fontFamily,
            fontWeight: this.fontWeight,
            lineHeight: this.lineHeight
            // overflow: this.overflow
        }, this.position.PositionStyle);
    }

    get ClassName() {
        return this.className;
    }

    tempPosition: any;
}

class TopoPositionModel extends BaseModel {
    @FormControl({
        label: 'Left',
        type: FormControlType.NUMBER,
        layout: { labelCol: 8, wrapperCol: 12, width: '100%' }
    } as FormControlTextModel)
    left: number = undefined;

    @FormControl({
        label: 'Top',
        type: FormControlType.NUMBER,
        layout: { labelCol: 8, wrapperCol: 12, width: '100%' }
    } as FormControlTextModel)
    top: number = undefined;

    @FormControl({
        label: 'Right',
        type: FormControlType.NUMBER,
        layout: { labelCol: 8, wrapperCol: 12, width: '100%' }
    } as FormControlTextModel)
    right: number = undefined;

    @FormControl({
        label: 'Bottom',
        type: FormControlType.NUMBER,
        layout: { labelCol: 8, wrapperCol: 12, width: '100%' }
    } as FormControlTextModel)
    bottom: number = undefined;

    @FormControl({
        label: 'Width',
        type: FormControlType.TEXT,
        layout: { labelCol: 8, wrapperCol: 12, width: '100%' }
    } as FormControlTextModel)
    w: number | string = undefined;

    @FormControl({
        label: 'Height',
        type: FormControlType.TEXT,
        layout: { labelCol: 8, wrapperCol: 12, width: '100%' }
    } as FormControlTextModel)
    h: number | string = undefined;

    @FormControl({
        label: 'Fixed',
        type: FormControlType.CHECKBOX,
        layout: { labelCol: 8, wrapperCol: 12, width: '100%' },
        index: 113
    } as FormControlModel<boolean>)
    fixed: boolean = false;

    relative: boolean = false;
    margin: string = undefined;

    get Width():string {
        return typeof (this.w) === 'string' ? this.w : `${this.w}px`;
    }

    get Height():string {
        return typeof (this.h) === 'string' ? this.h : `${this.h}px`;
    }

    get Left() {
        if (_.isNumber(this.left)) {
            return `${this.left}px`;
        } else if (_.isString(this.left)) {
            return this.left;
        }
        return 'auto';
        // return _.isNumber(this.left) ? `${this.left}px` : 'auto';
    }
    get Top() {
        if (_.isNumber(this.top)) {
            return `${this.top}px`;
        } else if (_.isString(this.top)) {
            return this.top;
        }
        return 'auto';
        // return _.isNumber(this.top) ? `${this.top}px` : 'auto';
    }
    get Right() {
        if (_.isNumber(this.right)) {
            return `${this.right}px`;
        } else if (_.isString(this.right)) {
            return this.right;
        }
        return 'auto';
        // return _.isNumber(this.right) ? `${this.right}px` : 'auto';
    }
    get Bottom() {
        if (_.isNumber(this.bottom)) {
            return `${this.bottom}px`;
        } else if (_.isString(this.bottom)) {
            return this.bottom;
        }
        return 'auto';
        // return _.isNumber(this.bottom) ? `${this.bottom}px` : 'auto';
    }

    get PositionStyle() {
        const positionStyle: any = {
            left: this.Left,
            top: this.Top,
            right: this.Right,
            bottom: this.Bottom,
            width: this.Width,
            height: this.Height
        };
        if (this.fixed) {
            positionStyle.position = 'fixed';
        }
        if (this.relative) {
            positionStyle.position = 'relative';
            positionStyle.display = 'inline-block';
            positionStyle.margin = this.margin;
        }
        return positionStyle;
    }
}
