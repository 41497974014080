











import { Component, Vue } from 'vue-property-decorator';
import TopoBasePanelView from '../topo-base-panel-view';

@Component
export default class ViewPanel extends TopoBasePanelView {
}

