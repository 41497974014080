








import { TopoStyleLineDashedModel } from '@/pages/topo/model/style-model/topo-style-line-model';
import { Component } from 'vue-property-decorator';
import ViewCanvas from './view-canvas';
let stop = null;

@Component
export default class ViewDashed extends ViewCanvas {
    get Style(): TopoStyleLineDashedModel {
        return this.component.style as TopoStyleLineDashedModel;
    }
    // 虚线样式
    get setLineDash() {
        let setLineDash = this.Style.setLineDash ? this.Style.setLineDash.split(',') : [5, 5];
        return setLineDash;
    }
    // 点之间的间隔
    get dotSpace() {
        return this.Style.dotSpace || 10;
    }
    // 点宽度
    get dotWidth() {
        return this.Style.dotWidth || 5;
    }
    mounted() {
        this.onResize();
    }

    drawLine(options) {
        let x1 = options.x1; let y1 = options.y1; let x2 = options.x2; let y2 = options.y2; let lineWidth = options.lineWidth; let color = options.color;
        var el: any = this.$refs.elCanvas;
        var ctx = el.getContext('2d');

        // console.log('setLineDash :', setLineDash);
        /**
       * 第一个参数用于规定第一个虚线的长度。
       * 第二个参数用于规定第一个虚线与第二个虚线之间的间隔。
       * 第三个参数用于规定第二个虚线的长度。
       */
        ctx.setLineDash(this.setLineDash);
        ctx.lineWidth = lineWidth; // 设置线宽状态
        ctx.strokeStyle = color; // 设置线的颜色状态
        ctx.beginPath();
        ctx.moveTo(x1, y1); // 设置起点状态
        ctx.lineTo(x2, y2); // 设置末端状态
        ctx.stroke(); // 进行绘制
        ctx.closePath();
    }
    onResize() {
        var w = this.component.style.position.w as number;
        var h = this.component.style.position.h as number;
        var el: any = this.$refs.elCanvas;
        var ctx = el.getContext('2d');
        ctx.clearRect(0, 0, w, h);
        var x1 = 0;
        var y1 = h / 2;
        var x2 = w;
        var y2 = h / 2;
        var color = this.getForeColor();
        var lineWidth = this.Style.lineWidth;
        // console.log('lineWidth :', lineWidth, typeof lineWidth);
        if (lineWidth === undefined) {
            lineWidth = 2;
        }
        // if (this.component.direction && this.component.direction === 'vertical') {
        //     // 竖线
        //     x1 = w / 2;
        //     x2 = w / 2;
        //     y1 = 0;
        //     y2 = h;
        // }
        // 使用动画
        // let animations = this.component.style.animations;
        let animations = null;
        // console.log('animations :', animations);
        let options = { x1: x1, y1: y1, x2: x2, y2: y2, lineWidth: lineWidth, color: color };
        if (animations && animations.value) {
            switch (animations.value) {
                case 'up':
                    this.drawColUpLine(options);
                    break;
                case 'right':
                    this.drawRowRightLine(options);
                    break;
                case 'down':
                    this.drawColDownLine(options);
                    break;
                case 'left':
                    this.drawRowLeftLine(options);
                    break;
            }
        } else {
            cancelAnimationFrame(stop);// 可以取消该次动画。
            this.drawLine(options);
        }
    }
    // 循环执行
    doLopo(el, ctx, drawLine) {
        // 画布渲染
        var render = function() {
            // 清除画布
            ctx.clearRect(0, 0, el.width, el.height);
            // 绘制(在canvas画布上绘制图形的代码)
            drawLine();
            // 继续渲染
            stop = requestAnimationFrame(render);
        };
        render();
    }
    // 动态画横线-右
    drawRowRightLine(options) {
        let x1 = options.x1; let y1 = options.y1; let x2 = options.x2; let y2 = options.y2; let lineWidth = options.lineWidth; let color = options.color;
        var el: any = this.$refs.elCanvas;
        var ctx = el.getContext('2d');
        ctx.lineWidth = lineWidth; // 设置线宽状态
        ctx.strokeStyle = color; // 设置线的颜色状态
        let dotSpace = this.dotSpace;
        let dotWidth = this.dotWidth;
        let dotsNum = x2 / dotSpace;
        let index = 0;
        function drawLine() {
            index++;
            // 生成线
            for (let i = 0; i < dotsNum; i++) {
                let x = x1 + dotSpace * i + index;
                ctx.beginPath();
                ctx.moveTo(x, y1); // 设置起点状态
                ctx.lineTo(x + dotWidth, y2); // 设置末端状态
                ctx.stroke(); // 进行绘制

                ctx.closePath();
            }
            if (index === dotSpace) {
                index = 0;
            }
        }
        this.doLopo(el, ctx, drawLine);
    }
    // 动态画横线-左
    drawRowLeftLine(options) {
        let x1 = options.x1; let y1 = options.y1; let x2 = options.x2; let y2 = options.y2; let lineWidth = options.lineWidth; let color = options.color;
        var el: any = this.$refs.elCanvas;
        var ctx = el.getContext('2d');
        ctx.lineWidth = lineWidth; // 设置线宽状态
        ctx.strokeStyle = color; // 设置线的颜色状态
        let dotSpace = this.dotSpace;
        let dotWidth = this.dotWidth;
        let dotsNum = x2 / dotSpace;
        let index = 0;
        function drawLine() {
            index++;
            // 生成线
            for (let i = dotsNum; i > 0; i--) {
                let x = x1 + dotSpace * i - index;
                ctx.beginPath();

                ctx.moveTo(x, y1); // 设置起点状态
                ctx.lineTo(x - dotWidth, y2); // 设置末端状态
                ctx.stroke(); // 进行绘制

                ctx.closePath();
            }
            if (index === dotSpace) {
                index = 0;
            }
        }
        this.doLopo(el, ctx, drawLine);
    }
    // 动态画向上竖线
    drawColUpLine(options) {
        let x1 = options.x1; let y1 = options.y1; let x2 = options.x2; let y2 = options.y2; let lineWidth = options.lineWidth; let color = options.color;
        console.log('drawColUpLine:', x1, y1, x2, y2, lineWidth, color); // 15 0 15 200 2 grey
        var el: any = this.$refs.elCanvas;
        var ctx = el.getContext('2d');
        ctx.lineWidth = lineWidth; // 设置线宽状态
        ctx.strokeStyle = color; // 设置线的颜色状态
        let dotSpace = this.dotSpace;
        let dotWidth = this.dotWidth;
        let dotsNum = y2 / dotSpace;
        let index = 0;
        function drawLine() {
            index++;
            // 生成线
            for (let i = dotsNum; i > 0; i--) {
                let y = y1 + dotSpace * i - index;
                ctx.beginPath();
                ctx.moveTo(x1, y); // 设置起点状态
                ctx.lineTo(x2, y - dotWidth); // 设置末端状态
                ctx.stroke(); // 进行绘制

                ctx.closePath();
            }
            if (index === dotSpace) {
                index = 0;
            }
        }
        this.doLopo(el, ctx, drawLine);
    }
    // 动态画向下竖线
    drawColDownLine(options) {
        let x1 = options.x1; let y1 = options.y1; let x2 = options.x2; let y2 = options.y2; let lineWidth = options.lineWidth; let color = options.color;
        var el: any = this.$refs.elCanvas;
        var ctx = el.getContext('2d');
        let dotSpace = this.dotSpace;
        let dotWidth = this.dotWidth;
        ctx.lineWidth = lineWidth; // 设置线宽状态
        ctx.strokeStyle = color; // 设置线的颜色状态

        let dotsNum = y2 / dotSpace;
        let index = 0;
        function drawLine() {
            index++;
            // 生成线
            for (let i = 0; i < dotsNum; i++) {
                let y = y1 + dotSpace * i + index;
                ctx.beginPath();

                ctx.moveTo(x1, y); // 设置起点状态
                ctx.lineTo(x2, y + dotWidth); // 设置末端状态
                ctx.stroke(); // 进行绘制

                ctx.closePath();
            }
            if (index === dotSpace) {
                index = 0;
            }
        }
        this.doLopo(el, ctx, drawLine);
    }
}
