var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "jtl-things-function-label-component inline-block" },
    [
      _c(
        "a-tag",
        { staticClass: "transform-tag", attrs: { color: _vm.NameColor } },
        [
          _vm._v(
            " " +
              _vm._s(_vm._f("thingsModelFunctionFilter")(_vm.functionType)) +
              " "
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }