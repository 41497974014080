
import { OrgEntityModel, OrgQueryModel } from '@/entity-model/org-entity';
import { ICRUDQ } from '@/model/interface';
import { post, del, get } from './request';
import { GroupEntityType } from '@/model/enum';
import { UserEntityModel } from '@/entity-model/user-entity';
import { SimpleTreeModel } from '@/model/simple-tree-model';

const URL_PATH = `${AUTH_BASE_REQUEST_PATH}/orgs`;

class OrgService implements ICRUDQ<OrgEntityModel, OrgQueryModel> {
    async create(model: OrgEntityModel):Promise<OrgEntityModel> {
        const url = `${URL_PATH}/save`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async retrieve(modelId: string):Promise<OrgEntityModel> {
        const url = `${URL_PATH}/detail/${modelId}`;
        const res = await get(url);
        // return new OrgEntityModel().toModel(res);
        return res;
    }

    async update(model: OrgEntityModel):Promise<OrgEntityModel> {
        const url = `${URL_PATH}/save`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async delete(model: OrgEntityModel):Promise<OrgEntityModel> {
        const url = `${URL_PATH}/delete/${model.id}`;
        const res = await del(url);
        return res;
    }

    async query(query?: OrgQueryModel, page?: number, limit?: number):Promise<any> {
        const params = Object.assign({ page, limit }, query?.toService());
        const url = `${URL_PATH}/orgUserList/${(query as any).groupIds[0]}`;
        const res = await post(url, params);
        if (res) {
            res.items = _.map(_.get(res, 'items'), item => item = new UserEntityModel().toModel(item));
        }
        return res;
    }

    async getOrgTree() {
        const url = `${URL_PATH}/getOrgs`;
        const res = await get(url);
        return _.map(res, item => item = new OrgEntityModel().toModel(Object.assign({ type: GroupEntityType.ORG }, item)));
    }

    async getOrgUserTree() {
        const url = `${URL_PATH}/getOrgUserWithTree`;
        const res = await get(url);
        return _.map(res, item => item = new OrgEntityModel().toModel(Object.assign({ type: GroupEntityType.ORG }, item)));
    }

    async getSelectOrgTree() {
        const url = `${URL_PATH}/getOrgs`;
        const res = await get(url);
        return _.map(res, item => {
            return new SimpleTreeModel().toModel(item);
        });
    }

    async addOrgUsers(orgId: string, userIds: Array<string>) {
        const url = `${URL_PATH}/addOrgUsers`;
        const param = {
            orgId,
            userIds
        };
        return await post(url, param);
    }

    async deleteOrgUsers(orgId: string, userId: string) {
        const url = `${URL_PATH}/removeUser/${orgId}/${userId}`;
        const res = await del(url);
        return res;
    }
}

export default new OrgService();
