import { FacilityAttributeDataType } from '@/model/enum';
const facilityAttributeDataTypeList: Array<{ name: string, value: FacilityAttributeDataType }> = _.filter(_.map(FacilityAttributeDataType, item => {
    let name: string = null;
    switch (item) {
        case FacilityAttributeDataType.DEVICE:
            name = '关联设备';
            break;
        case FacilityAttributeDataType.INT:
            name = 'int32(整数型)';
            break;
        case FacilityAttributeDataType.FLOAT:
            name = 'float(单精度浮点型)';
            break;
        case FacilityAttributeDataType.BOOLEAN:
            name = 'bool(布尔型)';
            break;
        case FacilityAttributeDataType.STRING:
            name = 'text(字符串)';
            break;
        case FacilityAttributeDataType.PEOPLE:
            name = '关联人员';
            break;
        case FacilityAttributeDataType.ATTRIBUTE:
            name = '关联设备属性';
            break;
        case FacilityAttributeDataType.SPACE:
            name = '关联空间';
            break;
        case FacilityAttributeDataType.SERVER_ATTRIBUTE:
            name = '服务端属性';
            break;
        case FacilityAttributeDataType.ENUM:
            name = '枚举类型';
            break;
    }
    return { name, value: item };
}), item => item.name) as any;
const facilityAttributeDataTypeFilter = (val: FacilityAttributeDataType) => {
    return _.get(_.find(facilityAttributeDataTypeList, item => item.value === val), 'name') || '-';
};
export {
    facilityAttributeDataTypeList,
    facilityAttributeDataTypeFilter
};
