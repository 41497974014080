import { BaseEntityModel } from '@/model/base-model';
import { FormControl, FormControlType, FormControlTextModel, FormControlOptionModel } from '@/model/form-control';
import { QueryControl, QueryControlType } from '@/model/query-control';
import { QueryPageModel } from '@/model/query-model';
import { MOBILE_REGEXP, EMAIL_REGEXP, NUMBER_LETTER_UNDERLINE_REGEXP } from '@/model/regexp';
import CommonService from '@/service/common';
import { UserStoreModule } from '@/store/modules/user';

export class UserPwdModel extends BaseEntityModel {
    @FormControl({
        label: '重置密码',
        type: FormControlType.TEXT,
        min: 6,
        max: 16,
        // pattern: NUMBER_LETTER_UNDERLINE_REGEXP,
        required: true,
        placeholder: '请输入6到16个字符，包含字母、数字'
    } as FormControlTextModel)
    password: string = undefined;
}
export class UserSetBaseInfoModel extends BaseEntityModel {
    @FormControl({
        label: '姓名',
        type: FormControlType.TEXT,
        required: true
    } as FormControlTextModel)
    name: string = undefined;

    @FormControl({
        label: '手机号',
        type: FormControlType.TEXT,
        pattern: MOBILE_REGEXP,
        required: true
    } as FormControlTextModel)
    phone: string = undefined;

    @FormControl({
        label: '性别',
        type: FormControlType.RADIO_GROUP,
        options: [
            { value: 0, name: '男' },
            { value: 1, name: '女' }
        ],
        required: true
    } as FormControlOptionModel)
    sex: number = undefined;

    @FormControl({
        label: '职位',
        type: FormControlType.TEXT
    } as FormControlTextModel)
    postName: string = undefined;

    toModel(json): any {
        super.toModel(json);
        this.sex = parseInt(_.get(json, 'sex'));
        return this;
    }
}
export class UserEntityModel extends BaseEntityModel {
    userId: string = undefined;
    @FormControl({
        label: '工号',
        type: FormControlType.TEXT
    } as FormControlTextModel)
    jobNo: undefined = undefined;

    @FormControl({
        label: '姓名',
        type: FormControlType.TEXT,
        required: true
    } as FormControlTextModel)
    name: string = undefined;

    @FormControl({
        label: '手机号',
        type: FormControlType.TEXT,
        pattern: MOBILE_REGEXP,
        required: true
    } as FormControlTextModel)
    phone: string = undefined;

    @FormControl({
        label: '性别',
        type: FormControlType.RADIO_GROUP,
        options: [
            { value: 0, name: '男' },
            { value: 1, name: '女' }
        ],
        required: true
    } as FormControlOptionModel)
    sex: number = undefined;

    @FormControl({
        label: '职位',
        type: FormControlType.TEXT
    } as FormControlTextModel)
    postName: string = undefined;

    @FormControl({
        label: '邮箱',
        type: FormControlType.TEXT,
        pattern: EMAIL_REGEXP
    } as FormControlTextModel)
    email: string = undefined;

    @FormControl({
        label: '公司',
        type: FormControlType.TEXT
    } as FormControlTextModel)
    company: string = undefined;

    // @FormControl({
    //     label: '角色',
    //     type: FormControlType.SELECT,
    //     optionsPromise: CommonService.getAllRolesV2,
    //     required: true
    // } as FormControlOptionModel)
    roleId: string = undefined;
    roleName: string = undefined;

    @FormControl({
        label: '初始密码',
        type: FormControlType.TEXT,
        min: 6,
        // max: 16,
        // pattern: NUMBER_LETTER_UNDERLINE_REGEXP,
        required: true,
        placeholder: '请输入6到16个字符，包含字母、数字',
        updateHidden: true
    } as FormControlTextModel)
    password: string = undefined;

    // @FormControl({
    //     label: '是否启用',
    //     type: FormControlType.RADIO_GROUP,
    //     options: [
    //         { value: true, name: '是' },
    //         { value: false, name: '否' }
    //     ],
    //     required: true
    // } as FormControlOptionModel)
    // enabled: boolean = true;

    tenantId: string = undefined;

    get key() {
        return this.id;
    }

    static getTableColumns() {
        const columns: Array<any> = [
            {
                title: '姓名',
                dataIndex: 'name',
                scopedSlots: { customRender: 'name' },
                width: 150,
                ellipsis: true
            },
            {
                title: '工号',
                dataIndex: 'jobNo',
                width: 100
            },
            {
                title: '手机号',
                dataIndex: 'phone',
                width: 150
            },
            {
                title: '邮箱',
                dataIndex: 'email'
            },
            {
                title: '职位',
                dataIndex: 'postName'
            },
            {
                title: '公司',
                dataIndex: 'company'
            },
            {
                title: '角色',
                dataIndex: 'roleName',
                width: 150
            },
            {
                title: '操作',
                dataIndex: 'action',
                scopedSlots: { customRender: 'action' },
                width: 200
            }
        ];
        return columns;
    }

    toModel(json): any {
        super.toModel(json);
        this.sex = parseInt(_.get(json, 'sex'));
        return this;
    }

    toService() {
        const data: any = super.toService();
        if (this.id) {
            delete data.password;
        }
        data.projectId = UserStoreModule.CurrentProjectId;
        return data;
    }
}
export class UserQueryModel extends QueryPageModel {
    @QueryControl({
        label: '名称/手机号',
        type: QueryControlType.TEXT,
        span: 12
    })
    name: string = undefined;

    toService() {
        return {
            params: {
                phone: this.name
            }
        };
    }
}
