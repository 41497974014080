
import { DeviceEntityModel, SelectDeviceModel, DeviceQueryModel, DeviceImportExcelEntityModel } from '@/entity-model/device-entity';
import { ICRUDQ } from '@/model/interface';
import { get, post, del, download } from './request';
// import detailMockData from '@/json/device-detail.json';

// function getDetailMockData() {
//     return new Promise((resolve, reject) => {
//         resolve(detailMockData.items);
//     });
// }

const URL_PATH = `${BI_BASE_REQUEST_PATH}/device`;

class DeviceService implements ICRUDQ<DeviceEntityModel, DeviceQueryModel> {
    async create(model: DeviceEntityModel):Promise<DeviceEntityModel> {
        let url = null;

        if ((model as any).isImport) {
            url = `${URL_PATH}/childrenbatchsave`;
        } else if ((model as any).isCopy) {
            url = `${URL_PATH}/childrenbatchcopy`;
        } else {
            url = `${URL_PATH}/save`;
        }

        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async retrieve(modelId: string):Promise<any> {
        const url = `${URL_PATH}/detail/${modelId}`;
        // const res = await getDetailMockData();
        const res = await get(url);
        return {
            deviceModel: new DeviceEntityModel().toModel(Object.assign({}, _.get(res, 'device'), { parentPath: _.get(res, 'parentPath') })),
            serverAttrs: _.get(res, 'serverAttrs'),
            clientAttrs: _.map(_.get(res, 'clientAttrs'), item => {
                item.jsonValue.attrstring = null;
                item.jsonValue.variablename = null;
                item.jsonValue.address = null;
                item.jsonValue.len = null;
                return item.jsonValue;
            })
            // clientAttrs:_.get(res, 'clientAttrs')
        };
    }

    async update(model: DeviceEntityModel):Promise<DeviceEntityModel> {
        const url = `${URL_PATH}/save`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async delete(model: DeviceEntityModel):Promise<DeviceEntityModel> {
        const url = `${URL_PATH}/delete/${model.id}`;
        const res = await del(url);
        return res;
    }

    async query(query?: DeviceQueryModel, page?: number, limit?: number):Promise<any> {
        const params = Object.assign({ page, limit }, query?.toService());

        if (_.get(params, 'params.deviceId')) {
            const url = `${URL_PATH}/getRelatedDevices/${_.get(params, 'params.deviceId')}`;
            const res = await post(url, params);
            return _.map(res, item => item = new DeviceEntityModel().toModel(item));
        } else {
            const url = `${URL_PATH}/list`;
            const res = await post(url, params);
            res.items = _.map(res.items, item => item = new DeviceEntityModel().toModel(item));
            return res;
        }
    }

    async listFacDevs(query?: DeviceQueryModel, page?: number, limit?: number):Promise<any> {
        const params:any = Object.assign({ page, limit }, query?.toService());
        params.params.typeIds = [params.params.typeId];
        const url = `${URL_PATH}/listFacDevs`;
        const res = await post(url, params);
        res.items = _.map(res.items, item => item = new DeviceEntityModel().toModel(item));
        return res;
    }

    async listAssetDevs(query?: DeviceQueryModel, page?: number, limit?: number):Promise<any> {
        const params:any = Object.assign({ page, limit }, query?.toService());
        params.params.typeIds = [params.params.typeId];
        const url = `${ASSET_BASE_REQUEST_PATH}/asset/basic/asset/searchDeviceList`;
        const res = await post(url, params);
        res.items = _.map(res.items, item => item = new DeviceEntityModel().toModel({ id: item.deviceId, displayName: item.deviceName }));
        return res;
    }

    async saveAttr(params):Promise<any> {
        const url = `${URL_PATH}/saveAttr`;
        const res = await post(url, params);
        return res;
    }

    async getDeviceListByTypeId(typeId: string):Promise<Array<any>> {
        const url = `${URL_PATH}/getDevices`;
        const res = await post(url, { typeId, forceRoot: false });
        // return _.map(res, item => item = new DeviceEntityModel().toModel(item));
        return _.map(res, item => {
            return {
                id: item.id,
                value: item.id,
                name: item.displayName,
                title: item.displayName
            };
        });
    }

    async link(id: string, parentId: string):Promise<any> {
        const url = `${URL_PATH}/link`;
        return await post(url, { childId: id, parentId: parentId });
    }

    async unlink(id: string, parentId: string):Promise<any> {
        const url = `${URL_PATH}/unlink`;
        return await post(url, { childId: id, parentId: parentId });
    }

    async getAllDeviceAttributes(scopeId?: string, identifier?: string):Promise<Array<SelectDeviceModel>> {
        let url = null;

        if (scopeId) {
            if (scopeId === 'energy') {
                url = `${BI_BASE_REQUEST_PATH}/energyItem/building/getDeviceAttr`;
            } else {
                url = `${BI_BASE_REQUEST_PATH}/facilitytype/getDeviceAttrs/${scopeId}/${identifier}`;
            }
        } else {
            url = `${URL_PATH}/getDevsWithAttrs`;
        }

        const res = await get(url, null, { cache: 10000 });
        return _.map(_.filter(res, item => item.values && item.values.length > 0), item => new SelectDeviceModel().toModel(item));
    }

    async getDeviceTree():Promise<Array<SelectDeviceModel>> {
        const url = `${URL_PATH}/getDeviceTree`;
        const res = await get(url);
        return _.map(res, item => {
            return this.initTreeData(item, null);
        });
    }

    initTreeData(treeData, parentId) {
        const treeItem: any = {
            title: _.get(treeData, 'displayName'),
            value: _.get(treeData, 'id'),
            id: _.get(treeData, 'id'),
            pId: parentId
        };
        const children = _.get(treeData, 'children');

        if (children && children.length > 0) {
            treeItem.children = _.map(children, child => {
                return this.initTreeData(child, treeItem.id);
            });
        }

        return treeItem;
    }

    async links(parentId: string, childId: Array<string>):Promise<any> {
        const url = `${URL_PATH}/links`;
        return await post(url, { parentId, childrenId: childId });
    }

    async batchUnlink(ids: Array<string>) {
        const url = `${URL_PATH}/unlinks`;
        const res = await post(url, ids);
        return res;
    }

    async batchDelete(ids: Array<string>) {
        const url = `${URL_PATH}/batchDelete`;
        const params = {
            list: ids
        };
        const res = await post(url, params);
        return res;
    }

    async batchChangeGroup(ids: Array<string>, groupId: string) {
        const url = `${URL_PATH}/batchChange`;
        const params = {
            ids,
            targetGrpId: groupId
        };
        const res = await post(url, params);
        return res;
    }

    async batchChangePosition(ids: Array<string>, positionId: string) {
        const url = `${URL_PATH}/batchChangeSpace`;
        const params = {
            list: ids,
            tarSpaceId: positionId
        };
        const res = await post(url, params);
        return res;
    }

    async exportExcelByDeviceTypeId(deviceTypeId: string, type: string) {
        const url = `${URL_PATH}/exportTemplate/${deviceTypeId}/${type}`;
        const res = await download(url);
        // const res = await get(url);
        return res;
    }

    async importExcel(importModel: DeviceImportExcelEntityModel) {
        const url = `${URL_PATH}/childrenImport`;
        const params = importModel.toService();
        const res = await post(url, params, { headers: { 'Content-Type': 'multipart/form-data' }, timeout: 600000 });
        return res;
    }
    async importExcelMatch(importModel: any) {
        const url = `${URL_PATH}/childrenAttrsImport`;
        const params = importModel.toService();
        const res = await post(url, params, { headers: { 'Content-Type': 'multipart/form-data' }, timeout: 600000 });
        return res;
    }
}

export default new DeviceService();
