import { FormControl, FormControlOptionModel, FormControlTextModel, FormControlType } from '@/model/form-control';
import TopoStyleBaseModel from './topo-style-base-model';

export default class TopoStyleTextModel extends TopoStyleBaseModel {
    // @FormControl({
    //     label: 'Text',
    //     type: FormControlType.TEXT,
    //     layout: { labelCol: 8, wrapperCol: 12, width: '100%' }
    // } as FormControlTextModel)
    // text: string = undefined;

    toModel(options) {
        super.toModel(options);
        return this;
    }
}
