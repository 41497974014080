
import { LabelEntityModel, LabelQueryModel, ImporExcelEntityModel } from '@/entity-model/label-entity';
import { ICRUDQ } from '@/model/interface';
import { get, post, del, download } from './request';
const URL_PATH = `${BI_BASE_REQUEST_PATH}/tag`;

class LabelService implements ICRUDQ<LabelEntityModel, LabelQueryModel> {
    async create(model: LabelEntityModel):Promise<LabelEntityModel> {
        const url = `${URL_PATH}/save`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async retrieve(modelId: string):Promise<LabelEntityModel> {
        const url = `${URL_PATH}/detail/${modelId}`;
        const res = await get(url);
        return new LabelEntityModel().toModel(res);
    }

    async update(model: LabelEntityModel):Promise<LabelEntityModel> {
        const url = `${URL_PATH}/save`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async delete(model: LabelEntityModel):Promise<LabelEntityModel> {
        const url = `${URL_PATH}/${model.id}`;
        const res = await del(url);
        return res;
    }

    async query(query?: LabelQueryModel, page?: number, limit?: number):Promise<any> {
        const url = `${URL_PATH}/list`;
        const params = Object.assign({ page, limit }, query?.toService());
        const res = await post(url, params);
        res.items = _.map(res.items, item => item = new LabelEntityModel().toModel(item));
        return res;
        // 不带分页功能的处理方法
        // return _.map(res, item => item = new LabelEntityModel().toModel(item));
    }

    async getAllLabel() {
        const res = await this.query(null, 1, 9999);
        return _.map(_.get(res, 'items'), item => {
            return {
                name: item.name,
                value: item.id
            };
        });
    }

    async batchSave(model: any): Promise<any> {
        const url = `${URL_PATH}/relation/batchSave`;
        const res = await post(url, model);
    }

    async batchDeleteEntityTagRelations(model: any): Promise<any> {
        const url = `${URL_PATH}/relation/batchDeleteEntityTagRelations`;
        const res = await post(url, model);
    }

    async selectTagsByEntityIdsAndEntityType(model:any): Promise<any> {
        const url = `${URL_PATH}/relation/selectTagsByEntityIdsAndEntityType`;
        const res = await post(url, model);
        return _.map(res, item => {
            return {
                name: item.name,
                value: item.id
            };
        });
    }

    async exportExcel(): Promise<any> {
        const url = `${URL_PATH}/export`;
        const res = await download(url);
        return res;
    }

    async importExcel(model: ImporExcelEntityModel): Promise<any> {
        const url = `${URL_PATH}/importExcel`;
        const params = model.toService();
        const res = await post(url, params, { headers: { 'Content-Type': 'multipart/form-data' }, timeout: 600000 });
        return res;
    }
}

export default new LabelService();
