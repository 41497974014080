

















import { Component } from 'vue-property-decorator';
import FormDialogComponent from '@/mixins/form-dialog-component';
import { FormControlOptionModel } from '@/model/form-control';

@Component
export default class JTLEntityDialogComponent extends FormDialogComponent<any> {
    formOnChange(formControlModel: FormControlOptionModel) {
        this.$emit('change', formControlModel);
    }
}
