import { render, staticRenderFns } from "./attribute-data-type-value.vue?vue&type=template&id=26ee3d40&"
import script from "./attribute-data-type-value.vue?vue&type=script&lang=ts&"
export * from "./attribute-data-type-value.vue?vue&type=script&lang=ts&"
import style0 from "./attribute-data-type-value.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\git.fe\\jtl3d-bi-admin\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('26ee3d40')) {
      api.createRecord('26ee3d40', component.options)
    } else {
      api.reload('26ee3d40', component.options)
    }
    module.hot.accept("./attribute-data-type-value.vue?vue&type=template&id=26ee3d40&", function () {
      api.rerender('26ee3d40', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/jtl-form/attribute-data-type-value.vue"
export default component.exports