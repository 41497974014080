import { EnableType } from '@/model/enum';

const enableList: Array<{ name: string, value: EnableType }> = _.filter(_.map(EnableType, item => {
    let name: string = null;
    switch (item) {
        case EnableType.ENABLE:
            name = '是';
            break;
        case EnableType.DISABLE:
            name = '否';
            break;
    }
    return { name, value: item };
}), item => item.name) as any;

const enableFilter = (val: EnableType) => {
    return _.get(_.find(enableList, item => item.value === val), 'name') || '-';
};

export {
    enableFilter,
    enableList
};
