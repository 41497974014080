






























import { Component, Vue, Prop, Watch, Ref } from 'vue-property-decorator';
import { BaseComponent } from '@/mixins/base-component';
import { SimpleTreeModel } from '@/model/simple-tree-model';
import CommonService from '@/service/common';

@Component
export default class JTLTreeProfessionalComponent extends BaseComponent {
    treeData: Array<SimpleTreeModel> = null;
    expandedKeys: Array<string> = null;
    selectedKeys: Array<string> = null;
    @Prop()
    professionalId: string;

    treeLoading: boolean = true;

    mounted() {
        if (this.professionalId) {
            this.selectedKeys = [this.professionalId];
        }
        this.initTree();
    }

    initTree() {
        this.treeLoading = true;
        CommonService.getProfessionalTree().then(res => {
            this.treeData = res;
            if (this.professionalId) {
                this.expandedKeys = [];
                this.initExpandedKeys(this.treeData);
            } else if (this.treeData && this.treeData.length > 0) {
                this.expandedKeys = [''];
                // 默认选中第一个节点
                const selectGroupId = this.treeData[0].id;
                this.selectedKeys = [selectGroupId];
                this.$emit('select', selectGroupId);
                // this.$emit('loaded');
            }
        }).catch(err => {
            $log.error(err);
        }).finally(() => {
            this.treeLoading = false;
        });
    }

    initExpandedKeys(children: Array<SimpleTreeModel>) {
        if (children && children.length > 0) {
            for (let i = 0; i < children.length; i++) {
                if (children[i].id === this.professionalId) {
                    this.$emit('select', children[i].id);
                    // this.$emit('loaded');
                    this.getParentKey(children[i].parent);
                    break;
                } else {
                    this.initExpandedKeys(children[i].children);
                }
            }
        }
    }

    getParentKey(parent: SimpleTreeModel) {
        if (parent) {
            this.expandedKeys.push(parent.id);
            if (parent.parent) {
                this.getParentKey(parent.parent);
            }
        }
    }

    treeSelect(selectedKeys, e:{ selected: boolean, selectedNodes, node, event }) {
        const groupId = e.node.value;
        if (e.selected) {
            this.$emit('select', e.node.dataRef.id);
        } else {
            this.selectedKeys = [groupId];
        }
    }
}

