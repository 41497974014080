





























import { Component } from 'vue-property-decorator';
import TopoBaseView from '../topo-base-view';
import { getParkingIndex } from '@topo/assets/data/chart-gauge-parking';

@Component
export default class ViewParkingText extends TopoBaseView {
    get Title() {
        return _.get(this.ComponentData, 'name');
    }
    get TotalCount() {
        return _.get(this.ComponentData, 'totalCount');
    }
    get FreeCount() {
        return _.get(this.ComponentData, 'freeCount');
    }
    get UsingCount() {
        return _.get(this.ComponentData, 'usingCount');
    }
    get FreeCountTextColor() {
        // return PARKING_COLORS[getParkingIndex(this.UsingCount / this.TotalCount * 100)];
        return '#fff';
    }
}
