var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-componnet" },
    [
      _c(
        "a-config-provider",
        { attrs: { locale: _vm.zhCN } },
        [_c("router-view")],
        1
      ),
      _c(
        "a-spin",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.fullScreenLoading,
              expression: "fullScreenLoading"
            }
          ],
          staticClass: "full-screen-loading",
          attrs: {
            spinning: _vm.fullScreenLoading,
            tip: _vm.fullScreenLoadingText
          }
        },
        [
          _c("a-icon", {
            staticClass: "center",
            staticStyle: { "font-size": "80px" },
            attrs: { slot: "indicator", type: "loading", spin: "" },
            slot: "indicator"
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }