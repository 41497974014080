import JtlBar from './packages/bar';
import JtlHistogram from './packages/histogram';
import JtlLine from './packages/line';
import JtlPie from './packages/pie';
import JtlRing from './packages/ring';
// import JtlWaterfall from './packages/waterfall';
// import JtlFunnel from './packages/funnel';
// import JtlRadar from './packages/radar';
import JtlChart from './packages/chart';
// import JtlMap from './packages/map';
// import JtlBmap from './packages/bmap';
// import JtlAmap from './packages/amap';
// import JtlSankey from './packages/sankey';
// import JtlHeatmap from './packages/heatmap';
// import JtlScatter from './packages/scatter';
// import JtlCandle from './packages/candle';
import JtlGauge from './packages/gauge';
// import JtlTree from './packages/tree';
// import JtlLiquidfill from './packages/liquidfill';
// import JtlWordcloud from './packages/wordcloud';

const components = [
    JtlBar,
    JtlHistogram,
    JtlLine,
    JtlPie,
    JtlRing,
    // JtlWaterfall,
    // JtlFunnel,
    // JtlRadar,
    JtlChart,
    // JtlMap,
    // JtlBmap,
    // JtlAmap,
    // JtlSankey,
    // JtlHeatmap,
    // JtlScatter,
    // JtlCandle,
    JtlGauge
    // JtlTree,
    // JtlLiquidfill,
    // JtlWordcloud,
];

function install(Vue) {
    components.forEach(component => {
        Vue.component(component.name, component);
    });
}

if (typeof window !== 'undefined' && window.Vue) {
    install(window.Vue);
}

export default {
    JtlBar,
    JtlHistogram,
    JtlRing,
    JtlLine,
    JtlPie,
    // JtlWaterfall,
    // JtlFunnel,
    // JtlRadar,
    JtlChart,
    // JtlMap,
    // JtlBmap,
    // JtlAmap,
    // JtlSankey,
    // JtlScatter,
    // JtlCandle,
    JtlGauge,
    // JtlTree,
    // JtlLiquidfill,
    // JtlWordcloud,
    install
};
