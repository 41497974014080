
import { ThingsGroupEntityModel } from '@/entity-model/things-group-entity';
import { ICRUDQ } from '@/model/interface';
import { get, post, put, del } from './request';
import { SimpleTreeModel } from '@/model/simple-tree-model';
import { GroupEntityType } from '@/model/enum';

const URL_PATH = `${BI_BASE_REQUEST_PATH}/groups`;

class ThingsGroupService implements ICRUDQ<ThingsGroupEntityModel, any> {
    async create(model: ThingsGroupEntityModel):Promise<ThingsGroupEntityModel> {
        const url = `${URL_PATH}/save`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async retrieve(modelId: string):Promise<ThingsGroupEntityModel> {
        throw new Error('Not implement.');
    }

    async update(model: ThingsGroupEntityModel):Promise<ThingsGroupEntityModel> {
        const url = `${URL_PATH}/save`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async delete(model: ThingsGroupEntityModel):Promise<ThingsGroupEntityModel> {
        const url = `${URL_PATH}/delete/${model.id}`;
        const res = await del(url);
        return res;
    }

    async query(type: any):Promise<any> {
        const url = `${URL_PATH}/getByType/${type}`;
        let res = await get(url);
        // return res;

        if (!res) {
            res = [];
        }

        res.unshift({
            id: '',
            name: '全部分组',
            type: type
        });

        // res.push({ id: ' ', name: '未分组', type });
        // const allGroup = [{
        //     id: '',
        //     name: '全部分组',
        //     type: type,
        //     children: res
        // }];
        return _.map(res, item => item = new ThingsGroupEntityModel().toModel(Object.assign({ type }, item)));
    }

    async getGroupTree(type: GroupEntityType, hasUnGroup: boolean = false):Promise<Array<SimpleTreeModel>> {
        const url = `${URL_PATH}/getByType/${type}`;
        let res = await get(url);

        // if (hasUnGroup) {
        //     if (!res || res.length === 0) {
        //         res = [];
        //     }
        //     res.push({ id: ' ', name: '未分组', type });
        // }

        return _.map(res, item => {
            return new SimpleTreeModel().toModel(item);
        });
    }
}

export default new ThingsGroupService();
