
import { ThingsAttributeEntityModel, ThingsAttributeQueryModel } from '@/entity-model/things-attribute-entity';
import { ICRUDQ } from '@/model/interface';
import { get, post, del } from './request';
import { ThingsEntityModel } from '@/entity-model/things-entity';

const URL_PATH = `${BI_BASE_REQUEST_PATH}/things/attrs`;

class ThingsAttributeService implements ICRUDQ<ThingsAttributeEntityModel, ThingsAttributeQueryModel> {
    async create(model: ThingsAttributeEntityModel):Promise<ThingsAttributeEntityModel> {
        const url = `${URL_PATH}/saveAttr`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async retrieve(modelId: string):Promise<ThingsAttributeEntityModel> {
        const url = `${URL_PATH}/detail/${modelId}`;
        const res = await get(url);
        return new ThingsAttributeEntityModel().toModel(res);
    }

    async update(model: ThingsAttributeEntityModel):Promise<ThingsAttributeEntityModel> {
        const url = `${URL_PATH}/saveAttr`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async delete(model: ThingsAttributeEntityModel):Promise<ThingsAttributeEntityModel> {
        const url = `${URL_PATH}/delAttr/${model.id}`;
        const res = await del(url);
        return res;
    }

    async query(query?: ThingsAttributeQueryModel, page?: number, limit?: number):Promise<any> {
        const url = `${URL_PATH}/list`;
        const params = Object.assign({ page, limit }, query?.toService());
        let res = await post(url, params);
        res.items = _.map(res.items, item => item = new ThingsAttributeEntityModel().toModel(item));
        return res;
    }

    async getAll(selectFunctionList: Array<ThingsEntityModel>):Promise<any> {
        const url = `${URL_PATH}/getAll`;
        let res = await get(url);
        return _.map(
            _.filter(
                res,
                item => _.findIndex(selectFunctionList, functionItem => functionItem.isSame({ name: _.get(item, 'jsonValue.name'), identifier: _.get(item, 'jsonValue.identifier') })) === -1
            ),
            item => {
                const id = item.id;
                return {
                    value: id,
                    name: `${_.get(item, 'jsonValue.name')} | ${_.get(item, 'jsonValue.identifier')}`,
                    check: false
                };
            });
    }

    async saveFunction(params):Promise<any> {
        const url = `${URL_PATH}/saveAttrs`;
        const res = await post(url, params);
        return res;
    }
}

export default new ThingsAttributeService();
